import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  IrisSpinner, IrisBox, IrisButton,
} from 'iris-core/libs/components';

import { GroupForm } from '../../components';
import { getGroups, createGroup } from '../../actions';
import { toDate, resolveGroupType } from '../../helper';
import i18n from '../../i18n';

const { SearchBar } = Search;

class Groups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalGroupForm: false,
    };
  }

  componentDidMount() {
    Promise.all([this.props.getGroups()]).then(() => {
      this.setState({ loading: false });
    });
  }

  toggleModalGroupForm = () => {
    this.setState({ modalGroupForm: !this.state.modalGroupForm });
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    const columns = [{
      dataField: 'id',
      hidden: true,
    }, {
      sort: true,
      dataField: 'name',
      text: i18n.t('generic.name'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
    }, {
      sort: true,
      dataField: 'description',
      text: i18n.t('generic.description'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
    }, {
      sort: true,
      dataField: 'type',
      text: i18n.t('generic.type'),
      headerStyle: { width: '140px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => resolveGroupType(cell),
    }, {
      sort: true,
      dataField: 'members',
      text: i18n.t('groups.members'),
      headerStyle: { width: '140px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => cell?.length ?? 0,
    }, {
      sort: true,
      dataField: 'createdAt',
      text: i18n.t('generic.created'),
      headerStyle: { width: '165px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => toDate(cell),
    }];

    const rowEvents = {
      onClick: (e, row) => this.props.history.push(`${this.props.location.pathname}/${row.id}`),
    };

    return (
      <div className="GroupList">
        <Row>
          <Col>
            <IrisBox>
              <div className="title mb-4">
                <h4 className="text-muted"><i className="fa fa-users"/> {i18n.t('groups.title')}</h4>
              </div>
              <ToolkitProvider
                keyField="id"
                data={this.props.groups || []}
                columns={ columns }
                search={{ searchFormatted: true }}
              >
                {props => (
                  <div>
                    <div style={{ display: 'inline' }}>
                      <div style={{ float: 'left' }}>
                        <SearchBar { ...props.searchProps }/>
                      </div>
                      <div>
                        <IrisButton style={{ float: 'right' }} key={_.uniqueId()} color="info" size="sm" onClick={this.toggleModalGroupForm} outline>{i18n.t('buttons.create')}...</IrisButton>
                      </div>
                    </div>
                    <BootstrapTable
                      { ...props.baseProps }
                      noDataIndication={i18n.t('notices.noDataIndication')}
                      rowEvents={rowEvents}
                      pagination={paginationFactory({ hideSizePerPage: true })}
                      striped hover/>
                  </div>
                )}
              </ToolkitProvider>
            </IrisBox>
          </Col>
        </Row>

        {this.state.modalGroupForm
         && <GroupForm title={i18n.t('groups.createGroupForm.title')} icon={<i className="fa fa-users fa-fw"/>}
                       onSubmit={this.props.createGroup} toggle={this.toggleModalGroupForm}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    groups: fleetmanager.posts.groups,
  };
};

export default connect(mapStateToProps, { getGroups, createGroup })(Groups);
