import React from 'react';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import {
  IrisSpinner, IrisBox, IrisButton, IrisButtonGroup, IrisDatatable, IrisTableHeaderColumn,
} from 'iris-core/libs/components';

import {
  UpdateUserForm, ConfirmDialog, AddPolicyRuleForm, RemovePolicyRuleForm, ImageModal,
} from '../../components';
import {
  updateUser, deleteUser, getUser, getAssets, getAssetTypes, getUserAuthRecords, getUserAssignments, getPolicyRules,
  addOrRemovePolicyRuleFromUser, createAllUserAssignments, cancelAllUserAssignments,
} from '../../actions';
import {
  toDate, resolveAssetName, resolveAssetTypeName, resolveCheckSquare, resolveAssignmentStatus,
} from '../../helper';
import { SELECT_ROW_PROPS, ROLE } from '../../globalConstants';
import i18n from '../../i18n';

import validationTypeOptions from '../../data/ValidationTypeOptions.json';

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalUpdateUser: false,
      modalDeleteUser: false,
      modalAddPolicyRuleForm: false,
      modalRemovePolicyRuleForm: false,
      modalCancelAssignments: false,
      disableAllUserAssignmentsBtns: false,
      modalImage: false,
      imageUrl: '',
    };
  }

  componentDidMount() {
    Promise.all([this.props.getAssets(), this.props.getAssetTypes(), this.props.getPolicyRules()]).then(() => {
      this.props.getUser(this.props.match.params.rowId);
      this.props.getUserAssignments(this.props.match.params.rowId);
      this.props.getUserAuthRecords(this.props.match.params.rowId);
    });
  }

  toggleModalUpdateUser = () => {
    this.setState({ modalUpdateUser: !this.state.modalUpdateUser });
  }

  toggleModalDeleteUser = () => {
    this.setState({ modalDeleteUser: !this.state.modalDeleteUser });
  }

  toggleModalAddPolicyRuleForm = () => {
    this.setState({ modalAddPolicyRuleForm: !this.state.modalAddPolicyRuleForm });
  }

  toggleModalRemovePolicyRuleForm = () => {
    this.setState({ modalRemovePolicyRuleForm: !this.state.modalRemovePolicyRuleForm });
  }

  toggleModalCancelAssignments = () => {
    this.setState({ modalCancelAssignments: !this.state.modalCancelAssignments });
  }

  toggleDisableAllUserAssignmentsBtns = () => {
    this.setState({ disableAllUserAssignmentsBtns: !this.state.disableAllUserAssignmentsBtns });
  }

  toggleModalImage = (image) => {
    if (!image) {
      this.setState({ modalImage: !this.state.modalImage });
      return;
    }

    const base64Str = btoa(new Uint8Array(image.data.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    this.setState({
      modalImage: !this.state.modalImage,
      imageUrl: `data:${image.contentType};base64,${base64Str}`,
    });
  }

  cancelAllUserAssignments = () => {
    this.toggleDisableAllUserAssignmentsBtns();

    this.props.cancelAllUserAssignments(this.props.user.id).then(() => {
      this.props.getUserAssignments(this.props.user.id);
      this.toggleDisableAllUserAssignmentsBtns();
    });
  }

  createAllUserAssignments = () => {
    this.toggleDisableAllUserAssignmentsBtns();

    const start = new Date();
    const end = new Date();
    end.setFullYear(start.getFullYear() + 1);
    end.setHours(23, 59, 0, 0);

    this.props.createAllUserAssignments({
      name: `${this.props.user.role} assignment`,
      description: 'One-year assignment',
      userId: this.props.user.id,
      start: start.toISOString(),
      end: end.toISOString(),
    }).then(() => {
      this.props.getUserAssignments(this.props.user.id);
      this.toggleDisableAllUserAssignmentsBtns();
    });
  }

  deleteUser = () => {
    this.props.deleteUser(this.props.user.id).then(() => {
      if (this.props.error !== 'user_deletion_error') {
        this.props.history.goBack();
      }
    });
  }

  mapRules = () => {
    this.mappedRules = [];
    this.userRulesMap = {};

    if (this.props.policyRules && this.props.user?.rules) {
      this.mappedRules = this.props.policyRules.filter(policyRule => {
        const found = this.props.user.rules.find(rule => rule.ruleId === policyRule.id);
        if (!found) {
          return false;
        }

        this.userRulesMap[policyRule.id] = found.value;
        return true;
      });
    }

    return this.mappedRules;
  }

  resolveRule = (rule) => {
    if (rule.type === 'required') {
      return 'Required';
    }

    const found = validationTypeOptions.find(r => rule.type === r.value);
    return `${found.label} ${rule.value} ${i18n.t('generic.hours')}`;
  }

  resolveValue = (rule) => {
    return (rule.type === 'required' ? 'Available' : `${this.userRulesMap[rule.id]} ${i18n.t('generic.hours')}`);
  }

  resolveAuthRecordImage = (type, image) => {
    return (type === 'Badge'
      ? <IrisButton size="xs" onClick={() => this.toggleModalImage(image)} outline>
          <i className="fa fa-eye" aria-hidden="true"/> {i18n.t('buttons.view')}
        </IrisButton>
      : 'N/A');
  }

  buttonValues = () => {
    const buttonsArray = [];
    if (this.props.profile.user.role === ROLE.ADMIN
        || (this.props.profile.user.role === ROLE.DISPATCHER && this.props.user.role !== ROLE.ADMIN && this.props.user.role !== ROLE.DISPATCHER)) {
      buttonsArray.push({ label: `${i18n.t('buttons.update')}...`, color: 'primary', onClick: this.toggleModalUpdateUser });
      if (this.props.profile.user.id !== this.props.user.id) {
        buttonsArray.push({ label: `${i18n.t('buttons.delete')}...`, color: 'secondary', onClick: this.toggleModalDeleteUser });
      }
    }
    return buttonsArray;
  }

  render() {
    if (this.props.detailsType !== 'user' || this.props.user.id !== this.props.match.params.rowId) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    this.mapRules();

    const policyRuleToolbarButtons = this.props.profile.account.iris.users.qualifications.show
      ? [<IrisButton key={_.uniqueId()} color="danger" size="sm"
                     disabled={!this.mappedRules.length}
                     onClick={this.toggleModalRemovePolicyRuleForm}
                     outline>{i18n.t('buttons.remove')}...</IrisButton>,
         <IrisButton key={_.uniqueId()} color="info" size="sm"
                     disabled={!this.props.policyRules?.length}
                     onClick={this.toggleModalAddPolicyRuleForm}
                     outline>{i18n.t('buttons.add')}...</IrisButton>]
      : null;

    const assignmentToolbarButtons = [
      <IrisButton key={_.uniqueId()} color="danger" size="sm"
                  disabled={this.state.disableAllUserAssignmentsBtns || this.props.user.role !== ROLE.TECHNICIAN}
                  onClick={this.toggleModalCancelAssignments}
                  outline>{i18n.t('buttons.cancel')}...</IrisButton>,
      <IrisButton key={_.uniqueId()} color="info" size="sm"
                  disabled={this.state.disableAllUserAssignmentsBtns || this.props.user.role !== ROLE.TECHNICIAN}
                  onClick={this.createAllUserAssignments}
                  outline>{i18n.t('buttons.sync')}</IrisButton>,
    ];

    const assignmentOptions = !this.props.profile.account.iris.bookings.show && this.props.user.role !== ROLE.TECHNICIAN
      ? { onRowClick: (row) => this.props.history.push(`${window.BASE_PATH}/assignments/${row.assignmentId}`) }
      : {};

    return (
      <div className="UserDetails">
        <Row>
          <Col>
            <IrisBox>
              <Row>
                <Col>
                  <div className="table-title d-flex flex-row align-items-center">
                    <h4 className="mr-auto"><i className="fa fa-user"/> <small className="text-muted">{this.props.user.name}, {this.props.user.email}</small></h4>
                    <IrisButtonGroup defaultActive={0} size="sm" type="info" values={this.buttonValues()}/>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm="6">
                  <Row>
                    <Col sm="4">
                      {this.props.user.externalId && <div>
                        {i18n.t('users.details.externalId')}
                      </div>}
                      <div>{i18n.t('users.role')}</div>
                      {this.props.user.role === ROLE.DISPATCHER && <div>
                        {i18n.t('users.details.notificationsEnabled')}
                      </div>}
                    </Col>
                    <Col sm="8">
                      {this.props.user.externalId && <div className="text-muted">
                        {this.props.user.externalId}
                      </div>}
                      <div className="text-muted">{this.props.user.role}</div>
                      {this.props.user.role === ROLE.DISPATCHER && <div>
                        {resolveCheckSquare(this.props.user.notificationsEnabled)}
                      </div>}
                    </Col>
                  </Row>
                </Col>
                <Col sm="6">
                  {_.get(this.props.profile.account.iris, 'users.fields.authId', false) && <Row>
                    <Col sm="4">
                      <div>{i18n.t('users.details.authId')}</div>
                    </Col>
                    <Col sm="8">
                      <div className="text-muted">{this.props.user.authId}</div>
                    </Col>
                  </Row>}
                  {_.get(this.props.profile.account.iris, 'users.qualifications.show', false) && <Row>
                    <Col sm="4">
                      <div>{i18n.t('users.qualifications')}</div>
                    </Col>
                    <Col sm="8">
                      <div className="text-muted">{this.props.user.rules?.length ?? 0}</div>
                    </Col>
                  </Row>}
                  <Row>
                    <Col sm="4">
                      <div>{i18n.t('generic.updated')}</div>
                    </Col>
                    <Col sm="8">
                      <div className="text-muted">{toDate(this.props.user.updatedAt)}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </IrisBox>

            {this.props.profile.account.iris.users.qualifications.show && <IrisBox>
              <div className="title mb-4">
                <h4><small className="text-muted">{i18n.t('users.qualifications')}</small></h4>
              </div>
              <IrisDatatable data={this.mappedRules} toolbar={policyRuleToolbarButtons} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn dataField="name" searchable>{i18n.t('generic.name')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="description" searchable>{i18n.t('generic.description')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="rules" dataFormat={(cell, row) => this.resolveRule(row)}>{i18n.t('generic.rule')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="rules" dataFormat={(cell, row) => this.resolveValue(row)}>{i18n.t('generic.value')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="createdAt" dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.created')}</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>}

            <IrisBox>
              <div className="title mb-4">
                <h4><small className="text-muted">{i18n.t('assignments.title')}</small></h4>
              </div>
              <IrisDatatable data={this.props.userAssignments} options={assignmentOptions} toolbar={assignmentToolbarButtons} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                <IrisTableHeaderColumn dataField="assignmentId" isKey hidden/>
                <IrisTableHeaderColumn
                  dataField="status" width="120px"
                  dataFormat={(cell, row) => resolveAssignmentStatus(row)}
                  searchable>{i18n.t('generic.status')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="name" width="200px" searchable>{i18n.t('generic.name')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="assetId"
                  dataFormat={(cell) => resolveAssetName(this.props.assets, cell)}
                  searchable>{i18n.t('assets.asset')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="assetId"
                  dataFormat={(cell) => resolveAssetTypeName(this.props.assetTypes, this.props.assets, cell)}
                  searchable>{i18n.t('assets.assetType')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="start" width="165px"
                  dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.startTime')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="end" width="165px"
                  dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.endTime')}</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>

            {this.props.profile.account.iris.users.authRecords.show && <IrisBox>
              <div className="title mb-4">
                <h4><small className="text-muted">{i18n.t('users.authRecords.title')}</small></h4>
              </div>
              <IrisDatatable data={this.props.userAuthRecords} striped hover pagination search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn dataField="type">{i18n.t('generic.type')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="createdAt"
                  dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.timestamp')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="image"
                  dataFormat={(cell, row) => this.resolveAuthRecordImage(row.type, cell)}>{i18n.t('users.authRecords.table.image')}</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>}
          </Col>
        </Row>

        {this.state.modalUpdateUser
         && <UpdateUserForm title={i18n.t('users.updateUserForm.title')} icon={<i className="fa fa-user fa-fw"/>}
                            account={this.props.profile.account} roles={this.props.roles} user={this.props.user}
                            onSubmit={this.props.updateUser} toggle={this.toggleModalUpdateUser}/>}

        {this.state.modalDeleteUser
         && <ConfirmDialog title={i18n.t('users.deleteUserDialog.title')} icon={<i className="fa fa-user-times fa-fw"/>}
                           confirmText={i18n.t('users.deleteUserDialog.confirmText')} itemName={this.props.user.name}
                           confirmButtonName={i18n.t('buttons.delete')} onConfirm={this.deleteUser} toggle={this.toggleModalDeleteUser}/>}

        {this.state.modalAddPolicyRuleForm
         && <AddPolicyRuleForm title={i18n.t('users.addQualificationForm.title')} type="user" parent={this.props.user}
                               availableRules={this.props.policyRules} resolveRule={this.resolveRule}
                               onSubmit={this.props.addOrRemovePolicyRuleFromUser} toggle={this.toggleModalAddPolicyRuleForm}/>}

        {this.state.modalRemovePolicyRuleForm
         && <RemovePolicyRuleForm title={i18n.t('users.removeQualificationForm.title')} type="user" parent={this.props.user}
                                  rules={this.mappedRules} resolveRule={this.resolveRule}
                                  onSubmit={this.props.addOrRemovePolicyRuleFromUser} toggle={this.toggleModalRemovePolicyRuleForm}/>}

        {this.state.modalImage
         && <ImageModal title={i18n.t('users.authRecords.table.image')} icon={<i className="fa fa-camera fa-fw"/>}
                        url={this.state.imageUrl} toggle={this.toggleModalImage}/>}

        {this.state.modalCancelAssignments
         && <ConfirmDialog title={i18n.t('assignments.cancelAssignmentsDialog.title')} icon={<i className="fa fa-trash fa-fw"/>}
                           confirmText={i18n.t('assignments.cancelAssignmentsDialog.confirmText')} itemName={this.props.user.name}
                           confirmButtonName={i18n.t('buttons.confirm')} onConfirm={this.cancelAllUserAssignments} toggle={this.toggleModalCancelAssignments}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    detailsType: fleetmanager.posts.details.type,
    profile: fleetmanager.posts.profile,
    roles: fleetmanager.posts.roles,
    user: fleetmanager.posts.details.data,
    assets: fleetmanager.posts.assets,
    assetTypes: fleetmanager.posts.assetTypes,
    policyRules: fleetmanager.posts.policyRules,
    userAuthRecords: fleetmanager.posts.userAuthRecords,
    userAssignments: fleetmanager.posts.userAssignments,
  };
};

export default connect(mapStateToProps, {
  getUser,
  updateUser,
  deleteUser,
  getAssets,
  getAssetTypes,
  getPolicyRules,
  addOrRemovePolicyRuleFromUser,
  getUserAuthRecords,
  getUserAssignments,
  createAllUserAssignments,
  cancelAllUserAssignments,
})(UserDetails);
