import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { IrisSpinner, IrisBox, IrisButton } from 'iris-core/libs/components';

import { UserForm } from '../../components';
import { getUsers, getPolicyRules, createUser } from '../../actions';
import { toDate } from '../../helper';
import i18n from '../../i18n';

const { SearchBar } = Search;

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalCreateUser: false,
    };
  }

  componentDidMount() {
    Promise.all([this.props.getPolicyRules()]).then(() => {
      this.props.getUsers();
      this.setState({ loading: false });
    });
  }

  toggleModalCreateUser = () => {
    this.setState({ modalCreateUser: !this.state.modalCreateUser });
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    const columns = [{
      dataField: 'id',
      hidden: true,
    }, {
      sort: true,
      dataField: 'name',
      text: i18n.t('generic.name'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
    }, {
      sort: true,
      dataField: 'email',
      text: i18n.t('users.details.email'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
    }, {
      sort: true,
      dataField: 'externalId',
      text: i18n.t('users.details.externalId'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
      hidden: !_.get(this.props.profile.account.iris, 'users.fields.externalId', true),
    }, {
      sort: true,
      dataField: 'role',
      text: i18n.t('users.role'),
      headerStyle: { width: '160px', color: '#0093ca', fontWeight: 'normal' },
    }, {
      sort: true,
      dataField: 'updatedAt',
      text: i18n.t('generic.updated'),
      headerStyle: { width: '165px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => toDate(cell),
    }];

    const rowEvents = {
      onClick: (e, row) => this.props.history.push(`${this.props.location.pathname}/${row.id}`),
    };

    return (
      <div className="UserList">
        <Row>
          <Col>
            <IrisBox>
              <div className="title mb-4">
                <h4 className="text-muted"><i className="fa fa-user"/> {i18n.t('users.title')}</h4>
              </div>
              <ToolkitProvider
                keyField="id"
                data={this.props.users || []}
                columns={ columns }
                search={{ searchFormatted: true }}
              >
                {props => (
                  <div>
                    <div style={{ display: 'inline' }}>
                      <div style={{ float: 'left' }}>
                        <SearchBar { ...props.searchProps }/>
                      </div>
                      <div>
                        <IrisButton style={{ float: 'right' }} key={_.uniqueId()} color="info" size="sm" onClick={this.toggleModalCreateUser} outline>{i18n.t('buttons.create')}...</IrisButton>
                      </div>
                    </div>
                    <BootstrapTable
                      { ...props.baseProps }
                      noDataIndication={i18n.t('notices.noDataIndication')}
                      rowEvents={rowEvents}
                      pagination={paginationFactory({ hideSizePerPage: true })}
                      striped hover/>
                  </div>
                )}
              </ToolkitProvider>
            </IrisBox>
          </Col>
        </Row>

        {this.state.modalCreateUser
         && <UserForm title={i18n.t('users.createUserForm.title')} icon={<i className="fa fa-user-plus fa-fw"/>}
                      account={this.props.profile.account} roles={this.props.roles}
                      onSubmit={this.props.createUser} toggle={this.toggleModalCreateUser}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    profile: fleetmanager.posts.profile,
    roles: fleetmanager.posts.roles,
    users: fleetmanager.posts.users,
  };
};

export default connect(mapStateToProps, {
  getUsers, getPolicyRules, createUser,
})(Users);
