import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import {
  IrisSpinner, IrisBox, IrisButton, IrisPinMap, IrisDatatable, IrisTableHeaderColumn,
} from 'iris-core/libs/components';

import { UpdateAssetForm, ConfirmDialog } from '../../components';
import {
  updateAsset, deleteAsset, getAsset, getAssetTypes, getUsers, getGroups, getAssetBookings,
  getAssetAssignments, getAssetChecklists, getAssetUsages,
} from '../../actions';
import {
  toDate, toHours, toMinutes, toPartitionedString, getArrayElementProperty, resolveAssetName,
  resolveAssetStatus, resolveCheckSquare, resolveBookingStatus, resolveAssignmentStatus,
  resolveTokenizedUrl, resolveI18nArtifact,
} from '../../helper';
import { SELECT_ROW_PROPS } from '../../globalConstants';
import assetIcon from '../../assets/excavator_gray.png';
import i18n from '../../i18n';

// const INTERVAL_MILLISECS = 10000;

class AssetDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalUpdateAsset: false,
      modalDeleteAsset: false,
    };
  }

  componentDidMount() {
    Promise.all([this.props.getAssetTypes(), this.props.getUsers()]).then(() => {
      this.props.getAsset(this.props.match.params.rowId);

      if (this.props.profile.account.iris.bookings.show) {
        this.props.getGroups();
        this.props.getAssetBookings(this.props.match.params.rowId);
      } else {
        this.props.getAssetAssignments(this.props.match.params.rowId);
      }

      this.props.getAssetChecklists(this.props.match.params.rowId, 'PROBLEM_REPORT');

      if (this.props.profile.account.iris.assets.assetUsages.show) {
        this.getAssetUsages();
        // this.setState({ getAssetUsagesIntervalId: setInterval(this.getAssetUsages, INTERVAL_MILLISECS) });
      }
    });
  }

  componentWillUnmount() {
    // if (this.props.profile.account.iris.assets.assetUsages.show) {
    //   clearInterval(this.state.getAssetUsagesIntervalId);
    // }
  }

  componentDidUpdate(prevProps) {
    if (this.props.asset?.status !== prevProps.asset?.status) {
      if (this.props.profile.account.iris.bookings.show) {
        this.props.getAssetBookings(this.props.match.params.rowId);
      } else {
        this.props.getAssetAssignments(this.props.match.params.rowId);
      }
    }
  }

  getKeystoneState = () => {
    if (this.props.asset.keystone && !_.isEmpty(this.props.asset.keystone)) {
      return i18n.t('assets.state.registered');
    }

    return i18n.t('assets.state.pending');
  }

  resolveLockedFlag = () => {
    return (this.props.asset.lockedFlag === undefined
      ? <i className="fa fa-question fa-lg"/>
      : <i className={this.props.asset.lockedFlag ? 'fa fa-lock fa-lg' : 'fa fa-unlock fa-lg'}/>);
  }

  getAssetUsages = () => {
    this.props.getAssetUsages(this.props.match.params.rowId);
  }

  deleteAsset = () => {
    this.props.deleteAsset(this.props.asset.id).then(() => {
      if (this.props.error !== 'asset_deletion_error') {
        this.props.history.goBack();
      }
    });
  }

  getDefaultAssetCharge = () => {
    const found = this.props.assetTypes.find(element => element.id === this.props.asset.assetTypeId);
    return (!found ? 0 : found.charge);
  }

  calculateCharge = (hours) => {
    const rate = this.getDefaultAssetCharge();
    return (!(hours && rate) ? '' : `$${Math.round((toHours(hours) * rate) * 100) / 100}`);
  }

  toggleModalUpdateAsset = () => {
    this.setState({ modalUpdateAsset: !this.state.modalUpdateAsset });
  }

  toggleModalDeleteAsset = () => {
    this.setState({ modalDeleteAsset: !this.state.modalDeleteAsset });
  }

  getSpecList = () => {
    const array = getArrayElementProperty(this.props.assetTypes, this.props.asset.assetTypeId, 'specifications');
    if (array?.length) {
      return array.map(d => <li key={d.i18n}>{resolveI18nArtifact(d.i18n, window.i18nSpecs)}: {d.value}<br/></li>);
    }
    return 'N/A';
  }

  resolveMap = () => {
    const center = {
      lat: this.props.asset.latitude,
      lng: this.props.asset.longitude,
    };
    const markers = [{
      position: center,
      label: toDate(this.props.asset.gpsTimestamp),
    }];

    return <IrisPinMap apiKey={window.PINMAP_API_KEY} defaultZoom={15} defaultCenter={center} markers={markers}/>;
  }

  render() {
    if (this.props.detailsType !== 'asset' || this.props.asset.id !== this.props.match.params.rowId) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    const bookingOptions = {
      onRowClick: (row) => this.props.history.push(`${window.BASE_PATH}/bookings/${row.id}`),
    };
    const assignmentOptions = {
      onRowClick: (row) => this.props.history.push(`${window.BASE_PATH}/assignments/${row.assignmentId}`),
    };
    const problemReportOptions = {
      onRowClick: (row) => this.props.history.push(`${this.props.location.pathname}/problems/${row.id}`),
    };

    const imgResponsive = {
      display: 'block',
      maxWidth: '250px',
      maxHeight: '250px',
      width: 'auto',
      height: 'auto',
    };

    const ulResponsive = {
      listStyle: 'none',
      textAlign: 'right',
      fontSize: '14px',
    };

    return (
      <div className="AssetDetails">
        <Row>
          <Col>
            <IrisBox>
              <Row>
                <Col>
                  <div className="table-title d-flex flex-row align-items-center">
                    <h4 className="mr-auto"><img src={assetIcon} alt="" width="32" height="32"/> <small className="text-muted">{this.props.asset.id}</small> {resolveAssetStatus(this.props.asset.status)}</h4>
                    <IrisButton color="primary" size="sm" onClick={this.toggleModalUpdateAsset} outline>{i18n.t('buttons.update')}...</IrisButton>
                    <IrisButton color="secondary" size="sm" onClick={this.toggleModalDeleteAsset} outline>{i18n.t('buttons.delete')}...</IrisButton>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm="4" style={{ whiteSpace: 'nowrap' }}>
                  <Row>
                    <Col sm="3"><br/><br/>
                      {this.props.asset.externalId && <div>
                        {i18n.t('assets.details.externalId')}
                      </div>}
                      <div>{i18n.t('assets.assetType')}</div>
                      <div>{i18n.t('assets.details.make')}</div>
                      <div>{i18n.t('assets.details.model')}</div>
                      {this.props.asset.year && <div>
                        {i18n.t('assets.details.year')}
                      </div>}
                      {this.props.asset.licensePlate && <div>
                        {i18n.t('assets.details.licensePlate')}
                      </div>}
                    </Col>
                    <Col sm="9"><br/><br/>
                      {this.props.asset.externalId && <div className="text-muted">
                        {this.props.asset.externalId}
                      </div>}
                      <div className="text-muted">{getArrayElementProperty(this.props.assetTypes, this.props.asset.assetTypeId, 'name')}</div>
                      <div className="text-muted">{getArrayElementProperty(this.props.assetTypes, this.props.asset.assetTypeId, 'make')}</div>
                      <div className="text-muted">{getArrayElementProperty(this.props.assetTypes, this.props.asset.assetTypeId, 'model')}</div>
                      {this.props.asset.year && <div className="text-muted">
                        {this.props.asset.year}
                      </div>}
                      {this.props.asset.licensePlate && <div className="text-muted">
                        {this.props.asset.licensePlate}
                      </div>}
                    </Col>
                  </Row>
                </Col>
                <Col sm="3" style={{ whiteSpace: 'nowrap' }}>
                  <Row>
                    <Col sm="5"><br/><br/>
                      <div>{i18n.t('assets.details.keystone')}</div>
                      <div>{i18n.t('assets.details.uptime')}</div>
                      <div>{i18n.t('assets.details.bypassFlag')}</div>
                      <div>{i18n.t('assets.details.lockedFlag')}</div>
                      {_.get(this.props.profile.account.iris, 'assets.fields.rate', false) && <div>
                        {i18n.t('assets.details.rate')}
                      </div>}
                      <div>{i18n.t('assets.details.appVersion')}</div>
                      <div>{i18n.t('assets.details.libVersion')}</div>
                      <div>{i18n.t('generic.updated')}</div>
                    </Col>
                    <Col sm="7"><br/><br/>
                      <div className="text-muted">{this.getKeystoneState()}</div>
                      <div className="text-muted">{toPartitionedString(this.props.asset.uptimeSeconds)}</div>
                      <div>{resolveCheckSquare(this.props.asset.bypassFlag)}</div>
                      <div>{this.resolveLockedFlag()}&nbsp;<span className="text-muted" style={{ fontSize: '14px' }}>{toDate(this.props.asset.lockedFlagTimestamp)}</span></div>
                      {_.get(this.props.profile.account.iris, 'assets.fields.rate', false) && <div className="text-muted">
                        ${this.getDefaultAssetCharge()}/hr
                      </div>}
                      <div className="text-muted">{this.props.asset.appVersion || <i className="fa fa-question fa-lg"/>}</div>
                      <div className="text-muted">{this.props.asset.libVersion || <i className="fa fa-question fa-lg"/>}</div>
                      <div className="text-muted">{toDate(this.props.asset.updatedAt)}</div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="2" style={{ whiteSpace: 'nowrap' }}><br/><br/>
                  <ul style={ulResponsive}>{this.getSpecList()}</ul>
                </Col>
                <Col sm="2">
                  <img src={resolveTokenizedUrl(getArrayElementProperty(this.props.assetTypes, this.props.asset.assetTypeId, 'imageUrl'))} style={imgResponsive} alt=""/>
                </Col>
              </Row>
            </IrisBox>

            {this.props.asset.latitude && <IrisBox>
              <div className="title mb-4">
                <h4><small className="text-muted">{i18n.t('generic.location')}</small></h4>
              </div>
              {this.resolveMap()}
            </IrisBox>}

            {this.props.profile.account.iris.bookings.show && <IrisBox>
              <div className="title mb-4">
                <h4><small className="text-muted">{i18n.t('bookings.title')}</small></h4>
              </div>
              <IrisDatatable data={this.props.assetBookings} options={bookingOptions} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn
                  dataField="status" width="125px"
                  dataFormat={(cell, row) => resolveBookingStatus(row)}
                  searchable>{i18n.t('generic.status')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="name" width="105px"
                  searchable>{i18n.t('bookings.name')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="groupId"
                  dataFormat={(cell) => getArrayElementProperty(this.props.groups, cell, 'name')}
                  searchable>{i18n.t('groups.group')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="start" width="165px"
                  dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.startTime')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="end" width="165px"
                  dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.endTime')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="usageSeconds" width="132px"
                  dataFormat={(cell) => toMinutes(cell)}>{i18n.t('generic.usageMinutes')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="activationTimestamp" width="165px"
                  dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.activatedTime')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="terminationTimestamp" width="165px"
                  dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.terminatedTime')}</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>}

            {!this.props.profile.account.iris.bookings.show && <IrisBox>
              <div className="title mb-4">
                <h4><small className="text-muted">{i18n.t('assignments.title')}</small></h4>
              </div>
              <IrisDatatable data={this.props.assetAssignments} options={assignmentOptions} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                <IrisTableHeaderColumn dataField="assignmentId" isKey hidden/>
                <IrisTableHeaderColumn
                  dataField="status" width="120px"
                  dataFormat={(cell, row) => resolveAssignmentStatus(row)}
                  searchable>{i18n.t('generic.status')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="userId"
                  dataFormat={(cell) => getArrayElementProperty(this.props.users, cell, 'name')}
                  searchable>{i18n.t('users.user')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="userId" width="160px"
                  dataFormat={(cell) => getArrayElementProperty(this.props.users, cell, 'role')}
                  searchable>{i18n.t('users.role')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="start" width="165px"
                  dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.startTime')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="end" width="165px"
                  dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.endTime')}</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>}

            <IrisBox>
              <div className="title mb-4">
                <h4><small className="text-muted">{i18n.t('problemReports.title')}</small></h4>
              </div>
              <IrisDatatable data={this.props.assetChecklists} options={problemReportOptions} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn
                  dataField="metadata"
                  dataFormat={(cell) => getArrayElementProperty(this.props.users, cell.userId, 'name')} searchable>{i18n.t('users.user')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="metadata"
                  dataFormat={(cell) => getArrayElementProperty(this.props.users, cell.userId, 'role')} searchable>{i18n.t('users.role')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="timestamp"
                  dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.timestamp')}</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>

            {this.props.profile.account.iris.assets.assetUsages.show && <IrisBox>
              <div className="title mb-4">
                <h4><small className="text-muted">{i18n.t('assets.assetUsages.title')}</small></h4>
              </div>
              <IrisDatatable data={this.props.assetUsages} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn
                  dataField="userId"
                  dataFormat={(cell) => getArrayElementProperty(this.props.users, cell, 'name', 'System')}
                  hidden={!_.get(this.props.profile.account.iris, 'assets.assetUsages.fields.user', true)}
                  searchable>{i18n.t('users.user')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="usage"
                  dataFormat={(cell) => toDate(cell.start)}
                  hidden={!_.get(this.props.profile.account.iris, 'assets.assetUsages.fields.start', true)}>{i18n.t('generic.startTime')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="usage"
                  dataFormat={(cell) => toDate(cell.end)}
                  hidden={!_.get(this.props.profile.account.iris, 'assets.assetUsages.fields.end', true)}>{i18n.t('generic.endTime')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="usage"
                  dataFormat={(cell) => toHours(cell.difference)}
                  hidden={!_.get(this.props.profile.account.iris, 'assets.assetUsages.fields.usage', true)}>{i18n.t('generic.usageHours')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="usage"
                  dataFormat={(cell) => this.calculateCharge(cell.difference)}
                  hidden={!_.get(this.props.profile.account.iris, 'assets.assetUsages.fields.charge', false)}>{i18n.t('generic.charge')}</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>}
          </Col>
        </Row>

        {this.state.modalUpdateAsset
         && <UpdateAssetForm title={i18n.t('assets.updateAssetForm.title')} icon={<img src={assetIcon} alt="" width="32" height="32"/>}
                             account={this.props.profile.account} asset={this.props.asset} assetTypes={this.props.assetTypes}
                             onSubmit={this.props.updateAsset} toggle={this.toggleModalUpdateAsset}/>}

        {this.state.modalDeleteAsset
         && <ConfirmDialog title={i18n.t('assets.deleteAssetDialog.title')} icon={<i className="fa fa-trash fa-fw"/>}
                           confirmText={i18n.t('assets.deleteAssetDialog.confirmText')} itemName={resolveAssetName([this.props.asset], this.props.asset.id)}
                           confirmButtonName={i18n.t('buttons.delete')} onConfirm={this.deleteAsset} toggle={this.toggleModalDeleteAsset}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    profile: fleetmanager.posts.profile,
    detailsType: fleetmanager.posts.details.type,
    users: fleetmanager.posts.users,
    groups: fleetmanager.posts.groups,
    asset: fleetmanager.posts.details.data,
    assetTypes: fleetmanager.posts.assetTypes,
    assetBookings: fleetmanager.posts.assetBookings,
    assetAssignments: fleetmanager.posts.assetAssignments,
    assetChecklists: fleetmanager.posts.assetChecklists,
    assetUsages: fleetmanager.posts.assetUsages,
  };
};

export default connect(mapStateToProps, {
  getAsset,
  updateAsset,
  deleteAsset,
  getAssetTypes,
  getUsers,
  getGroups,
  getAssetBookings,
  getAssetAssignments,
  getAssetChecklists,
  getAssetUsages,
})(AssetDetails);
