import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import {
  IrisSpinner, IrisDatatable, IrisTableHeaderColumn, IrisBox, IrisButton,
} from 'iris-core/libs/components';

import { PolicyForm } from '../../components';
import {
  getPolicies, createPolicy, getKeystonePolicies, getAssetTypes,
} from '../../actions';
import { getArrayElementProperty, toDate } from '../../helper';
import { SELECT_ROW_PROPS } from '../../globalConstants';
import i18n from '../../i18n';

class Policies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalPolicyForm: false,
    };
  }

  componentDidMount() {
    Promise.all([this.props.getKeystonePolicies(), this.props.getAssetTypes()]).then(() => {
      this.props.getPolicies();
    });
  }

  toggleModalPolicyForm = () => {
    this.setState({ modalPolicyForm: !this.state.modalPolicyForm });
  }

  render() {
    if (!this.props.keystonePolicies) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    const toolbarButtons = [
      <IrisButton key={_.uniqueId()} color="info" size="sm" onClick={this.toggleModalPolicyForm} disabled={!this.props.assetTypes?.length} outline>
        {i18n.t('buttons.create')}...
      </IrisButton>,
    ];

    const options = {
      onRowClick: (row) => this.props.history.push(`${this.props.location.pathname}/${row.id}`),
    };

    return (
      <div className="PolicyList">
        <Row>
          <Col>
            <IrisBox>
              <div className="title mb-4">
                <h4 className="text-muted"><i className="fa fa-file-text-o"/> {i18n.t('policies.title')}</h4>
              </div>
              <IrisDatatable data={this.props.policies} options={options} toolbar={toolbarButtons} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn dataField="name" searchable>{i18n.t('generic.name')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="description" searchable>{i18n.t('generic.description')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="keystonePolicyId"
                  dataFormat={(cell) => getArrayElementProperty(this.props.keystonePolicies, cell, 'name')}
                  searchable>{i18n.t('generic.type')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="assetTypeId"
                  dataFormat={(cell) => getArrayElementProperty(this.props.assetTypes, cell, 'name')}
                  searchable>{i18n.t('assets.assetType')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="rules" dataFormat={(cell) => cell?.length ?? 0}>{i18n.t('generic.rules')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="createdAt" dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.created')}</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>
          </Col>
        </Row>

        {this.state.modalPolicyForm
         && <PolicyForm title={i18n.t('policies.createPolicyForm.title')} icon={<i className="fa fa-file-text-o fa-fw"/>}
                        keystonePolicies={this.props.keystonePolicies} assetTypes={this.props.assetTypes}
                        onSubmit={this.props.createPolicy} toggle={this.toggleModalPolicyForm}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    policies: fleetmanager.posts.policies,
    assetTypes: fleetmanager.posts.assetTypes,
    keystonePolicies: fleetmanager.posts.keystonePolicies,
  };
};

export default connect(mapStateToProps, {
  getPolicies,
  createPolicy,
  getKeystonePolicies,
  getAssetTypes,
})(Policies);
