import React from 'react';
import _ from 'lodash';

import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';

import {
  func,
  string,
  object,
  array,
} from 'prop-types';

import { IrisSelect, IrisButton, IrisToggleSwitcher } from 'iris-core/libs/components';

import {
  ROLE, PERSON_NAME_MAX_LENGTH, USER_EXTERNAL_ID_MAX_LENGTH, AUTH_ID_MAX_LENGTH,
  PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH,
} from '../../globalConstants';
import i18n from '../../i18n';

/*
* Form to create user
*/
class UserForm extends React.Component {
  static propTypes = {
    title: string.isRequired,
    account: object.isRequired,
    roles: array.isRequired,
    toggle: func.isRequired,
    onSubmit: func.isRequired,
    icon: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      name: '',
      email: '',
      externalId: '',
      authId: '',
      password: '',
      passwordType: 'password',
      role: this.props.roles[0].value,
      notificationsEnabled: true,
    };
  }

  toggleWindow = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.toggle();
  }

  showHide = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      passwordType: this.state.passwordType === 'input' ? 'password' : 'input',
    });
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit({
      name: this.state.name,
      email: this.state.email,
      externalId: this.state.externalId || undefined,
      authId: this.state.authId || undefined,
      password: this.state.password,
      role: this.state.role,
      notificationsEnabled: this.state.role === ROLE.DISPATCHER && this.state.notificationsEnabled === true,
    }).then(() => {
      this.toggleWindow();
    });
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen} fade={true}>
        <Form className="modal-content" onSubmit={this.onSubmit}>
          <ModalHeader toggle={this.toggleWindow}>
            {this.props.icon} {this.props.title}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>{i18n.t('users.role')}:&nbsp;</Label>
              <IrisSelect options={this.props.roles} onChange={value => this.setState({ role: value })} outline/>
            </FormGroup>
            <hr/>
            <FormGroup>
              <Label>{i18n.t('generic.name')}:</Label>
              <Input required name="personName" maxLength={PERSON_NAME_MAX_LENGTH} placeholder={i18n.t('users.createUserForm.namePlaceholder')}
                     onChange={e => this.setState({ name: e.target.value })} value={this.state.name}/>
            </FormGroup>
            <FormGroup>
              <Label>{i18n.t('users.details.email')}:</Label>
              <Input required name="email" type="email" placeholder={i18n.t('users.createUserForm.emailPlaceholder')}
                     onChange={e => this.setState({ email: e.target.value })} value={this.state.email}/>
            </FormGroup>
            {_.get(this.props.account.iris, 'users.fields.externalId', false) && <FormGroup>
              <Label>{i18n.t('users.details.externalId')}:</Label>
              <Input required name="externalId" maxLength={USER_EXTERNAL_ID_MAX_LENGTH} placeholder={i18n.t('users.createUserForm.externalIdPlaceholder')}
                     pattern={_.get(this.props.account.iris, 'users.fieldValidations.externalId', '.+')}
                     onChange={e => this.setState({ externalId: e.target.value })} value={this.state.externalId}/>
            </FormGroup>}
            {_.get(this.props.account.iris, 'users.fields.authId', false) && <FormGroup>
              <Label>{i18n.t('users.details.authId')}:</Label>
              <Input required name="authId" maxLength={AUTH_ID_MAX_LENGTH} placeholder={i18n.t('users.createUserForm.authIdPlaceholder')}
                     onChange={e => this.setState({ authId: e.target.value })} value={this.state.authId}/>
            </FormGroup>}
            <FormGroup>
              <Label>{i18n.t('users.details.password')}:</Label>
              <Row>
                <Col md={10}>
                  <Input required name="password"
                         placeholder={i18n.t('users.createUserForm.passwordPlaceholder', { minLength: PASSWORD_MIN_LENGTH })}
                         type={this.state.passwordType} minLength={PASSWORD_MIN_LENGTH} maxLength={PASSWORD_MAX_LENGTH}
                         onChange={e => this.setState({ password: e.target.value })} value={this.state.password}/>
                </Col>
                <Col md={2}>
                  <IrisButton color="primary" size="sm" onClick={this.showHide}>
                    {this.state.passwordType === 'input' ? i18n.t('buttons.hide') : i18n.t('buttons.show')}
                  </IrisButton>
                </Col>
              </Row>
            </FormGroup>
            {this.state.role === ROLE.DISPATCHER && <FormGroup>
              <Label>{i18n.t('users.details.notificationsEnabled')}:&nbsp;</Label>
              <IrisToggleSwitcher onChange={value => this.setState({ notificationsEnabled: value })} checked={this.state.notificationsEnabled}/>
            </FormGroup>}
          </ModalBody>
          <ModalFooter>
            <IrisButton color="info" size="sm" type="submit">
              {i18n.t('buttons.create')}
            </IrisButton>
            <IrisButton color="secondary" size="sm" onClick={this.toggleWindow} outline>
              {i18n.t('buttons.close')}
            </IrisButton>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default UserForm;
