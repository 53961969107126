import React from 'react';

import {
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';

import {
  func,
  array,
  string,
  object,
} from 'prop-types';

import { IrisSelect, IrisButton } from 'iris-core/libs/components';

import { generateSelectMap } from '../../helper';
import i18n from '../../i18n';

/*
* Form to add policy-rule
*/
class AddPolicyRuleForm extends React.Component {
  static propTypes = {
    title: string.isRequired,
    type: string.isRequired,
    parent: object.isRequired,
    availableRules: array.isRequired,
    resolveRule: func.isRequired,
    toggle: func.isRequired,
    onSubmit: func.isRequired,
    icon: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.rulesMap = {};
    this.availableRules = generateSelectMap(this.props.availableRules, this.rulesMap);

    this.state = {
      isOpen: true,
      ruleId: this.availableRules?.[0]?.value ?? '',
      value: null,
    };
  }

  toggleWindow = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.toggle();
  }

  onSubmit = (event) => {
    event.preventDefault();
    const request = {
      action: 'add',
      ruleId: this.state.ruleId,
      ruleName: this.rulesMap[this.state.ruleId].name, // Only for notifications - not persisted in DB
    };

    request[`${this.props.type}Id`] = this.props.parent.id;
    request[`${this.props.type}Name`] = this.props.parent.name; // Only for notifications - not persisted in DB

    if (this.props.type === 'user') {
      request.value = (this.rulesMap[this.state.ruleId].type === 'required' ? true : this.state.value);
    }

    this.props.onSubmit(request).then(() => {
      this.toggleWindow();
    });
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen} fade={true}>
        <Form className="modal-content" onSubmit={this.onSubmit}>
          <ModalHeader toggle={this.toggleWindow}>
            {this.props.icon} {this.props.title}
          </ModalHeader>
          <ModalBody>
            {this.props.type === 'policy' && <p>{i18n.t('policies.addPolicyRuleForm.description')}</p>}
            {this.props.type === 'user' && <p>{i18n.t('users.addQualificationForm.description')}</p>}
            <hr/>
            <FormGroup>
              <Label>{i18n.t('generic.selectRule')}:&nbsp;</Label>
              <IrisSelect options={this.availableRules} onChange={value => this.setState({ ruleId: value })} outline/>
            </FormGroup>
            <FormGroup>
              <Label>{i18n.t('generic.ruleDefinition')}:&nbsp;</Label>
              <span>{this.props.resolveRule(this.rulesMap[this.state.ruleId])}</span>
            </FormGroup>
            {this.props.type === 'user' && this.rulesMap[this.state.ruleId].type !== 'required' && <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label>{i18n.t('generic.value')}:</Label>
                  <Input name="value" onChange={e => this.setState({ value: e.target.value })} value={this.state.value}/>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>{i18n.t('users.addQualificationForm.unit')}:</Label>
                  <Input name="value" value={i18n.t('generic.hours')} disabled/>
                </FormGroup>
              </Col>
            </Row>}
          </ModalBody>
          <ModalFooter>
            <IrisButton color="info" size="sm" type="submit">
              {i18n.t('buttons.add')}
            </IrisButton>
            <IrisButton color="secondary" size="sm" onClick={this.toggleWindow} outline>
              {i18n.t('buttons.close')}
            </IrisButton>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default AddPolicyRuleForm;
