export const PASSWORD_MIN_LENGTH = '6';
export const PASSWORD_MAX_LENGTH = '20';
export const GENERIC_NAME_MAX_LENGTH = '25';
export const VIN_MAX_LENGTH = '40';
export const LICENSE_PLATE_MAX_LENGTH = '25';
export const GROUP_NAME_MAX_LENGTH = '50';
export const PERSON_NAME_MAX_LENGTH = '40';
export const AUTH_ID_MAX_LENGTH = '30';
export const ASSET_EXTERNAL_ID_MAX_LENGTH = '35';
export const USER_EXTERNAL_ID_MAX_LENGTH = '25';

export const ROLE = {
  TECHNICIAN: 'Technician',
  DISPATCHER: 'Dispatcher',
  ADMIN: 'Admin',
};

export const SERVER_ERROR = {
  BOOKING_CONFLICT: 1009,
};

export const PORTAL_ROLES = [
  { value: ROLE.DISPATCHER, label: ROLE.DISPATCHER },
  { value: ROLE.ADMIN, label: ROLE.ADMIN },
];

export const SELECT_ROW_PROPS = {
  mode: 'checkbox',
  hideSelectColumn: true,
};
