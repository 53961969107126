import React from 'react';
import { Card } from 'reactstrap';

class Telemetry extends React.Component {
  render() {
    return (
      <Card className="p-2">
        <div className="title mb-4">
          <h4 className="text-muted"><i className="fa fa-rss"/> Telemetry - Under Construction</h4>
        </div>
      </Card>
    );
  }
}

export default Telemetry;
