import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { IrisSpinner, IrisBox } from 'iris-core/libs/components';

import {
  getAccessRule, getUsers, getAssets, getGroups,
} from '../../actions';
import { resolveAssetName, toDate } from '../../helper';
import i18n from '../../i18n';

class AccessRuleDetails extends React.Component {
  componentDidMount() {
    Promise.all([this.props.getUsers(), this.props.getAssets(), this.props.getGroups()]).then(() => {
      this.props.getAccessRule(this.props.match.params.rowId);
    });
  }

  resolveUser = (userRef) => {
    if (userRef.type === 'group') {
      return this.props.groups.find(g => g.id === userRef.id).name;
    }

    return this.props.users.find(u => u.id === userRef.id).name;
  }

  resolveAsset = (assetRef) => {
    if (assetRef.type === 'group') {
      return this.props.groups.find(g => g.id === assetRef.id).name;
    }

    return resolveAssetName(this.props.assets, assetRef.id);
  }

  render() {
    if (this.props.detailsType !== 'accessrule' || this.props.accessRule.id !== this.props.match.params.rowId) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    return (
      <div className="AccessRuleDetails">
        <Row>
          <Col>
            <IrisBox>
              <Row>
                <Col>
                  <div className="table-title d-flex flex-row align-items-center">
                    <h4 className="mr-auto"><i className="fa fa-file-text-o"/> <small className="text-muted">{this.props.accessRule.name}</small></h4>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm="4">
                  <Row>
                    <Col sm="4">
                      <div>{i18n.t('generic.description')}</div>
                      <div>{i18n.t('generic.created')}</div>
                    </Col>
                    <Col sm="8">
                      <div className="text-muted">{this.props.accessRule.description || '\u00A0'}</div>
                      <div className="text-muted">{toDate(this.props.accessRule.createdAt)}</div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="4">
                  <Row>
                    <Col sm="4">
                      <div>{i18n.t(this.props.accessRule.user.type === 'user' ? 'users.user' : 'groups.userGroup')}</div>
                      <div>{i18n.t(this.props.accessRule.asset.type === 'asset' ? 'assets.asset' : 'groups.assetGroup')}</div>
                    </Col>
                    <Col sm="8">
                      <div className="text-muted">{this.resolveUser(this.props.accessRule.user)}</div>
                      <div className="text-muted">{this.resolveAsset(this.props.accessRule.asset)}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </IrisBox>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    detailsType: fleetmanager.posts.details.type,
    accessRule: fleetmanager.posts.details.data,
    users: fleetmanager.posts.users,
    assets: fleetmanager.posts.assets,
    groups: fleetmanager.posts.groups,
  };
};

export default connect(mapStateToProps, {
  getAccessRule, getUsers, getAssets, getGroups,
})(AccessRuleDetails);
