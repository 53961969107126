import React from 'react';
import _ from 'lodash';
import {
  Collapse, Card, CardBody, Row, Col, Label,
} from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  IrisSpinner, IrisBox, IrisButton,
} from 'iris-core/libs/components';

import { AssignmentForm } from '../../components';
import {
  resetState, getAssignments, getUsers, getAssets, getAssetTypes,
} from '../../actions';
import {
  toDate, getArrayElementProperty, generateSelectMap, isAssetWorkerRole, resolveAssignmentStatus, resolveAssetName,
  resolveAssetTypeName,
} from '../../helper';
import assignmentStatusOptions from '../../data/assignmentStatusOptions.json';
import i18n from '../../i18n';

const { SearchBar } = Search;

class Assignments extends React.Component {
  constructor(props) {
    super(props);
    this.roles = this.props.roles.filter(element => isAssetWorkerRole(element.value));
    this.state = {
      loading: true,
      modalAssignmentForm: false,
      filters: {},
    };
    this.props.resetState();
  }

  componentDidMount() {
    Promise.all([this.props.getUsers(), this.props.getAssets(), this.props.getAssetTypes()]).then(() => {
      this.props.getAssignments();
      this.assetTypeOptions = generateSelectMap(this.props.assetTypes, {});
      this.setState({ loading: false });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.isEmpty(this.state.filters) && !_.isEmpty(prevState.filters)) {
      this.props.getAssignments();
    }

    if (!_.isEmpty(this.state.filters) && !_.isEqual(this.state.filters, prevState.filters)) {
      this.props.getAssignments(this.getUrlEncodedFilters());
    }
  }

  toggleModalAssignmentForm = () => {
    this.setState({ modalAssignmentForm: !this.state.modalAssignmentForm });
  }

  toggleFilter = () => {
    this.setState({
      openFilters: true,
      filters: {},
      selectedRoles: [],
      selectedAssetTypes: [],
      selectedStatuses: [],
    });
  }

  clearFilter = () => {
    this.setState({
      openFilters: false,
      filters: {},
    });
  }

  getUrlEncodedFilters = () => {
    const queryArray = [];

    if (this.state.filters.startDate) {
      queryArray.push(`start=${this.state.filters.startDate.toISOString()}`);
    }

    if (this.state.filters.endDate) {
      queryArray.push(`end=${this.state.filters.endDate.toISOString()}`);
    }

    if (this.state.filters.roles?.length) {
      queryArray.push(this.state.filters.roles.join('&'));
    }

    if (this.state.filters.assetTypeIds?.length) {
      queryArray.push(this.state.filters.assetTypeIds.join('&'));
    }

    if (this.state.filters.statuses?.length) {
      queryArray.push(this.state.filters.statuses.join('&'));
    }

    return queryArray.join('&');
  }

  setSelectedRole = (selectedRoles) => {
    const array = selectedRoles && selectedRoles.map(role => `role=${role.value}`);
    this.setState({
      selectedRoles,
      filters: { ...this.state.filters, roles: array },
    });
  }

  setSelectedAssetType = (selectedAssetTypes) => {
    const array = selectedAssetTypes && selectedAssetTypes.map(assetType => `assettype=${assetType.value}`);
    this.setState({
      selectedAssetTypes,
      filters: { ...this.state.filters, assetTypeIds: array },
    });
  }

  setSelectedStatus = (selectedStatuses) => {
    const array = selectedStatuses && selectedStatuses.map(status => `status=${status.value}`);
    this.setState({
      selectedStatuses,
      filters: { ...this.state.filters, statuses: array },
    });
  }

  setStartDate = (date) => {
    this.setState({ filters: { ...this.state.filters, startDate: date } });
  }

  setEndDate = (date) => {
    this.setState({ filters: { ...this.state.filters, endDate: date } });
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    const columns = [{
      dataField: 'assignmentId',
      hidden: true,
    }, {
      sort: true,
      dataField: 'status',
      text: i18n.t('generic.status'),
      headerStyle: { width: '120px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell, row) => resolveAssignmentStatus(row),
    }, {
      sort: true,
      dataField: 'userId',
      text: i18n.t('users.user'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => getArrayElementProperty(this.props.users, cell, 'name'),
    }, {
      sort: true,
      dataField: 'userId',
      text: i18n.t('users.role'),
      headerStyle: { width: '160px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => getArrayElementProperty(this.props.users, cell, 'role'),
    }, {
      sort: true,
      dataField: 'assetId',
      text: i18n.t('assets.asset'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => resolveAssetName(this.props.assets, cell),
    }, {
      sort: true,
      dataField: 'assetId',
      text: i18n.t('assets.assetType'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => resolveAssetTypeName(this.props.assetTypes, this.props.assets, cell),
    }, {
      sort: true,
      dataField: 'start',
      text: i18n.t('generic.startTime'),
      headerStyle: { width: '165px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => toDate(cell),
    }, {
      sort: true,
      dataField: 'end',
      text: i18n.t('generic.endTime'),
      headerStyle: { width: '165px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => toDate(cell),
    }];

    const rowEvents = {
      onClick: (e, row) => this.props.history.push(`${this.props.location.pathname}/${row.assignmentId}`),
    };

    const DateInput = ({ value, onClick }) => (
      <IrisButton onClick={onClick} style={{ width: '200px', height: '35px' }} outline>
        {value}
      </IrisButton>
    );

    const animatedComponents = makeAnimated();

    return (
      <div className="AssignmentFilters">
        <Row>
          <Col>
            <IrisBox>
              <div className="title mb-4">
                <h4 className="text-muted"><i className="fa fa-key"/> {i18n.t('assignments.title')}</h4>
              </div>
              <div>
                <Collapse isOpen={this.state.openFilters}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={2}>
                          <Label>{i18n.t('generic.status')}:</Label>
                          <br/>
                          <Select
                            value={this.state.selectedStatuses}
                            onChange={this.setSelectedStatus}
                            options={assignmentStatusOptions}
                            components={animatedComponents}
                            isMulti/>
                        </Col>
                        <Col md={2}>
                          <Label>{i18n.t('users.role')}:</Label>
                          <br/>
                          <Select
                            value={this.state.selectedRoles}
                            onChange={this.setSelectedRole}
                            options={this.roles}
                            components={animatedComponents}
                            isMulti/>
                        </Col>
                        <Col md={3}>
                          <Label>{i18n.t('assets.assetType')}:</Label>
                          <br/>
                          <Select
                            value={this.state.selectedAssetTypes}
                            onChange={this.setSelectedAssetType}
                            options={this.assetTypeOptions}
                            components={animatedComponents}
                            isMulti/>
                        </Col>
                        <Col>
                          <Label>{i18n.t('generic.startTime')}:</Label>
                          <br/>
                          <DatePicker
                            selected={this.state.filters.startDate}
                            onChange={date => this.setStartDate(date)}
                            timeInputLabel=""
                            dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeInput
                            customInput={<DateInput/>}/>
                        </Col>
                        <Col>
                          <Label>{i18n.t('generic.endTime')}:</Label>
                          <br/>
                          <DatePicker
                            selected={this.state.filters.endDate}
                            onChange={date => this.setEndDate(date)}
                            timeInputLabel=""
                            dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeInput
                            customInput={<DateInput/>}/>
                        </Col>
                        <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                          <IrisButton key={_.uniqueId()} color="secondary" size="sm" onClick={this.clearFilter} outline>
                            <i className="fa fa-times-circle fa-lg"/>
                          </IrisButton>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Collapse>
                <br/>
              </div>

              <ToolkitProvider
                keyField="assignmentId"
                data={this.props.assignments || []}
                columns={ columns }
                search={{ searchFormatted: true }}
              >
                {props => (
                  <div>
                    <div style={{ display: 'inline' }}>
                      <div style={{ float: 'left' }}>
                        <SearchBar { ...props.searchProps }/>
                      </div>
                      <div style={{ float: 'right' }}>
                        <IrisButton key={_.uniqueId()} color="primary" size="sm" onClick={this.toggleFilter} disabled={this.state.openFilters} outline>
                          <i className="fa fa-filter" aria-hidden="true"/> {i18n.t('buttons.filter')}
                        </IrisButton>
                        <span>&nbsp;</span>
                        <IrisButton key={_.uniqueId()} color="info" size="sm" onClick={this.toggleModalAssignmentForm} disabled={!this.props.assetTypes?.length} outline>
                          {i18n.t('buttons.create')}...
                        </IrisButton>
                      </div>
                    </div>
                    <BootstrapTable
                      { ...props.baseProps }
                      noDataIndication={i18n.t('notices.noDataIndication')}
                      rowEvents={rowEvents}
                      pagination={paginationFactory({ hideSizePerPage: true })}
                      striped hover/>
                  </div>
                )}
              </ToolkitProvider>
            </IrisBox>
          </Col>
        </Row>

        {this.state.modalAssignmentForm
         && <AssignmentForm title={i18n.t('assignments.createAssignmentForm.title')} icon={<i className="fa fa-key fa-fw"/>}
                            users={this.props.users} assets={this.props.assets} assetTypes={this.props.assetTypes}
                            toggle={this.toggleModalAssignmentForm}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    assignments: fleetmanager.posts.assignments,
    roles: fleetmanager.posts.roles,
    users: fleetmanager.posts.users,
    assets: fleetmanager.posts.assets,
    assetTypes: fleetmanager.posts.assetTypes,
  };
};

export default connect(mapStateToProps, {
  resetState, getAssignments, getUsers, getAssets, getAssetTypes,
})(Assignments);
