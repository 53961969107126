import React from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import {
  func,
  string,
  object,
} from 'prop-types';

import { IrisButton } from 'iris-core/libs/components';

import i18n from '../../i18n';

/*
* Modal to display image
*/
class ImageModal extends React.Component {
  static propTypes = {
    title: string.isRequired,
    url: string.isRequired,
    toggle: func.isRequired,
    icon: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      rotateAngle: 0,
    };
  }

  rotate = () => {
    this.setState({
      rotateAngle: this.state.rotateAngle < 270 ? this.state.rotateAngle + 90 : 0,
    });
  }

  toggleWindow = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.toggle();
  }

  render() {
    const imgResponsive = {
      width: '450px',
      height: '450px',
      transform: `rotate(${this.state.rotateAngle}deg)`,
    };

    return (
      <Modal isOpen={this.state.isOpen} fade={true}>
        <ModalHeader toggle={this.toggleWindow}>
          {this.props.icon} {this.props.title}
        </ModalHeader>
        <ModalBody>
          <img src={this.props.url} style={imgResponsive} alt=""
               title="Click to rotate" onClick={this.rotate}/>
        </ModalBody>
        <ModalFooter>
          <IrisButton color="secondary" size="sm" onClick={this.toggleWindow} outline>
            {i18n.t('buttons.close')}
          </IrisButton>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ImageModal;
