import React from 'react';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import {
  IrisSpinner, IrisBox, IrisButton, IrisDatatable, IrisTableHeaderColumn,
} from 'iris-core/libs/components';

import { AddPolicyRuleForm, RemovePolicyRuleForm } from '../../components';
import {
  getPolicy, getKeystonePolicies, getAssetTypes, getPolicyRules, addOrRemovePolicyRuleFromPolicy,
} from '../../actions';
import { getArrayElementProperty, toDate } from '../../helper';
import { SELECT_ROW_PROPS } from '../../globalConstants';
import i18n from '../../i18n';

import validationTypeOptions from '../../data/ValidationTypeOptions.json';

class PolicyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAddPolicyRuleForm: false,
      modalRemovePolicyRuleForm: false,
    };
  }

  componentDidMount() {
    Promise.all([this.props.getKeystonePolicies(), this.props.getAssetTypes(), this.props.getPolicyRules()]).then(() => {
      this.props.getPolicy(this.props.match.params.rowId);
    });
  }

  mapRules = () => {
    if (!this.props.policyRules || !this.props.policy?.rules) {
      this.mappedRules = [];
      return this.mappedRules;
    }

    this.mappedRules = this.props.policyRules.filter(policyRule => this.props.policy.rules.includes(policyRule.id));
    return this.mappedRules;
  }

  resolveRule = (rule) => {
    if (rule.type === 'required') {
      return 'Required';
    }

    const found = validationTypeOptions.find(r => rule.type === r.value);
    return `${found.label} ${rule.value} ${i18n.t('generic.hours')}`;
  }

  toggleModalAddPolicyRuleForm = () => {
    this.setState({ modalAddPolicyRuleForm: !this.state.modalAddPolicyRuleForm });
  }

  toggleModalRemovePolicyRuleForm = () => {
    this.setState({ modalRemovePolicyRuleForm: !this.state.modalRemovePolicyRuleForm });
  }

  render() {
    if (this.props.detailsType !== 'policy' || this.props.policy.id !== this.props.match.params.rowId) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    this.mapRules();

    const toolbarButtons = [
      <IrisButton key={_.uniqueId()} color="danger" size="sm"
                  disabled={!this.mappedRules.length}
                  onClick={this.toggleModalRemovePolicyRuleForm}
                  outline>{i18n.t('buttons.remove')}...</IrisButton>,
      <IrisButton key={_.uniqueId()} color="info" size="sm"
                  onClick={this.toggleModalAddPolicyRuleForm}
                  outline>{i18n.t('buttons.add')}...</IrisButton>,
    ];

    return (
      <div className="PolicyDetails">
        <Row>
          <Col>
            <IrisBox>
              <Row>
                <Col>
                  <div className="table-title d-flex flex-row align-items-center">
                    <h4 className="mr-auto"><i className="fa fa-file-text-o"/> <small className="text-muted">{this.props.policy.name}, {getArrayElementProperty(this.props.keystonePolicies, this.props.policy.keystonePolicyId, 'name')}</small></h4>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm="4">
                  <Row>
                    <Col sm="3">
                      <div>{i18n.t('generic.name')}</div>
                      <div>{i18n.t('generic.description')}</div>
                      <div>{i18n.t('generic.created')}</div>
                    </Col>
                    <Col sm="9">
                      <div className="text-muted">{this.props.policy.name}</div>
                      <div className="text-muted">{this.props.policy.description || '\u00A0'}</div>
                      <div className="text-muted">{toDate(this.props.policy.createdAt)}</div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="4">
                  <Row>
                    <Col sm="3">
                      <div>{i18n.t('generic.type')}</div>
                      <div>{i18n.t('assets.assetType')}</div>
                      <div>{i18n.t('generic.rules')}</div>
                    </Col>
                    <Col sm="9">
                      <div className="text-muted">{getArrayElementProperty(this.props.keystonePolicies, this.props.policy.keystonePolicyId, 'name')}</div>
                      <div className="text-muted">{getArrayElementProperty(this.props.assetTypes, this.props.policy.assetTypeId, 'name')}</div>
                      <div className="text-muted">{this.props.policy.rules?.length ?? 0}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </IrisBox>
            <IrisBox>
              <div className="title mb-4">
                <h4><small className="text-muted">{i18n.t('policyRules.title')}</small></h4>
              </div>
              <IrisDatatable data={this.mappedRules} toolbar={toolbarButtons} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn dataField="name" searchable>{i18n.t('generic.name')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="description" searchable>{i18n.t('generic.description')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="rules" dataFormat={(cell, row) => this.resolveRule(row)}>{i18n.t('generic.rule')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="createdAt" dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.created')}</IrisTableHeaderColumn>
              </IrisDatatable>
          </IrisBox>
          </Col>
        </Row>

        {this.state.modalAddPolicyRuleForm
         && <AddPolicyRuleForm title="Add Policy Rule" type="policy" parent={this.props.policy}
                               availableRules={this.props.policyRules} resolveRule={this.resolveRule}
                               onSubmit={this.props.addOrRemovePolicyRuleFromPolicy} toggle={this.toggleModalAddPolicyRuleForm}/>}

        {this.state.modalRemovePolicyRuleForm
         && <RemovePolicyRuleForm title="Remove Policy Rule" type="policy" parent={this.props.policy}
                                  rules={this.mappedRules} resolveRule={this.resolveRule}
                                  onSubmit={this.props.addOrRemovePolicyRuleFromPolicy} toggle={this.toggleModalRemovePolicyRuleForm}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    detailsType: fleetmanager.posts.details.type,
    policy: fleetmanager.posts.details.data,
    assetTypes: fleetmanager.posts.assetTypes,
    keystonePolicies: fleetmanager.posts.keystonePolicies,
    policyRules: fleetmanager.posts.policyRules,
    result: fleetmanager.posts.result,
  };
};

export default connect(mapStateToProps, {
  getPolicy,
  getKeystonePolicies,
  getAssetTypes,
  getPolicyRules,
  addOrRemovePolicyRuleFromPolicy,
})(PolicyDetails);
