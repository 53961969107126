import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import {
  IrisDatatable, IrisTableHeaderColumn, IrisBox, IrisButton,
} from 'iris-core/libs/components';

import { AccessRuleForm } from '../../components';
import {
  getAccessRules, getUsers, getAssets, getGroups, createAccessRule,
} from '../../actions';
import { resolveAssetName, toDate } from '../../helper';
import { SELECT_ROW_PROPS } from '../../globalConstants';
import i18n from '../../i18n';

class AccessRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAccessRuleForm: false,
    };
  }

  componentDidMount() {
    Promise.all([this.props.getUsers(), this.props.getAssets(), this.props.getGroups()]).then(() => {
      this.props.getAccessRules();
    });
  }

  resolveUser = (userRef) => {
    if (userRef.type === 'group') {
      return this.props.groups.find(g => g.id === userRef.id).name;
    }

    return this.props.users.find(u => u.id === userRef.id).name;
  }

  resolveAsset = (assetRef) => {
    if (assetRef.type === 'group') {
      return this.props.groups.find(g => g.id === assetRef.id).name;
    }

    return resolveAssetName(this.props.assets, assetRef.id);
  }

  toggleModalAccessRuleForm = () => {
    this.setState({ modalAccessRuleForm: !this.state.modalAccessRuleForm });
  }

  render() {
    const toolbarButtons = [
      <IrisButton key={_.uniqueId()} color="info" size="sm" onClick={this.toggleModalAccessRuleForm} outline>{i18n.t('buttons.create')}...</IrisButton>,
    ];

    const options = {
      onRowClick: (row) => this.props.history.push(`${this.props.location.pathname}/${row.id}`),
    };

    return (
      <div className="AccessRuleList">
        <Row>
          <Col>
            <IrisBox>
              <div className="title mb-4">
                <h4 className="text-muted"><i className="fa fa-file-text-o"/> {i18n.t('accessRules.title')}</h4>
              </div>
              <IrisDatatable data={this.props.accessRules} options={options} toolbar={toolbarButtons} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn dataField="name" searchable>{i18n.t('generic.name')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="description" searchable>{i18n.t('generic.description')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="user" dataFormat={(cell) => this.resolveUser(cell)}>{i18n.t('users.user')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="asset" dataFormat={(cell) => this.resolveAsset(cell)}>{i18n.t('assets.asset')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="createdAt" dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.created')}</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>
          </Col>
        </Row>

        {this.state.modalAccessRuleForm
         && <AccessRuleForm title={i18n.t('accessRules.createAccessRuleForm.title')} icon={<i className="fa fa-file-text-o fa-fw"/>}
                            users={this.props.users} assets={this.props.assets} groups={this.props.groups}
                            onSubmit={this.props.createAccessRule} toggle={this.toggleModalAccessRuleForm}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    accessRules: fleetmanager.posts.accessRules,
    users: fleetmanager.posts.users,
    assets: fleetmanager.posts.assets,
    groups: fleetmanager.posts.groups,
  };
};

export default connect(mapStateToProps, {
  getAccessRules, getUsers, getAssets, getGroups, createAccessRule,
})(AccessRules);
