import React from 'react';

import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import {
  func,
  array,
  string,
  object,
} from 'prop-types';

import { IrisSelect, IrisButton } from 'iris-core/libs/components';

import { generateSelectMap } from '../../helper';
import i18n from '../../i18n';

/*
* Form to remove policy rule
*/
class RemovePolicyRuleForm extends React.Component {
  static propTypes = {
    title: string.isRequired,
    type: string.isRequired,
    parent: object.isRequired,
    rules: array.isRequired,
    resolveRule: func.isRequired,
    toggle: func.isRequired,
    onSubmit: func.isRequired,
    icon: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.rulesMap = {};
    this.rules = generateSelectMap(this.props.rules, this.rulesMap);

    this.state = {
      isOpen: true,
      ruleId: this.rules?.[0]?.value ?? '',
    };
  }

  toggleWindow = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.toggle();
  }

  onSubmit = (event) => {
    event.preventDefault();
    const request = {
      action: 'remove',
      ruleId: this.state.ruleId,
      ruleName: this.rulesMap[this.state.ruleId].name, // Only for notifications - not persisted in DB
    };

    request[`${this.props.type}Id`] = this.props.parent.id;
    request[`${this.props.type}Name`] = this.props.parent.name; // Only for notifications - not persisted in DB

    this.props.onSubmit(request).then(() => {
      this.toggleWindow();
    });
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen} fade={true}>
        <Form className="modal-content" onSubmit={this.onSubmit}>
          <ModalHeader toggle={this.toggleWindow}>
            {this.props.icon} {this.props.title}
          </ModalHeader>
          <ModalBody>
            {this.props.type === 'policy' && <p>{i18n.t('policies.removePolicyRuleForm.description')}</p>}
            {this.props.type === 'user' && <p>{i18n.t('users.removeQualificationForm.description')}</p>}
            <hr/>
            <FormGroup>
              <Label>{i18n.t('generic.selectRule')}:&nbsp;</Label>
              <IrisSelect options={this.rules} onChange={value => this.setState({ ruleId: value })} outline/>
            </FormGroup>
            <FormGroup>
              <Label>{i18n.t('generic.ruleDefinition')}:&nbsp;</Label>
              <span>{this.props.resolveRule(this.rulesMap[this.state.ruleId])}</span>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <IrisButton color="danger" size="sm" type="submit">
              {i18n.t('buttons.remove')}
            </IrisButton>
            <IrisButton color="secondary" size="sm" onClick={this.toggleWindow} outline>
              {i18n.t('buttons.close')}
            </IrisButton>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default RemovePolicyRuleForm;
