import React from 'react';
import './style.css';

import {
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
} from 'reactstrap';
import DatePicker from 'react-datepicker';

import {
  func,
  string,
  object,
} from 'prop-types';

import { IrisButton } from 'iris-core/libs/components';

import { showFormValidationErrors } from '../../helper';
import i18n from '../../i18n';

/*
* Form to update assignment
*/
class UpdateAssignmentForm extends React.Component {
  static propTypes = {
    title: string.isRequired,
    assignment: object.isRequired,
    onSubmit: func.isRequired,
    toggle: func.isRequired,
    icon: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      startDate: new Date(this.props.assignment.start),
      endDate: new Date(this.props.assignment.end),
      validationErrors: [],
    };

    this.origStartTime = this.state.startDate.getTime();
  }

  componentDidUpdate(prevProps, prevState) {
    const start = this.state.startDate;
    const end = this.state.endDate;

    if (start.getTime() !== prevState.startDate.getTime() || end.getTime() !== prevState.endDate.getTime()) {
      const validationErrors = [];

      this.validateTime(start, end, validationErrors);
      this.setState({ validationErrors });
    }
  }

  toggleWindow = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.toggle();
  }

  validateTime = (startDate, endDate, validationErrors) => {
    if (startDate.getTime() !== this.origStartTime && startDate.getTime() <= (Date.now() - 600000)) {
      validationErrors.push('Change start-time - Must be after current time');
    }

    if (endDate.getTime() <= startDate.getTime()) {
      validationErrors.push('Change end-time - Must be after start-time');
    }
  }

  onSubmit = (event) => {
    event.preventDefault();

    const validationErrors = [];

    this.validateTime(this.state.startDate, this.state.endDate, validationErrors);
    this.setState({ validationErrors });

    if (validationErrors.length) {
      return;
    }

    this.props.onSubmit({
      id: this.props.assignment.assignmentId,
      name: this.props.assignment.name,
      description: 'Long-term assignment',
      userId: this.props.assignment.userId,
      assetId: this.props.assignment.assetId,
      policyId: this.props.assignment.policyId,
      start: this.state.startDate.toISOString(),
      end: this.state.endDate.toISOString(),
    }).then(() => {
      this.toggleWindow();
    });
  }

  render() {
    const DateInput = React.forwardRef((props, ref) => {
      return (<IrisButton onClick={props.onClick} outline>{props.value}</IrisButton>);
    });

    return (
      <Modal isOpen={this.state.isOpen} size="md" fade={true}>
        <ModalHeader toggle={this.toggleWindow}>
          {this.props.icon} {this.props.title}
        </ModalHeader>

        <ModalBody>
          {showFormValidationErrors(this.state.validationErrors)}
          <Row>
            <Col>
              <FormGroup>
                <Label>{i18n.t('generic.startTime')}:</Label>
                <br/>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={date => this.setState({ startDate: date })}
                  timeInputLabel=""
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  customInput={<DateInput/>}/>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{i18n.t('generic.endTime')}:</Label>
                <br/>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={date => this.setState({ endDate: date })}
                  timeInputLabel=""
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  customInput={<DateInput/>}/>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <IrisButton color="info" size="sm" type="submit" onClick={this.onSubmit}>
            {i18n.t('buttons.update')}
          </IrisButton>
          <IrisButton color="secondary" size="sm" onClick={this.toggleWindow} outline>
            {i18n.t('buttons.close')}
          </IrisButton>
        </ModalFooter>
      </Modal>
    );
  }
}

export default UpdateAssignmentForm;
