import React from 'react';
import { connect } from 'react-redux';
import './style.css';

import {
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
} from 'reactstrap';
import DatePicker from 'react-datepicker';

import {
  func,
  array,
  string,
  object,
} from 'prop-types';

import { IrisSelect, IrisButton } from 'iris-core/libs/components';

import { createAssignment } from '../../actions';
import {
  generateSelectMap, generateSelectMapWithFilter, isAssetWorkerRole, showFormValidationErrors,
} from '../../helper';
import { SERVER_ERROR } from '../../globalConstants';
import i18n from '../../i18n';

/*
* Form to create assignment
*/
class AssignmentForm extends React.Component {
  static propTypes = {
    title: string.isRequired,
    users: array.isRequired,
    assets: array.isRequired,
    assetTypes: array.isRequired,
    error: object,
    createAssignment: func,
    toggle: func.isRequired,
    icon: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.users = generateSelectMapWithFilter(this.props.users, {}, user => isAssetWorkerRole(user.role));

    this.assetTypes = generateSelectMap(this.props.assetTypes, {});

    this.assetsByAssetTypeMap = {};
    for (const asset of this.props.assets) {
      const element = {
        value: asset.id,
        label: asset.licensePlate || asset.externalId || asset.id,
      };

      if (this.assetsByAssetTypeMap[asset.assetTypeId]) {
        this.assetsByAssetTypeMap[asset.assetTypeId].push(element);
      } else {
        this.assetsByAssetTypeMap[asset.assetTypeId] = [element];
      }
    }

    const assetTypeId = this.assetTypes[0].value;
    const assets = this.assetsByAssetTypeMap[assetTypeId];
    const assetId = assets ? assets[0].value : null;

    const startDate = new Date();
    const endDate = new Date();
    endDate.setFullYear(startDate.getFullYear() + 1);
    endDate.setHours(23, 59, 0, 0);

    this.state = {
      isOpen: true,
      userId: this.users?.[0]?.value ?? null,
      assetTypeId,
      assetId,
      startDate,
      endDate,
      validationErrors: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const start = this.state.startDate;
    const end = this.state.endDate;

    if (start.getTime() !== prevState.startDate.getTime() || end.getTime() !== prevState.endDate.getTime()) {
      const validationErrors = [];

      this.validateTime(start, end, validationErrors);
      this.setState({ validationErrors });
    }
  }

  toggleWindow = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.toggle();
  }

  onAssetTypeOptionChange = (assetTypeId) => {
    this.setState({
      assetTypeId,
      assetId: this.getAssetIdFromSelectedAssetTypeId(assetTypeId),
    });
  }

  getAssetIdFromSelectedAssetTypeId = (assetTypeId) => {
    const assets = this.assetsByAssetTypeMap[assetTypeId];
    if (!assets) {
      return null;
    }

    let asset = assets.find(a => a.value === this.state.assetId);
    if (!asset) { // Select first one if selected asset is not available
      [asset] = assets;
    }

    return asset.value;
  }

  getAssets = () => {
    return this.assetsByAssetTypeMap[this.state.assetTypeId] || [];
  }

  validateTime = (startDate, endDate, validationErrors) => {
    // Give user at least 10 minutes to create an assignment
    if (startDate.getTime() <= (Date.now() - 600000)) {
      validationErrors.push('Change start-time - Must be after current time');
    }

    if (endDate.getTime() <= startDate.getTime()) {
      validationErrors.push('Change end-time - Must be after start-time');
    }
  }

  onSubmit = (event) => {
    event.preventDefault();

    const validationErrors = [];

    this.validateTime(this.state.startDate, this.state.endDate, validationErrors);

    if (!this.state.userId) {
      validationErrors.push('Select user - No applicable user found');
    }

    if (!this.state.assetId) {
      validationErrors.push('Select asset - Ensure an asset of the selected type is added');
    }

    this.setState({ validationErrors });

    if (validationErrors.length) {
      return;
    }

    this.props.createAssignment({
      name: 'Access-Control',
      description: 'Long-term assignment',
      assetId: this.state.assetId,
      userId: this.state.userId,
      start: this.state.startDate.toISOString(),
      end: this.state.endDate.toISOString(),
    }).then(() => {
      if (!this.props.error) {
        this.toggleWindow();
        return;
      }

      validationErrors.push(this.props.error.errorCode === SERVER_ERROR.BOOKING_CONFLICT
        ? this.props.error.message
        : 'Unexpected failure; please retry');
      this.setState({ validationErrors });
    });
  }

  render() {
    const DateInput = React.forwardRef((props, ref) => {
      return (<IrisButton onClick={props.onClick} outline>{props.value}</IrisButton>);
    });

    return (
      <Modal isOpen={this.state.isOpen} size="md" fade={true}>
        <ModalHeader toggle={this.toggleWindow}>
          {this.props.icon} {this.props.title}
        </ModalHeader>

        <ModalBody>
          {showFormValidationErrors(this.state.validationErrors)}
          <Row>
            <Col>
              <FormGroup>
                <Label>{i18n.t('generic.startTime')}:</Label>
                <br/>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={date => this.setState({ startDate: date })}
                  timeInputLabel=""
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  customInput={<DateInput/>}/>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{i18n.t('generic.endTime')}:</Label>
                <br/>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={date => this.setState({ endDate: date })}
                  timeInputLabel=""
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  customInput={<DateInput/>}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{i18n.t('assets.asset')}:</Label>
                <div>
                  <IrisSelect className="asset-type-select" options={this.assetTypes}
                              onChange={value => this.onAssetTypeOptionChange(value)} outline/>
                  <span>&nbsp;</span>
                  <IrisSelect className="asset-select" options={this.getAssets()}
                              onChange={value => this.setState({ assetId: value })} value={this.state.assetId || ''} outline/>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{i18n.t('users.user')}:&nbsp;</Label>
                <IrisSelect options={this.users} onChange={value => this.setState({ userId: value })} outline/>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <IrisButton color="info" size="sm" type="submit" onClick={this.onSubmit}>
            {i18n.t('buttons.create')}
          </IrisButton>
          <IrisButton color="secondary" size="sm" onClick={this.toggleWindow} outline>
            {i18n.t('buttons.close')}
          </IrisButton>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    error: fleetmanager.posts.error,
  };
};

export default connect(mapStateToProps, {
  createAssignment,
})(AssignmentForm);
