import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { changeMenuConfig } from 'iris-core/libs/actions';
import { ADMIN, TENANT, ANALYST } from 'iris-core/libs/UserRoles';
import { NotFound } from 'iris-core/libs/views';
import { IrisSpinner } from 'iris-core/libs/components';

import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

import serviceReducer from './reducers';
import { reducers } from './widgets';
import {
  Dashboard,
  Assets,
  Users,
  Groups,
  Bookings,
  Assignments,
  Policies,
  PolicyRules,
  AccessRules,
  Telemetry,
  Support,
  SwitchAccount,
  AssetDetails,
  UserDetails,
  GroupDetails,
  BookingDetails,
  AssignmentDetails,
  PolicyDetails,
  AccessRuleDetails,
  ChecklistDetails,
} from './views';

import {
  authenticateKeystoneUser, getAdminAccounts, getRoles, updateSysConfig,
} from './actions';
import { getArtifacts } from './helper';
import assetIconPurple from './assets/excavator_purple.png';
import assetIcon from './assets/excavator_gray.png';
import i18n from './i18n';

const { REACT_APP_SERVICE_ID, REACT_APP_CORE_OFFLINE, REACT_APP_KPS_URL } = process.env;

class FleetManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedLocale: false,
      loadedAdminAccounts: false,
      loadedGlobalArtifacts: false,
    };
  }

  componentDidMount() {
    if (REACT_APP_CORE_OFFLINE === 'true') {
      const loginEmail = new URLSearchParams(window.location.search).get('loginEmail');
      if (loginEmail) {
        localStorage.setItem('offline_login_email', this.props.user.details.email = loginEmail);
      } else {
        this.props.user.details.email = localStorage.getItem('offline_login_email');
      }
    }

    // Use previously logged-in account when not switching account
    this.props.authenticateKeystoneUser(this.props.user.details.email, localStorage.getItem('accountId')).then(() => {
      if (this.props.error === 'authentication_error') {
        return;
      }

      this.props.getRoles(this.props.profile.user.role);
      this.props.getAdminAccounts().then(() => {
        this.setState({ loadedAdminAccounts: true });
      });

      i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          fallbackLng: 'en',
          load: 'languageOnly',
          interpolation: { escapeValue: false },
          backend: {
            loadPath: `${REACT_APP_KPS_URL}/locales/{{lng}}`,
            customHeaders: { Authorization: `Bearer ${localStorage.getItem('iksUserId')}` },
          },
        }, () => {
          this.setState({ loadedLocale: true });
        });

      if (REACT_APP_CORE_OFFLINE === 'true') {
        this.props.user.details.user_metadata.email = this.props.profile.user.email;
        [this.props.user.details.user_metadata.nickname] = this.props.profile.user.email.split('@');
        this.props.user.details.user_metadata.fullName = this.props.profile.user.name;
      }

      window.BASE_PATH = `/${this.props.user.viewingAs}/${REACT_APP_SERVICE_ID}`;
      window.PINMAP_API_KEY = this.props.profile.config.pinmapApiKey;
      let lang = (window.navigator.userLanguage ?? window.navigator.language).split('-')[0];
      if (lang === 'no' || lang === 'nn') {
        lang = 'nb';
      }
      window.tokenStorage = {
        baseArtifactUrl: this.props.profile.config.baseArtifactUrl,
        lang,
      };

      getArtifacts([
        this.props.profile.config.i18nChecksUrl,
        this.props.profile.config.i18nSpecsUrl,
      ]).then(resultArray => {
        [window.i18nChecks, window.i18nSpecs] = resultArray;
        this.setState({ loadedGlobalArtifacts: true });
      });

      if (!this.props.profile.config.initFlag) {
        this.props.updateSysConfig({
          'frontend.initFlag': true,
          'backend.frontendBaseUrl': `${window.location.origin}/${TENANT}/${REACT_APP_SERVICE_ID}`,
        });
      }
    });
  }

  render() {
    if (this.props.error === 'authentication_error') {
      return (<h1><i className="fa fa-ban" style={{ color: 'red' }}/> Authentication failed</h1>);
    }

    if (!(this.props.profile && this.state.loadedAdminAccounts && this.state.loadedLocale && this.state.loadedGlobalArtifacts)) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    this.props.changeMenuConfig([{
      label: i18n.t('sidebar.title'),
      link: window.BASE_PATH,
      items: [
        ...this.props.profile.account.iris.dashboard.show ? [{
          label: i18n.t('sidebar.dashboard'),
          link: `${window.BASE_PATH}/dashboard`,
          icon: <i className="fa fa-dashboard"/>,
        }] : [],
        ...this.props.profile.account.iris.assets.show ? [{
          label: i18n.t('assets.title'),
          link: `${window.BASE_PATH}/assets`,
          icon: <img src={assetIcon} alt="" width="20" height="20"/>,
        }] : [],
        ...this.props.profile.account.iris.users.show ? [{
          label: i18n.t('users.title'),
          link: `${window.BASE_PATH}/users`,
          icon: <i className="fa fa-user"/>,
        }] : [],
        ...this.props.profile.account.iris.groups.show ? [{
          label: i18n.t('groups.title'),
          link: `${window.BASE_PATH}/groups`,
          icon: <i className="fa fa-users"/>,
        }] : [],
        ...this.props.profile.account.iris.bookings.show ? [{
          label: i18n.t('bookings.title'),
          link: `${window.BASE_PATH}/bookings`,
          icon: <i className="fa fa-key"/>,
        }] : [{
          label: i18n.t('assignments.title'),
          link: `${window.BASE_PATH}/assignments`,
          icon: <i className="fa fa-key"/>,
        }],
        ...this.props.profile.account.iris.accessRules.show ? [{
          label: i18n.t('accessRules.title'),
          link: `${window.BASE_PATH}/accessrules`,
          icon: <i className="fa fa-file-text-o"/>,
        }] : [],
        ...this.props.profile.account.iris.policyRules.show ? [{
          label: i18n.t('policyRules.title'),
          link: `${window.BASE_PATH}/policyrules`,
          icon: <i className="fa fa-file-text-o"/>,
        }] : [],
        ...this.props.profile.account.iris.policies.show ? [{
          label: i18n.t('policies.title'),
          link: `${window.BASE_PATH}/policies`,
          icon: <i className="fa fa-file-text-o"/>,
        }] : [],
        ...this.props.profile.account.iris.telemetry.show ? [{
          label: i18n.t('sidebar.telemetry'),
          link: `${window.BASE_PATH}/telemetry`,
          icon: <i className="fa fa-rss"/>,
        }] : [],
        ...this.props.profile.account.iris.support.show ? [{
          label: i18n.t('sidebar.support'),
          link: `${window.BASE_PATH}/support`,
          icon: <i className="fa fa-support"/>,
        }] : [],
        ...this.props.adminAccounts.length ? [{
          label: i18n.t('switchAccount.title'),
          link: `${window.BASE_PATH}/switch`,
          icon: <i className="fa fa-refresh"/>,
        }] : [],
      ],
    }]);

    this.props.leftMenu.isOpen = true;

    return (
      <div>
        <Switch>
          <Route exact path={'/:role/:service'}>
            <Redirect to={`${window.BASE_PATH}/${this.props.profile.account.iris.dashboard.show ? 'dashboard' : 'assets'}`}/>
          </Route>
          <Route exact path={'/:role/:service/dashboard'} component={Dashboard}/>
          <Route exact path={'/:role/:service/switch'} component={SwitchAccount}>
            <Redirect to={`${window.BASE_PATH}/switch`}/>
          </Route>
          <Route exact path={'/:role/:service/assets'} component={Assets}>
            <Redirect to={`${window.BASE_PATH}/assets`}/>
          </Route>
          <Route exact path={'/:role/:service/users'} component={Users}>
            <Redirect to={`${window.BASE_PATH}/users`}/>
          </Route>
          <Route exact path={'/:role/:service/groups'} component={Groups}>
            <Redirect to={`${window.BASE_PATH}/groups`}/>
          </Route>
          <Route exact path={'/:role/:service/bookings'} component={Bookings}>
            <Redirect to={`${window.BASE_PATH}/bookings`}/>
          </Route>
          <Route exact path={'/:role/:service/assignments'} component={Assignments}>
            <Redirect to={`${window.BASE_PATH}/assignments`}/>
          </Route>
          <Route exact path={'/:role/:service/accessrules'} component={AccessRules}>
            <Redirect to={`${window.BASE_PATH}/accessrules`}/>
          </Route>
          <Route exact path={'/:role/:service/policyrules'} component={PolicyRules}>
            <Redirect to={`${window.BASE_PATH}/policyrules`}/>
          </Route>
          <Route exact path={'/:role/:service/policies'} component={Policies}>
            <Redirect to={`${window.BASE_PATH}/policies`}/>
          </Route>
          <Route exact path={'/:role/:service/telemetry'} component={Telemetry}>
            <Redirect to={`${window.BASE_PATH}/telemetry`}/>
          </Route>
          <Route exact path={'/:role/:service/support'} component={Support}>
            <Redirect to={`${window.BASE_PATH}/support`}/>
          </Route>
          <Route exact path={'/:role/:service/assets/:rowId'} component={AssetDetails}/>
          <Route exact path={'/:role/:service/assets/:rowId/problems/:checklistId'} component={ChecklistDetails}/>
          <Route exact path={'/:role/:service/users/:rowId'} component={UserDetails}/>
          <Route exact path={'/:role/:service/groups/:rowId'} component={GroupDetails}/>
          <Route exact path={'/:role/:service/bookings/:rowId'} component={BookingDetails}/>
          <Route exact path={'/:role/:service/bookings/:rowId/checklists/:checklistId'} component={ChecklistDetails}/>
          <Route exact path={'/:role/:service/assignments/:rowId'} component={AssignmentDetails}/>
          <Route exact path={'/:role/:service/accessrules/:rowId'} component={AccessRuleDetails}/>
          <Route exact path={'/:role/:service/policies/:rowId'} component={PolicyDetails}/>
          <Redirect from={'/:role/:service/assets/:rowId/problems'} to={'/:role/:service/assets/:rowId'}/>
          <Redirect from={'/:role/:service/bookings/:rowId/checklists'} to={'/:role/:service/bookings/:rowId'}/>
          <Route component={NotFound}/>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = ({ core = {}, fleetmanager = {} }) => ({
  user: core.user,
  leftMenu: core.leftMenu,
  profile: fleetmanager.posts.profile,
  adminAccounts: fleetmanager.posts.adminAccounts,
  roles: fleetmanager.posts.roles,
  error: fleetmanager.posts.error,
});

export const component = connect(mapStateToProps, {
  changeMenuConfig, authenticateKeystoneUser, getAdminAccounts, getRoles, updateSysConfig,
})(FleetManager);

export const reducer = { ...serviceReducer, ...reducers };

export const info = {
  id: REACT_APP_SERVICE_ID,
  url: `/${REACT_APP_SERVICE_ID}`,
  name: 'Imperto Rental Manager',
  description: `${process.env.REACT_APP_VERSION}`,
  icon: <img src={assetIconPurple} alt="Logo" width="64" height="64"/>,
  activeRoles: [ADMIN, TENANT, ANALYST],
};

export default {
  component,
  info,
  reducer,
};
