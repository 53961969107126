import React from 'react';

import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import {
  func,
  string,
  array,
  object,
} from 'prop-types';

import { IrisSelect, IrisButton } from 'iris-core/libs/components';

import { GENERIC_NAME_MAX_LENGTH } from '../../globalConstants';
import i18n from '../../i18n';

/*
* Form to create/update policy rule
*/
class PolicyRuleForm extends React.Component {
  static propTypes = {
    title: string.isRequired,
    validationTypes: array.isRequired,
    toggle: func.isRequired,
    onSubmit: func.isRequired,
    icon: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      name: '',
      description: '',
      validationType: this.props.validationTypes[0].value,
      validationValue: null,
    };
  }

  toggleWindow = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.toggle();
  }

  onSubmit = (event) => {
    event.preventDefault();
    if (this.state.validationType === 'required') {
      this.setState({ validationValue: true });
    }
    this.props.onSubmit({
      name: this.state.name,
      description: this.state.description || undefined,
      type: this.state.validationType,
      value: this.state.validationValue,
    }).then(() => {
      this.toggleWindow();
    });
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen} fade={true}>
        <Form className="modal-content" onSubmit={this.onSubmit}>
          <ModalHeader toggle={this.toggleWindow}>
            {this.props.icon} {this.props.title}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>{i18n.t('generic.name')}:</Label>
              <Input required name="name" maxLength={GENERIC_NAME_MAX_LENGTH} placeholder={i18n.t('policyRules.createPolicyRuleForm.namePlaceholder')}
                     onChange={e => this.setState({ name: e.target.value })} value={this.state.name}/>
            </FormGroup>
            <FormGroup>
              <Label>{i18n.t('generic.description')}:</Label>
              <Input name="description" placeholder={i18n.t('policyRules.createPolicyRuleForm.descriptionPlaceholder')}
                     onChange={e => this.setState({ description: e.target.value })} value={this.state.description}/>
            </FormGroup>
            <hr/>
            <FormGroup>
              <Label>{i18n.t('generic.type')}:&nbsp;</Label>
              <IrisSelect options={this.props.validationTypes} onChange={value => this.setState({ validationType: value })} outline/>
            </FormGroup>
            {this.state.validationType !== 'required' && <FormGroup>
              <Label>{i18n.t('generic.value')}:</Label>
              <Input name="value" placeholder={i18n.t('policyRules.createPolicyRuleForm.valuePlaceholder')}
                     onChange={e => this.setState({ validationValue: e.target.value })} value={this.state.validationValue}/>
            </FormGroup>}
          </ModalBody>
          <ModalFooter>
            <IrisButton color="info" size="sm" type="submit">
              {i18n.t('buttons.create')}
            </IrisButton>
            <IrisButton color="secondary" size="sm" onClick={this.toggleWindow} outline>
              {i18n.t('buttons.close')}
            </IrisButton>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default PolicyRuleForm;
