import axios from 'axios';
import _ from 'lodash';
import { showNotification } from 'iris-core/libs/actions';

import { getArtifacts } from '../helper';
import {
  RESET, AUTHENTICATE_START, AUTHENTICATE_DONE, AUTHENTICATE_ERROR, POSTS_LIST_START, LIST_ADMIN_ACCOUNTS_DONE, LIST_ASSET_TYPES_DONE,
  LIST_ASSETS_DONE, LIST_ASSET_CHECKLISTS_DONE, LIST_ASSET_BOOKINGS_DONE, LIST_ASSET_ASSIGNMENTS_DONE, LIST_ASSET_USAGES_DONE,
  LIST_USERS_DONE, LIST_USER_ASSIGNMENTS_DONE, LIST_USER_AUTH_RECORDS_DONE, LIST_ROLES_DONE, LIST_GROUPS_DONE, LIST_IMAGE_REFERENCES_DONE,
  LIST_ASSIGNMENTS_DONE, LIST_ASSIGNMENT_CHECKLISTS_DONE, LIST_ASSIGNMENT_EVENTS_DONE, LIST_BOOKINGS_DONE, LIST_BOOKING_ITEMS_DONE,
  LIST_BOOKING_CHECKLISTS_DONE, LIST_BOOKING_EVENTS_DONE, LIST_AVAILABLE_ASSETS_FOR_BOOKING_DONE, LIST_CURRENT_BOOKINGS_DONE,
  LIST_KEYSTONE_POLICIES_DONE, LIST_POLICIES_DONE, LIST_POLICY_RULES_DONE, LIST_ACCESS_RULES_DONE, POSTS_LIST_ERROR, GET_IMAGE_DONE,
  GET_DETAILS_START, GET_DETAILS_DONE, GET_DETAILS_ERROR, POST_START, POST_DONE, POST_ERROR, UPDATE_DETAILS_START, UPDATE_DETAILS_DONE,
  UPDATE_DETAILS_ERROR, DELETE_START, DELETE_DONE, DELETE_ERROR,
} from '../constants';
import { ROLE, PORTAL_ROLES } from '../globalConstants';

const { REACT_APP_KPS_URL } = process.env;
let REQ_CONFIG;

export const resetState = () => (dispatch, getState) => {
  dispatch({ type: RESET });
};

export const authenticateKeystoneUser = (email, accountId) => (dispatch, getState) => {
  dispatch({ type: AUTHENTICATE_START });
  localStorage.removeItem('iksUserId'); // Keep track of userId for fetching locale using a different XHR mechanism instead of axios
  localStorage.removeItem('accountId');
  return axios.post(`${REACT_APP_KPS_URL}/authenticate`, { email, accountId })
    .then(response => {
      localStorage.setItem('iksUserId', response.data.user.id);
      REQ_CONFIG = { headers: { Authorization: `Bearer ${response.data.user.id}` } };

      if (accountId) {
        localStorage.setItem('accountId', accountId);
        REQ_CONFIG.headers['X-Account-ID'] = accountId;
      }

      dispatch({ type: AUTHENTICATE_DONE, profile: response.data });
    })
    .catch(() => {
      dispatch({ type: AUTHENTICATE_ERROR, error: 'authentication_error' });
      showNotification('Failed to authenticate user')(dispatch);
    });
};

export const getAdminAccounts = () => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/accounts/admin`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_ADMIN_ACCOUNTS_DONE, adminAccounts: response.data });
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch admin accounts')(dispatch);
    });
};

export const getKeystonePolicies = () => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/keystonepolicies`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_KEYSTONE_POLICIES_DONE, keystonePolicies: response.data });
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch Keystone policies')(dispatch);
    });
};

export const getAssetTypes = () => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/assettypes`, REQ_CONFIG)
    .then(async response => {
      if (response.data.length) {
        const assetTypes = response.data;
        const resultArray = await getArtifacts(assetTypes.map(assetType => assetType.i18nAssetUrl));
        for (let i = resultArray.length; --i >= 0;) {
          Object.assign(assetTypes[i], resultArray[i]);
        }
        dispatch({ type: LIST_ASSET_TYPES_DONE, assetTypes: _.sortBy(assetTypes, 'name') });
      } else {
        dispatch({ type: LIST_ASSET_TYPES_DONE, assetTypes: response.data });
      }
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch asset-types')(dispatch);
    });
};

export const getAsset = (assetId) => (dispatch, getState) => {
  dispatch({ type: GET_DETAILS_START });
  return axios.get(`${REACT_APP_KPS_URL}/assets/${assetId}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: GET_DETAILS_DONE, details: { type: 'asset', data: response.data } });
    })
    .catch(error => {
      dispatch({ type: GET_DETAILS_ERROR, error });
      showNotification(`Failed to fetch asset: ${assetId}`)(dispatch);
    });
};

export const getAssets = () => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/assets`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_ASSETS_DONE, assets: response.data });
      showNotification(`Retrieved ${_(response.data).size()} assets`, { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch assets')(dispatch);
    });
};

export const getAssetChecklists = (assetId, type) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  const query = type ? `?type=${type}` : '';
  return axios.get(`${REACT_APP_KPS_URL}/assets/${assetId}/checklists${query}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_ASSET_CHECKLISTS_DONE, assetChecklists: response.data });
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch problem reports')(dispatch);
    });
};

export const getAssetBookings = (assetId) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/assets/${assetId}/bookings`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_ASSET_BOOKINGS_DONE, assetBookings: response.data });
      showNotification(`Retrieved ${_(response.data).size()} bookings`, { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch bookings')(dispatch);
    });
};

export const getAssetAssignments = (assetId) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/assets/${assetId}/assignments`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_ASSET_ASSIGNMENTS_DONE, assetAssignments: response.data });
      showNotification(`Retrieved ${_(response.data).size()} assignments`, { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch assignments')(dispatch);
    });
};

export const getAssetUsages = (assetId) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/assets/${assetId}/assetusages`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_ASSET_USAGES_DONE, assetUsages: response.data });
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch asset-usages')(dispatch);
    });
};

export const createAsset = (asset) => (dispatch, getState) => {
  dispatch({ type: POST_START });
  return axios.post(`${REACT_APP_KPS_URL}/assets`, asset, REQ_CONFIG)
    .then(response => {
      dispatch({ type: POST_DONE, result: { type: 'asset', data: response.data } });
      showNotification(`Created asset: ${asset.id}`, { notificationType: 'success' })(dispatch);
    })
    .catch(() => {
      dispatch({ type: POST_ERROR, error: 'asset_creation_error' });
      showNotification(`Failed to create asset: ${asset.id}`)(dispatch);
    });
};

export const updateAsset = (asset) => (dispatch, getState) => {
  dispatch({ type: UPDATE_DETAILS_START });
  return axios.put(`${REACT_APP_KPS_URL}/assets/${asset.id}`, asset, REQ_CONFIG)
    .then(response => {
      dispatch({ type: UPDATE_DETAILS_DONE, details: { type: 'asset', data: response.data } });
      showNotification(`Updated asset: ${asset.id}`, { notificationType: 'success' })(dispatch);
    })
    .catch(() => {
      dispatch({ type: UPDATE_DETAILS_ERROR, error: 'asset_update_error' });
      showNotification(`Failed to update asset: ${asset.id}`)(dispatch);
    });
};

export const deleteAsset = (assetId) => (dispatch, getState) => {
  dispatch({ type: DELETE_START });
  return axios.delete(`${REACT_APP_KPS_URL}/assets/${assetId}`, REQ_CONFIG)
    .then(() => {
      dispatch({ type: DELETE_DONE, result: { type: 'asset', data: assetId } });
      showNotification(`Deleted asset: ${assetId}`, { notificationType: 'success' })(dispatch);
    })
    .catch(() => {
      dispatch({ type: DELETE_ERROR, error: 'asset_deletion_error' });
      showNotification(`Failed to delete asset: ${assetId}`)(dispatch);
    });
};

export const getAssignment = (assignmentId) => (dispatch, getState) => {
  dispatch({ type: GET_DETAILS_START });
  return axios.get(`${REACT_APP_KPS_URL}/assignments/${assignmentId}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: GET_DETAILS_DONE, details: { type: 'assignment', data: response.data } });
    })
    .catch(error => {
      dispatch({ type: GET_DETAILS_ERROR, error });
      showNotification('Failed to fetch assignment')(dispatch);
    });
};

export const getAssignments = (urlEncodedQuery) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  const query = urlEncodedQuery ? `?${urlEncodedQuery}` : '';
  return axios.get(`${REACT_APP_KPS_URL}/assignments${query}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_ASSIGNMENTS_DONE, assignments: response.data });
      showNotification(`Retrieved ${_(response.data).size()} assignments`, { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch assignments')(dispatch);
    });
};

export const getAssignmentChecklists = (assignmentId) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/assignments/${assignmentId}/checklists`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_ASSIGNMENT_CHECKLISTS_DONE, assignmentChecklists: response.data });
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch submitted reports')(dispatch);
    });
};

export const getAssignmentEvents = (assignmentId) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/assignments/${assignmentId}/events`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_ASSIGNMENT_EVENTS_DONE, assignmentEvents: response.data });
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch assignment events')(dispatch);
    });
};

export const createAssignment = (assignment) => (dispatch, getState) => {
  dispatch({ type: POST_START });
  return axios.post(`${REACT_APP_KPS_URL}/assignments`, assignment, REQ_CONFIG)
    .then(response => {
      dispatch({ type: POST_DONE, result: { type: 'assignment', data: response.data } });
      showNotification('Created assignment', { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: POST_ERROR, error: error.response.data });
      showNotification('Failed to create assignment')(dispatch);
    });
};

export const createAllUserAssignments = (body) => (dispatch, getState) => {
  dispatch({ type: POST_START });
  return axios.post(`${REACT_APP_KPS_URL}/assignments/createall`, body, REQ_CONFIG)
    .then(response => {
      dispatch({ type: POST_DONE });
      showNotification('Created all user assignments', { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: POST_ERROR, error });
      showNotification('Failed to create all user assignments')(dispatch);
    });
};

export const cancelAllUserAssignments = (userId) => (dispatch, getState) => {
  dispatch({ type: DELETE_START });
  return axios.delete(`${REACT_APP_KPS_URL}/assignments/cancelall?userId=${userId}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: DELETE_DONE });
      showNotification('Cancelled all user assignments', { notificationType: 'success' })(dispatch);
    })
    .catch(() => {
      dispatch({ type: DELETE_ERROR, error: 'cancel_all_assignments_error' });
      showNotification('Failed to cancel all user assignments')(dispatch);
    });
};

export const updateAssignment = (assignment) => (dispatch, getState) => {
  dispatch({ type: UPDATE_DETAILS_START });
  return axios.put(`${REACT_APP_KPS_URL}/assignments/${assignment.id}`, assignment, REQ_CONFIG)
    .then(response => {
      dispatch({ type: UPDATE_DETAILS_DONE, details: { type: 'assignment', data: response.data } });
      showNotification('Updated assignment', { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: UPDATE_DETAILS_ERROR, error: error.response.data });
      showNotification('Failed to update assignment')(dispatch);
    });
};

export const revokeAssignment = (assignmentId) => (dispatch, getState) => {
  dispatch({ type: DELETE_START });
  return axios.delete(`${REACT_APP_KPS_URL}/assignments/revoke/${assignmentId}`, REQ_CONFIG)
    .then(() => {
      dispatch({ type: DELETE_DONE, result: { type: 'assignment', data: assignmentId } });
    })
    .catch(() => {
      dispatch({ type: DELETE_ERROR, error: 'assignment_deletion_error' });
      showNotification(`Failed to revoke assignment: ${assignmentId}`)(dispatch);
    });
};

export const getUser = (userId) => (dispatch, getState) => {
  dispatch({ type: GET_DETAILS_START });
  return axios.get(`${REACT_APP_KPS_URL}/users/${userId}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: GET_DETAILS_DONE, details: { type: 'user', data: response.data } });
    })
    .catch(error => {
      dispatch({ type: GET_DETAILS_ERROR, error });
      showNotification('Failed to fetch user')(dispatch);
    });
};

export const getUsers = () => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/users`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_USERS_DONE, users: response.data });
      showNotification(`Retrieved ${_(response.data).size()} users`, { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch users')(dispatch);
    });
};

export const getUserAssignments = (userId) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/users/${userId}/assignments`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_USER_ASSIGNMENTS_DONE, userAssignments: response.data });
      showNotification(`Retrieved ${_(response.data).size()} assignments`, { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch user assignments')(dispatch);
    });
};

export const getUserAuthRecords = (userId) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/users/${userId}/authrecords`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_USER_AUTH_RECORDS_DONE, userAuthRecords: response.data });
      showNotification(`Retrieved ${_(response.data).size()} device authentication-records`, { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch device authentication-records')(dispatch);
    });
};

export const createUser = (user) => (dispatch, getState) => {
  dispatch({ type: POST_START });
  return axios.post(`${REACT_APP_KPS_URL}/users`, user, REQ_CONFIG)
    .then(response => {
      dispatch({ type: POST_DONE, result: { type: 'user', data: response.data } });
      showNotification(`Created user: ${user.email}`, { notificationType: 'success' })(dispatch);
    })
    .catch(() => {
      dispatch({ type: POST_ERROR, error: 'user_creation_error' });
      showNotification(`Failed to create user: ${user.email}`)(dispatch);
    });
};

export const updateUser = (user) => (dispatch, getState) => {
  dispatch({ type: UPDATE_DETAILS_START });
  return axios.put(`${REACT_APP_KPS_URL}/users/${user.id}`, user, REQ_CONFIG)
    .then(response => {
      dispatch({ type: UPDATE_DETAILS_DONE, details: { type: 'user', data: response.data } });
      showNotification('Updated user', { notificationType: 'success' })(dispatch);
    })
    .catch(() => {
      dispatch({ type: UPDATE_DETAILS_ERROR, error: 'user_update_error' });
      showNotification('Failed to update user')(dispatch);
    });
};

export const deleteUser = (userId) => (dispatch, getState) => {
  dispatch({ type: DELETE_START });
  return axios.delete(`${REACT_APP_KPS_URL}/users/${userId}`, REQ_CONFIG)
    .then(() => {
      dispatch({ type: DELETE_DONE, result: { type: 'user', data: userId } });
      showNotification('Deleted user', { notificationType: 'success' })(dispatch);
    })
    .catch(() => {
      dispatch({ type: DELETE_ERROR, error: 'user_deletion_error' });
      showNotification('Failed to delete user')(dispatch);
    });
};

export const addOrRemovePolicyRuleFromUser = (request) => (dispatch, getState) => {
  dispatch({ type: UPDATE_DETAILS_START });
  return axios.post(`${REACT_APP_KPS_URL}/users/${request.userId}/rules`, request, REQ_CONFIG)
    .then(response => {
      dispatch({ type: UPDATE_DETAILS_DONE, details: { type: 'user', data: response.data } });
      showNotification(
        `${request.ruleName} ${request.action === 'add' ? 'added to' : 'removed from'} user: ${request.userName}`,
        { notificationType: 'success' }
      )(dispatch);
    })
    .catch(() => {
      dispatch({ type: POST_ERROR, error: 'policy_member_modification_error' });
      showNotification(`Failed to ${request.action} ${request.ruleName} ${request.action === 'add' ? 'to' : 'from'} user: ${request.userName}`)(dispatch);
    });
};

export const getRoles = (userRole) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/users/rolenames`, REQ_CONFIG)
    .then(response => {
      const roles = response.data.names.map(name => ({ value: name, label: name }));
      dispatch({ type: LIST_ROLES_DONE, roles: (userRole === ROLE.ADMIN ? roles.concat(PORTAL_ROLES) : roles) });
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch user roles')(dispatch);
    });
};

export const getGroup = (groupId) => (dispatch, getState) => {
  dispatch({ type: GET_DETAILS_START });
  return axios.get(`${REACT_APP_KPS_URL}/groups/${groupId}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: GET_DETAILS_DONE, details: { type: 'group', data: response.data } });
    })
    .catch(error => {
      dispatch({ type: GET_DETAILS_ERROR, error });
      showNotification('Failed to fetch group')(dispatch);
    });
};

export const getGroups = () => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/groups`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_GROUPS_DONE, groups: response.data });
      showNotification(`Retrieved ${_(response.data).size()} groups`, { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch groups')(dispatch);
    });
};

export const createGroup = (group) => (dispatch, getState) => {
  dispatch({ type: POST_START });
  return axios.post(`${REACT_APP_KPS_URL}/groups`, group, REQ_CONFIG)
    .then(response => {
      dispatch({ type: POST_DONE, result: { type: 'group', data: response.data } });
      showNotification(`Created group: ${group.name}`, { notificationType: 'success' })(dispatch);
    })
    .catch(() => {
      dispatch({ type: POST_ERROR, error: 'group_creation_error' });
      showNotification(`Failed to create group: ${group.name}`)(dispatch);
    });
};

export const updateGroup = (group) => (dispatch, getState) => {
  dispatch({ type: UPDATE_DETAILS_START });
  return axios.put(`${REACT_APP_KPS_URL}/groups/${group.id}`, group, REQ_CONFIG)
    .then(response => {
      dispatch({ type: UPDATE_DETAILS_DONE, details: { type: 'group', data: response.data } });
      showNotification('Updated group', { notificationType: 'success' })(dispatch);
    })
    .catch(() => {
      dispatch({ type: UPDATE_DETAILS_ERROR, error: 'group_update_error' });
      showNotification('Failed to update group')(dispatch);
    });
};

export const deleteGroup = (groupId) => (dispatch, getState) => {
  dispatch({ type: DELETE_START });
  return axios.delete(`${REACT_APP_KPS_URL}/groups/${groupId}`, REQ_CONFIG)
    .then(() => {
      dispatch({ type: DELETE_DONE, result: { type: 'group', data: groupId } });
      showNotification('Deleted group', { notificationType: 'success' })(dispatch);
    })
    .catch(() => {
      dispatch({ type: DELETE_ERROR, error: 'group_deletion_error' });
      showNotification('Failed to delete group')(dispatch);
    });
};

export const modifyGroupMember = (request) => (dispatch, getState) => {
  dispatch({ type: UPDATE_DETAILS_START });
  return axios.post(`${REACT_APP_KPS_URL}/groups/${request.groupId}/members`, request, REQ_CONFIG)
    .then(response => {
      dispatch({ type: UPDATE_DETAILS_DONE, details: { type: 'group', data: response.data } });
      showNotification(
        `${request.action === 'add' ? 'Added to' : 'Removed from'} group ${request.groupName}: ${request.memberName}`,
        { notificationType: 'success' }
      )(dispatch);
    })
    .catch(() => {
      dispatch({ type: POST_ERROR, error: 'member_modification_error' });
      showNotification(`Failed to ${request.action} ${request.memberName} ${request.action === 'add' ? 'to' : 'from'} group: ${request.groupName}`)(dispatch);
    });
};

export const getPolicy = (policyId) => (dispatch, getState) => {
  dispatch({ type: GET_DETAILS_START });
  return axios.get(`${REACT_APP_KPS_URL}/policies/${policyId}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: GET_DETAILS_DONE, details: { type: 'policy', data: response.data } });
    })
    .catch(error => {
      dispatch({ type: GET_DETAILS_ERROR, error });
      showNotification('Failed to fetch policy')(dispatch);
    });
};

export const getPolicies = () => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/policies`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_POLICIES_DONE, policies: response.data });
      showNotification(`Retrieved ${_(response.data).size()} policies`, { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch policies')(dispatch);
    });
};

export const createPolicy = (policy) => (dispatch, getState) => {
  dispatch({ type: POST_START });
  return axios.post(`${REACT_APP_KPS_URL}/policies`, policy, REQ_CONFIG)
    .then(response => {
      dispatch({ type: POST_DONE, result: { type: 'policy', data: response.data } });
      showNotification(`Created policy: ${policy.name}`, { notificationType: 'success' })(dispatch);
    })
    .catch(() => {
      dispatch({ type: POST_ERROR, error: 'policy_creation_error' });
      showNotification(`Failed to create policy: ${policy.name}`)(dispatch);
    });
};

export const addOrRemovePolicyRuleFromPolicy = (request) => (dispatch, getState) => {
  dispatch({ type: UPDATE_DETAILS_START });
  return axios.post(`${REACT_APP_KPS_URL}/policies/${request.policyId}/rules`, request, REQ_CONFIG)
    .then(response => {
      dispatch({ type: UPDATE_DETAILS_DONE, details: { type: 'policy', data: response.data } });
      showNotification(
        `${request.ruleName} ${request.action === 'add' ? 'added to' : 'removed from'} policy: ${request.policyName}`,
        { notificationType: 'success' }
      )(dispatch);
    })
    .catch(() => {
      dispatch({ type: POST_ERROR, error: 'policy_member_modification_error' });
      showNotification(`Failed to ${request.action} ${request.ruleName} ${request.action === 'add' ? 'to' : 'from'} policy: ${request.policyName}`)(dispatch);
    });
};

export const getAccessRule = (accessRuleId) => (dispatch, getState) => {
  dispatch({ type: GET_DETAILS_START });
  return axios.get(`${REACT_APP_KPS_URL}/accessrules/${accessRuleId}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: GET_DETAILS_DONE, details: { type: 'accessrule', data: response.data } });
    })
    .catch(error => {
      dispatch({ type: GET_DETAILS_ERROR, error });
      showNotification('Failed to fetch access rule')(dispatch);
    });
};

export const getAccessRules = () => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/accessrules`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_ACCESS_RULES_DONE, accessRules: response.data });
      showNotification(`Retrieved ${_(response.data).size()} access rules`, { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch access rules')(dispatch);
    });
};

export const createAccessRule = (accessRule) => (dispatch, getState) => {
  dispatch({ type: POST_START });
  return axios.post(`${REACT_APP_KPS_URL}/accessrules`, accessRule, REQ_CONFIG)
    .then(response => {
      dispatch({ type: POST_DONE, result: { type: 'accessrule', data: response.data } });
      showNotification(`Created access rule: ${accessRule.name}`, { notificationType: 'success' })(dispatch);
    })
    .catch(() => {
      dispatch({ type: POST_ERROR, error: 'access_rule_creation_error' });
      showNotification(`Failed to create access rule: ${accessRule.name}`)(dispatch);
    });
};

export const getPolicyRule = (policyRuleId) => (dispatch, getState) => {
  dispatch({ type: GET_DETAILS_START });
  return axios.get(`${REACT_APP_KPS_URL}/policyrules/${policyRuleId}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: GET_DETAILS_DONE, details: { type: 'policyrule', data: response.data } });
    })
    .catch(error => {
      dispatch({ type: GET_DETAILS_ERROR, error });
      showNotification('Failed to fetch policy rule')(dispatch);
    });
};

export const getPolicyRules = () => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/policyrules`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_POLICY_RULES_DONE, policyRules: response.data });
      showNotification(`Retrieved ${_(response.data).size()} policy rules`, { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch policy rules')(dispatch);
    });
};

export const createPolicyRule = (policyRule) => (dispatch, getState) => {
  dispatch({ type: POST_START });
  return axios.post(`${REACT_APP_KPS_URL}/policyrules`, policyRule, REQ_CONFIG)
    .then(response => {
      dispatch({ type: POST_DONE, result: { type: 'policyrule', data: response.data } });
      showNotification(`Created policy rule: ${policyRule.name}`, { notificationType: 'success' })(dispatch);
    })
    .catch(() => {
      dispatch({ type: POST_ERROR, error: 'policy_rule_creation_error' });
      showNotification(`Failed to create policy rule: ${policyRule.name}`)(dispatch);
    });
};

export const getChecklist = (checklistId) => (dispatch, getState) => {
  dispatch({ type: GET_DETAILS_START });
  return axios.get(`${REACT_APP_KPS_URL}/checklists/${checklistId}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: GET_DETAILS_DONE, details: { type: 'checklist', data: response.data } });
    })
    .catch(error => {
      dispatch({ type: GET_DETAILS_ERROR, error });
      showNotification('Failed to fetch checklist')(dispatch);
    });
};

export const getImageReferences = (parentId) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/images/parents/${parentId}/references`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_IMAGE_REFERENCES_DONE, imageReferences: response.data });
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch image references')(dispatch);
    });
};

export const getImage = (imageId) => (dispatch, getState) => {
  dispatch({ type: GET_DETAILS_START });
  return axios.get(`${REACT_APP_KPS_URL}/images/${imageId}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: GET_IMAGE_DONE, image: response.data });
    })
    .catch(error => {
      dispatch({ type: GET_DETAILS_ERROR, error });
      showNotification('Failed to fetch image')(dispatch);
    });
};

export const getBooking = (bookingId) => (dispatch, getState) => {
  dispatch({ type: GET_DETAILS_START });
  return axios.get(`${REACT_APP_KPS_URL}/bookings/${bookingId}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: GET_DETAILS_DONE, details: { type: 'booking', data: response.data } });
    })
    .catch(error => {
      dispatch({ type: GET_DETAILS_ERROR, error });
      showNotification('Failed to fetch booking')(dispatch);
    });
};

export const getBookings = (urlEncodedQuery) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  const query = urlEncodedQuery ? `?${urlEncodedQuery}` : '';
  return axios.get(`${REACT_APP_KPS_URL}/bookings${query}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_BOOKINGS_DONE, bookings: response.data });
      showNotification(`Retrieved ${_(response.data).size()} bookings`, { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch bookings')(dispatch);
    });
};

export const getCurrentBookings = (urlEncodedQuery) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  const extra = urlEncodedQuery ? `&${urlEncodedQuery}` : '';
  return axios.get(`${REACT_APP_KPS_URL}/bookings?status=pending&status=future&status=activated${extra}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_CURRENT_BOOKINGS_DONE, currentBookings: response.data });
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch current bookings')(dispatch);
    });
};

export const getBookingChecklists = (bookingId) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/bookings/${bookingId}/checklists`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_BOOKING_CHECKLISTS_DONE, bookingChecklists: response.data });
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch submitted reports')(dispatch);
    });
};

export const getBookingEvents = (bookingId) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/bookings/${bookingId}/events`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_BOOKING_EVENTS_DONE, bookingEvents: response.data });
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch booking events')(dispatch);
    });
};

export const getBookingItems = (bookingId) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  return axios.get(`${REACT_APP_KPS_URL}/bookings/${bookingId}/items`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_BOOKING_ITEMS_DONE, bookingItems: response.data });
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch booking assignments')(dispatch);
    });
};

export const getAssetsAvailableForBooking = (start, end, excludeBookingName) => (dispatch, getState) => {
  dispatch({ type: POSTS_LIST_START });
  const extra = excludeBookingName ? `&excludeBookingName=${excludeBookingName}` : '';
  return axios.get(`${REACT_APP_KPS_URL}/bookings/availableassets?start=${start}&end=${end}${extra}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: LIST_AVAILABLE_ASSETS_FOR_BOOKING_DONE, availableAssetsForBooking: response.data });
    })
    .catch(error => {
      dispatch({ type: POSTS_LIST_ERROR, error });
      showNotification('Failed to fetch assets available for booking')(dispatch);
    });
};

export const createBooking = (booking) => (dispatch, getState) => {
  dispatch({ type: POST_START });
  return axios.post(`${REACT_APP_KPS_URL}/bookings`, booking, REQ_CONFIG)
    .then(response => {
      dispatch({ type: POST_DONE, result: { type: 'booking', data: response.data } });
      showNotification(`Created booking: ${response.data.name}`, { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: POST_ERROR, error: error.response.data });
      showNotification('Failed to create booking')(dispatch);
    });
};

export const updateBooking = (booking) => (dispatch, getState) => {
  dispatch({ type: UPDATE_DETAILS_START });
  return axios.put(`${REACT_APP_KPS_URL}/bookings/${booking.id}`, booking, REQ_CONFIG)
    .then(response => {
      dispatch({ type: UPDATE_DETAILS_DONE, details: { type: 'booking', data: response.data } });
      showNotification('Updated booking', { notificationType: 'success' })(dispatch);
    })
    .catch(error => {
      dispatch({ type: UPDATE_DETAILS_ERROR, error: error.response.data });
      showNotification('Failed to update booking')(dispatch);
    });
};

export const cancelBooking = (booking) => (dispatch, getState) => {
  dispatch({ type: UPDATE_DETAILS_START });
  return axios.put(`${REACT_APP_KPS_URL}/bookings/${booking.id}/cancel`, booking, REQ_CONFIG)
    .then(response => {
      dispatch({ type: UPDATE_DETAILS_DONE, details: { type: 'booking', data: response.data } });
      showNotification('Cancelled booking', { notificationType: 'success' })(dispatch);
    })
    .catch(() => {
      dispatch({ type: UPDATE_DETAILS_ERROR, error: 'booking_cancel_error' });
      showNotification('Failed to cancel booking')(dispatch);
    });
};

export const getData = (type) => (dispatch, getState) => {
  dispatch({ type: GET_DETAILS_START });
  return axios.get(`${REACT_APP_KPS_URL}/data?type=${type}`, REQ_CONFIG)
    .then(response => {
      dispatch({ type: GET_DETAILS_DONE, details: { type: 'data', data: response.data } });
    })
    .catch(error => {
      dispatch({ type: GET_DETAILS_ERROR, error });
      showNotification('Failed to fetch data')(dispatch);
    });
};

export const updateSysConfig = (config) => (dispatch, getState) => {
  dispatch({ type: UPDATE_DETAILS_START });
  return axios.put(`${REACT_APP_KPS_URL}/sysconfig`, config, REQ_CONFIG)
    .then(response => {
      dispatch({ type: UPDATE_DETAILS_DONE, details: { type: 'sysConfig', data: response.data } });
      showNotification('Updated system-configuration', { notificationType: 'success' })(dispatch);
    })
    .catch(() => {
      dispatch({ type: UPDATE_DETAILS_ERROR, error: 'sysConfig_update_error' });
      showNotification('Failed to update system-configuration')(dispatch);
    });
};
