import React from 'react';

import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import {
  func,
  string,
  array,
  object,
} from 'prop-types';

import { IrisSelect, IrisButton } from 'iris-core/libs/components';

import { generateSelectMap } from '../../helper';
import { GENERIC_NAME_MAX_LENGTH } from '../../globalConstants';
import i18n from '../../i18n';

/*
* Form to create/update policy
*/
class PolicyForm extends React.Component {
  static propTypes = {
    title: string.isRequired,
    keystonePolicies: array.isRequired,
    assetTypes: array.isRequired,
    toggle: func.isRequired,
    onSubmit: func.isRequired,
    icon: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.keystonePoliciesMap = {};
    this.keystonePolicies = generateSelectMap(this.props.keystonePolicies, this.keystonePoliciesMap);

    this.assetTypesMap = {};
    this.assetTypes = generateSelectMap(this.props.assetTypes, this.assetTypesMap);

    this.state = {
      isOpen: true,
      name: '',
      description: '',
      assetTypeId: this.assetTypes[0].value,
      keystonePolicyId: this.keystonePolicies[0].value,
    };
  }

  toggleWindow = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.toggle();
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit({
      name: this.state.name,
      description: this.state.description || undefined,
      assetTypeId: this.state.assetTypeId,
      assetTypeName: this.assetTypesMap[this.state.assetTypeId].name, // Only for notifications - not persisted in DB
      keystonePolicyId: this.state.keystonePolicyId,
      keystonePolicyName: this.keystonePoliciesMap[this.state.keystonePolicyId].name, // Only for notifications - not persisted in DB
    }).then(() => {
      this.toggleWindow();
    });
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen} fade={true}>
        <Form className="modal-content" onSubmit={this.onSubmit}>
          <ModalHeader toggle={this.toggleWindow}>
            {this.props.icon} {this.props.title}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>{i18n.t('generic.name')}:</Label>
              <Input required name="name" maxLength={GENERIC_NAME_MAX_LENGTH} placeholder={i18n.t('policies.createPolicyForm.namePlaceholder')}
                     onChange={e => this.setState({ name: e.target.value })} value={this.state.name}/>
            </FormGroup>
            <FormGroup>
              <Label>{i18n.t('generic.description')}:</Label>
              <Input name="description" placeholder={i18n.t('policies.createPolicyForm.descriptionPlaceholder')}
                     onChange={e => this.setState({ description: e.target.value })} value={this.state.description}/>
            </FormGroup>
            <hr/>
            <FormGroup>
              <Label>{i18n.t('assets.assetType')}:&nbsp;</Label>
              <IrisSelect options={this.assetTypes} onChange={value => this.setState({ assetTypeId: value })} outline/>
            </FormGroup>
            <hr/>
            <FormGroup>
              <Label>{i18n.t('policies.createPolicyForm.keystonePolicy')}:&nbsp;</Label>
              <IrisSelect options={this.keystonePolicies} onChange={value => this.setState({ keystonePolicyId: value })} outline/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <IrisButton color="info" size="sm" type="submit">
              {i18n.t('buttons.create')}
            </IrisButton>
            <IrisButton color="secondary" size="sm" onClick={this.toggleWindow()} outline>
              {i18n.t('buttons.close')}
            </IrisButton>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default PolicyForm;
