import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import {
  IrisSpinner, IrisBox, IrisButton, IrisDatatable, IrisTableHeaderColumn,
} from 'iris-core/libs/components';

import { UpdateAssignmentForm, ConfirmDialog } from '../../components';
import {
  getAssignment, getUsers, getAssets, getAssetTypes, getAssignmentChecklists, getAssignmentEvents, updateAssignment,
  revokeAssignment,
} from '../../actions';
import {
  toDate, getArrayElementProperty, resolveAssetName, resolveAssetTypeName, resolveAssignmentStatus, resolveEventName,
  resolveChecklistType,
} from '../../helper';
import { SELECT_ROW_PROPS } from '../../globalConstants';
import i18n from '../../i18n';

class AssignmentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalUpdateAssignment: false,
      modalRevokeAssignment: false,
    };
  }

  componentDidMount() {
    Promise.all([this.props.getUsers(), this.props.getAssets(), this.props.getAssetTypes()]).then(() => {
      this.props.getAssignmentChecklists(this.props.match.params.rowId);
      this.props.getAssignmentEvents(this.props.match.params.rowId);
      this.props.getAssignment(this.props.match.params.rowId);
    });
  }

  isUpdateAssignmentDisabled = (assignment) => {
    return assignment.status === 'cancelled';
  }

  revokeAssignment = () => {
    this.props.revokeAssignment(this.props.assignment.assignmentId).then(() => {
      if (this.props.error !== 'assignment_deletion_error') {
        this.props.history.goBack();
      }
    });
  }

  goTo = (itemSubPath, itemId) => {
    this.props.history.push(`${window.BASE_PATH}/${itemSubPath}/${itemId}`);
  }

  toggleModalUpdateAssignment = () => {
    this.setState({ modalUpdateAssignment: !this.state.modalUpdateAssignment });
  }

  toggleModalRevokeAssignment = () => {
    this.setState({ modalRevokeAssignment: !this.state.modalRevokeAssignment });
  }

  render() {
    if (this.props.detailsType !== 'assignment' || this.props.assignment.assignmentId !== this.props.match.params.rowId) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    const problemReportOptions = {
      onRowClick: (row) => this.props.history.push(`${this.props.location.pathname}/problems/${row.id}`),
    };

    return (
      <div className="AssignmentDetails">
        <Row>
          <Col>
            <IrisBox>
              <Row>
                <Col>
                  <div className="table-title d-flex flex-row align-items-center">
                    <h4 className="mr-auto"><i className="fa fa-key"/> <small className="text-muted">{this.props.assignment.name}</small> {resolveAssignmentStatus(this.props.assignment)}</h4>
                    <IrisButton color="primary" size="sm" onClick={this.toggleModalUpdateAssignment} disabled={this.isUpdateAssignmentDisabled(this.props.assignment)} outline>{i18n.t('buttons.update')}...</IrisButton>
                    <IrisButton color="secondary" size="sm" onClick={this.toggleModalRevokeAssignment} disabled={this.isUpdateAssignmentDisabled(this.props.assignment)} outline>{i18n.t('buttons.revoke')}...</IrisButton>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm="4" style={{ whiteSpace: 'nowrap' }}>
                  <Row>
                    <Col sm="3">
                      <div>{i18n.t('users.user')}</div>
                      <div>{i18n.t('users.role')}</div>
                    </Col>
                    <Col sm="9">
                      <a href="#/" onClick={() => this.goTo('users', this.props.assignment.userId)}>{getArrayElementProperty(this.props.users, this.props.assignment.userId, 'name')}</a>
                      <div className="text-muted">{getArrayElementProperty(this.props.users, this.props.assignment.userId, 'role')}</div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="4" style={{ whiteSpace: 'nowrap' }}>
                  <Row>
                    <Col sm="3">
                      <div>{i18n.t('assets.asset')}</div>
                      <div>{i18n.t('assets.assetType')}</div>
                    </Col>
                    <Col sm="9">
                      <a href="#/" onClick={() => this.goTo('assets', this.props.assignment.assetId)}>{resolveAssetName(this.props.assets, this.props.assignment.assetId)}</a>
                      <div className="text-muted">{resolveAssetTypeName(this.props.assetTypes, this.props.assets, this.props.assignment.assetId)}</div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="4" style={{ whiteSpace: 'nowrap' }}>
                  <Row>
                    <Col sm="4">
                      <div>{i18n.t('generic.startTime')}</div>
                      <div>{i18n.t('generic.endTime')}</div>
                    </Col>
                    <Col sm="7">
                      <div className="text-muted">{toDate(this.props.assignment.start)}</div>
                      <div className="text-muted">{toDate(this.props.assignment.end)}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </IrisBox>
          </Col>
        </Row>

        <Row>
          <Col sm="6">
            <IrisBox>
              <div className="title mb-4">
                <h4><small className="text-muted">{i18n.t('checklists.title')}</small></h4>
              </div>
              <IrisDatatable height='350px' data={this.props.assignmentChecklists} options={problemReportOptions} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn
                  dataField="type"
                  dataFormat={(cell) => resolveChecklistType(cell)} searchable>{i18n.t('generic.type')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="timestamp" width="165px"
                  dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.timestamp')}</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>
          </Col>
          <Col sm="6">
            <IrisBox>
              <div className="title mb-4">
                <h4><small className="text-muted">{i18n.t('events.title')}</small></h4>
              </div>
              <IrisDatatable height='350px' data={this.props.assignmentEvents} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn
                  dataField="telemetry"
                  dataFormat={(cell) => resolveEventName(cell)} searchable>{i18n.t('generic.type')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="telemetry" width="165px"
                  dataFormat={(cell) => toDate(cell.timestamp)}>{i18n.t('generic.timestamp')}</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>
          </Col>
        </Row>

        {this.state.modalUpdateAssignment
        && <UpdateAssignmentForm title={i18n.t('assignments.updateAssignmentForm.title')} icon={<i className="fa fa-key fa-fw"/>}
                                 assignment={this.props.assignment} onSubmit={this.props.updateAssignment} toggle={this.toggleModalUpdateAssignment}/>}

        {this.state.modalRevokeAssignment
        && <ConfirmDialog title={i18n.t('assignments.revokeAssignmentDialog.title')} icon={<i className="fa fa-trash fa-fw"/>}
                          confirmText={i18n.t('assignments.revokeAssignmentDialog.confirmText')} itemName=""
                          confirmButtonName={i18n.t('buttons.confirm')} onConfirm={this.revokeAssignment} toggle={this.toggleModalRevokeAssignment}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    detailsType: fleetmanager.posts.details.type,
    assignment: fleetmanager.posts.details.data,
    users: fleetmanager.posts.users,
    assets: fleetmanager.posts.assets,
    assetTypes: fleetmanager.posts.assetTypes,
    assignmentChecklists: fleetmanager.posts.assignmentChecklists,
    assignmentEvents: fleetmanager.posts.assignmentEvents,
  };
};

export default connect(mapStateToProps, {
  getAssignment,
  getUsers,
  getAssets,
  getAssetTypes,
  getAssignmentChecklists,
  getAssignmentEvents,
  updateAssignment,
  revokeAssignment,
})(AssignmentDetails);
