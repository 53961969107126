import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import {
  func,
  array,
  string,
  object,
} from 'prop-types';

import { IrisButton } from 'iris-core/libs/components';

import i18n from '../../i18n';

/*
* Form to remove member from group
*/
class RemoveMemberForm extends React.Component {
  static propTypes = {
    title: string.isRequired,
    group: object.isRequired,
    members: array.isRequired,
    toggle: func.isRequired,
    onSubmit: func.isRequired,
    icon: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.type = (this.props.group.type === 'users' ? 'User' : 'Asset');

    this.membersMap = {};
    this.members = this.props.members.map(value => {
      this.membersMap[value.id] = value;

      return {
        value: value.id,
        label: this.props.group.type === 'users' ? value.name : (value.licensePlate || value.externalId || value.id),
      };
    });

    this.state = {
      isOpen: true,
      member: this.members?.[0] ?? null,
    };
  }

  toggleWindow = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.toggle();
  }

  resolveMemberName = () => {
    switch (this.props.group.type) {
      case 'users':
        return this.membersMap[this.state.member.value].name;
      case 'assets':
        return this.membersMap[this.state.member.value].licensePlate || this.membersMap[this.state.member.value].externalId || this.membersMap[this.state.member.value].id;
      default:
        return '';
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit({
      action: 'remove',
      groupId: this.props.group.id,
      groupName: this.props.group.name,
      memberId: this.state.member.value,
      memberName: this.resolveMemberName(),
    }).then(() => {
      this.toggleWindow();
    });
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen} fade={true}>
        <Form className="modal-content" onSubmit={this.onSubmit}>
          <ModalHeader toggle={this.toggleWindow}>
            {this.props.icon} {this.props.title}
          </ModalHeader>
          <ModalBody>
            {this.props.group.type === 'users' && <p>{i18n.t('groups.removeMemberForm.descriptionUser')}</p>}
            {this.props.group.type === 'assets' && <p>{i18n.t('groups.removeMemberForm.descriptionAsset')}</p>}
            <hr/>
            <FormGroup>
              <Label>{i18n.t('generic.select')}:&nbsp;</Label>
              <Select
                value={this.state.member}
                onChange={selected => this.setState({ member: selected })}
                options={this.members}
                components={makeAnimated()}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <IrisButton color="danger" size="sm" type="submit">
              {i18n.t('buttons.remove')}
            </IrisButton>
            <IrisButton color="secondary" size="sm" onClick={this.toggleWindow} outline>
              {i18n.t('buttons.close')}
            </IrisButton>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default RemoveMemberForm;
