import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import {
  IrisDatatable, IrisTableHeaderColumn, IrisBox, IrisButton,
} from 'iris-core/libs/components';

import { PolicyRuleForm } from '../../components';
import { getPolicyRules, createPolicyRule } from '../../actions';
import { toDate } from '../../helper';
import { SELECT_ROW_PROPS } from '../../globalConstants';
import i18n from '../../i18n';

import validationTypeOptions from '../../data/ValidationTypeOptions.json';

class PolicyRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalPolicyRuleForm: false,
    };
  }

  componentDidMount() {
    this.props.getPolicyRules();
  }

  toggleModalPolicyRuleForm = () => {
    this.setState({ modalPolicyRuleForm: !this.state.modalPolicyRuleForm });
  }

  resolveRule = (rule) => {
    if (rule.type === 'required') {
      return 'Required';
    }

    const found = validationTypeOptions.find(r => rule.type === r.value);
    return `${found.label} ${rule.value} ${i18n.t('generic.hours')}`;
  }

  render() {
    const toolbarButtons = [
      <IrisButton key={_.uniqueId()} color="info" size="sm" onClick={this.toggleModalPolicyRuleForm} outline>{i18n.t('buttons.create')}...</IrisButton>,
    ];

    const options = {
      // onRowClick: (row) => this.props.history.push(`${this.props.location.pathname}/${row.id}`),
    };

    return (
      <div className="PolicyRuleList">
        <Row>
          <Col>
            <IrisBox>
              <div className="title mb-4">
                <h4 className="text-muted"><i className="fa fa-file-text-o"/> {i18n.t('policyRules.title')}</h4>
              </div>
              <IrisDatatable data={this.props.policyRules} options={options} toolbar={toolbarButtons} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn dataField="name" searchable>{i18n.t('generic.name')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="description" searchable>{i18n.t('generic.description')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="rule" dataFormat={(cell, row) => this.resolveRule(row)}>{i18n.t('generic.rule')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="createdAt" dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.created')}</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>
          </Col>
        </Row>

        {this.state.modalPolicyRuleForm
         && <PolicyRuleForm title={i18n.t('policyRules.createPolicyRuleForm.title')} icon={<i className="fa fa-file-text-o fa-fw"/>} validationTypes={validationTypeOptions}
                            onSubmit={this.props.createPolicyRule} toggle={this.toggleModalPolicyRuleForm}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    policyRules: fleetmanager.posts.policyRules,
  };
};

export default connect(mapStateToProps, { getPolicyRules, createPolicyRule })(PolicyRules);
