import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import {
  IrisSpinner, IrisBox, IrisButton, IrisPinMap, IrisDatatableSimple, IrisTableSimpleHeaderColumn, IrisProgressBar,
} from 'iris-core/libs/components';

import { ImageModal } from '../../components';
import {
  getChecklist, getUsers, getAssets, getAssetTypes, getImageReferences, getImage,
} from '../../actions';
import {
  toDate, getArrayElementProperty, resolveAssetName, resolveChecklistType, resolveI18nArtifact,
} from '../../helper';
import i18n from '../../i18n';

class ChecklistDetails extends React.Component {
  constructor(props) {
    super(props);
    this.sections = {};
    this.state = {
      modalImage: false,
      image: {},
    };
  }

  componentDidMount() {
    Promise.all([
      this.props.getImageReferences(this.props.match.params.checklistId),
      this.props.getUsers(),
      this.props.getAssets(),
      this.props.getAssetTypes(),
    ]).then(() => this.props.getChecklist(this.props.match.params.checklistId));
  }

  goTo = (itemSubPath, itemId) => {
    this.props.history.push(`${window.BASE_PATH}/${itemSubPath}/${itemId}`);
  }

  toggleModalImage = () => {
    this.setState({ modalImage: !this.state.modalImage });
  }

  showImage = (imageId, title) => {
    this.props.getImage(imageId).then(() => {
      const { image } = this.props;
      const base64Str = btoa(new Uint8Array(image.data.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
      this.setState({
        modalImage: !this.state.modalImage,
        image: { title, url: `data:${image.contentType};base64,${base64Str}` },
      });
    });
  }

  resolveCheckValue = (check) => {
    switch (check.type) {
      case 'image': {
        const imageRef = this.props.imageReferences.find(entry => entry.referenceId === check.value);
        return (imageRef
          ? <IrisButton size="xs" onClick={() => this.showImage(imageRef.id, check.name)} outline>
            <i className="fa fa-eye" aria-hidden="true"/> {i18n.t('buttons.view')}
          </IrisButton>
          : i18n.t('notices.notUploaded'));
      }
      case 'bool':
        return <i style={{ color: check.value ? 'green' : 'red' }} className={check.value ? 'fa fa-check fa-lg' : 'fa fa-times fa-lg'}/>;
      case 'numberPercent':
        return <IrisProgressBar color="info" value={check.value}>{check.value}%</IrisProgressBar>;
      default:
        return check.value;
    }
  }

  resolveMap = () => {
    const center = {
      lat: this.props.checklist.metadata.latitude,
      lng: this.props.checklist.metadata.longitude,
    };
    const markers = [{
      position: center,
      label: resolveAssetName(this.props.assets, this.props.checklist.metadata.assetId),
    }];

    return <IrisPinMap apiKey={window.PINMAP_API_KEY} defaultZoom={15} defaultCenter={center} markers={markers}/>;
  }

  render() {
    if (this.props.detailsType !== 'checklist' || this.props.checklist.id !== this.props.match.params.checklistId) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    if (this.props.checklist.checks) {
      let array = null;
      for (const check of this.props.checklist.checks) {
        if (check.type === 'title') {
          this.sections[check.name] = array = []; // eslint-disable-line no-multi-assign
        } else {
          if (!array) {
            this.sections.MISSING_TITLE = array = []; // eslint-disable-line no-multi-assign
          }
          array.push(check);
        }
      }
    } else {
      const totalSections = this.props.checklist.sections.length;
      let idx = 0;
      for (const section of this.props.checklist.sections) {
        this.sections[`${++idx}/${totalSections} ${resolveI18nArtifact(section.i18n, window.i18nChecks)}`] = section.checks;
        for (let imageIdx = 0, commentIdx = 0, i = 0; i < section.checks.length;) {
          const check = section.checks[i++];
          check.name = resolveI18nArtifact(check.i18n, window.i18nChecks);
          if (this.props.checklist.type === 'PROBLEM_REPORT') {
            check.name = check.name.replace('##', check.i18n === 'image_#' ? ++imageIdx : ++commentIdx);
          }
        }
      }
    }

    return (
      <div className="ChecklistDetails">
        <Row>
          <Col>
            <IrisBox>
              <Row>
                <Col>
                  <div className="table-title d-flex flex-row align-items-center">
                    <h4 className="mr-auto">
                      <small className="text-muted">{resolveChecklistType(this.props.checklist.type)}: {getArrayElementProperty(this.props.assetTypes, this.props.checklist.assetTypeId, 'name')}</small>
                    </h4>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm="4">
                  <Row>
                    <Col sm="3">
                      <div>{i18n.t('assets.asset')}</div>
                      <div>{i18n.t('assets.assetType')}</div>
                    </Col>
                    <Col sm="9">
                      <a href="#/" onClick={() => this.goTo('assets', this.props.checklist.metadata.assetId)}>{resolveAssetName(this.props.assets, this.props.checklist.metadata.assetId)}</a>
                      <div className="text-muted">{getArrayElementProperty(this.props.assetTypes, this.props.checklist.assetTypeId, 'name')}</div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="4">
                  <Row>
                    <Col sm="3">
                      <div>{i18n.t('users.user')}</div>
                      <div>{i18n.t('users.role')}</div>
                    </Col>
                    <Col sm="9">
                      <a href="#/" onClick={() => this.goTo('users', this.props.checklist.metadata.userId)}>{getArrayElementProperty(this.props.users, this.props.checklist.metadata.userId, 'name')}</a>
                      <div className="text-muted">{getArrayElementProperty(this.props.users, this.props.checklist.metadata.userId, 'role')}</div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="4">
                  <Row>
                    <Col sm="3">
                      <div>{i18n.t('generic.timestamp')}</div>
                    </Col>
                    <Col sm="9">
                      <div className="text-muted">{toDate(this.props.checklist.timestamp)}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </IrisBox>
          </Col>
        </Row>

        <Row>
          {this.props.checklist.metadata.latitude && <Col sm="4">
            <IrisBox>{this.resolveMap()}</IrisBox>
          </Col>}
          {Object.keys(this.sections).map(key => {
            return (<Col sm="4" key={key}>
              <IrisBox>
                <div className="title mb-4">
                  <h4><small className="text-muted">{key}</small></h4>
                </div>
                <IrisDatatableSimple height='348px' headerStyle={{ display: 'none' }} style={{ visibility: 'hidden' }} data={this.sections[key]} ignoreSinglePage>
                  <IrisTableSimpleHeaderColumn dataField="name" isKey/>
                  <IrisTableSimpleHeaderColumn dataField="value" dataFormat={(cell, row) => this.resolveCheckValue(row)}/>
                </IrisDatatableSimple>
              </IrisBox>
            </Col>);
          })}
        </Row>

        {this.state.modalImage
         && <ImageModal title={this.state.image.title} icon={<i className="fa fa-camera fa-fw"/>} url={this.state.image.url} toggle={this.toggleModalImage}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    detailsType: fleetmanager.posts.details.type,
    checklist: fleetmanager.posts.details.data,
    users: fleetmanager.posts.users,
    assets: fleetmanager.posts.assets,
    assetTypes: fleetmanager.posts.assetTypes,
    imageReferences: fleetmanager.posts.imageReferences,
    image: fleetmanager.posts.image,
  };
};

export default connect(mapStateToProps, {
  getChecklist, getUsers, getAssets, getAssetTypes, getImageReferences, getImage,
})(ChecklistDetails);
