import React from 'react';
import _ from 'lodash';

import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import {
  func,
  string,
  object,
  array,
} from 'prop-types';

import { IrisSelect, IrisButton } from 'iris-core/libs/components';

import {
  VIN_MAX_LENGTH, ASSET_EXTERNAL_ID_MAX_LENGTH, LICENSE_PLATE_MAX_LENGTH,
} from '../../globalConstants';
import i18n from '../../i18n';

/*
* Form to create asset
*/
class AssetForm extends React.Component {
  static propTypes = {
    title: string.isRequired,
    account: object.isRequired,
    assetTypes: array.isRequired,
    toggle: func.isRequired,
    onSubmit: func.isRequired,
    icon: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.assetTypes = this.props.assetTypes.map(value => ({ value: value.id, label: value.name }));

    this.state = {
      isOpen: true,
      id: '',
      assetTypeId: this.assetTypes[0].value,
      externalId: '',
      licensePlate: '',
      year: '',
    };
  }

  toggleWindow = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.toggle();
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit({
      id: this.state.id,
      assetTypeId: this.state.assetTypeId,
      externalId: this.state.externalId || undefined,
      licensePlate: this.state.licensePlate || undefined,
      year: this.state.year ? parseInt(this.state.year, 10) : undefined,
    }).then(() => {
      this.toggleWindow();
    });
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen} fade={true}>
        <Form className="modal-content" onSubmit={this.onSubmit}>
          <ModalHeader toggle={this.toggleWindow}>
            {this.props.icon} {this.props.title}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>{i18n.t('generic.type')}:&nbsp;</Label>
              <IrisSelect options={this.assetTypes} onChange={value => this.setState({ assetTypeId: value })} outline/>
            </FormGroup>
            <hr/>
            <FormGroup>
              <Label>{i18n.t('assets.details.vin')}:</Label>
              <Input required name="vin" maxLength={VIN_MAX_LENGTH} placeholder={i18n.t('assets.createAssetForm.vinPlaceholder')}
                     onChange={e => this.setState({ id: e.target.value })} value={this.state.id}/>
            </FormGroup>
            {_.get(this.props.account.iris, 'assets.fields.externalId', false) && <FormGroup>
              <Label>{i18n.t('assets.details.externalId')}:</Label>
              <Input required name="externalId" maxLength={ASSET_EXTERNAL_ID_MAX_LENGTH} placeholder={i18n.t('assets.createAssetForm.externalIdPlaceholder')}
                     onChange={e => this.setState({ externalId: e.target.value })} value={this.state.externalId}/>
            </FormGroup>}
            {_.get(this.props.account.iris, 'assets.fields.licensePlate', false) && <FormGroup>
              <Label>{i18n.t('assets.details.licensePlate')}:</Label>
              <Input name="licensePlate" maxLength={LICENSE_PLATE_MAX_LENGTH} placeholder={i18n.t('assets.createAssetForm.licensePlatePlaceholder')}
                     onChange={e => this.setState({ licensePlate: e.target.value })} value={this.state.licensePlate}/>
            </FormGroup>}
            {_.get(this.props.account.iris, 'assets.fields.year', false) && <FormGroup>
              <Label>{i18n.t('assets.details.year')}:</Label>
              <Input name="year" placeholder={i18n.t('assets.createAssetForm.yearPlaceholder')}
                     type="number" min="1980" max="2200"
                     onChange={e => this.setState({ year: e.target.value })} value={this.state.year}/>
            </FormGroup>}
          </ModalBody>
          <ModalFooter>
            <IrisButton color="info" size="sm" type="submit">
              {i18n.t('buttons.create')}
            </IrisButton>
            <IrisButton color="secondary" size="sm" onClick={this.toggleWindow} outline>
              {i18n.t('buttons.close')}
            </IrisButton>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default AssetForm;
