import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import {
  IrisSpinner, IrisDatatable, IrisTableHeaderColumn, IrisPieChart, IrisBarChart, IrisPinMap, IrisBox,
} from 'iris-core/libs/components';

import { getData } from '../../actions';

class Dashboard extends React.Component {
  componentDidMount() {
    this.props.getData('dashboard');
  }

  getInfoBox(assetName) {
    return (
      <div style={{ backgroundColor: 'yellow', padding: '12px' }}>
        <div style={{ fontSize: '16px', fontColor: '#08233B' }}>{assetName}</div>
      </div>
    );
  }

  render() {
    if (this.props.detailsType !== 'data') {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    return (
      <div className="Dashboard">
        <Row>
          <Col>
            <IrisBox className="title mb-4 d-flex flex-row">
              <h3 className="text-muted pt-2"><i className="fa fa-dashboard"/> Dashboard</h3>
            </IrisBox>
          </Col>
        </Row>
        <Row>
          <Col>
            <IrisBox>
              <div className="title">
                <h4><small className="text-muted">Alerts</small></h4>
              </div>
              <IrisDatatable
                data={this.props.data.alerts}
                striped
                hover
                pagination
                search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn dataField="severity" searchable>Severity</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="type" searchable>Type</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="asset" searchable>Asset</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="details" searchable>Details</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="date" searchable>Date/Time</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>
          </Col>
        </Row>
        <Row>
          <Col>
            <IrisBox>
              <div className="title">
                <h4><small className="text-muted">Policy Violations</small></h4>
              </div>
              <IrisDatatable data={this.props.data.policyViolations} striped hover pagination search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn dataField="user" searchable>User</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="violation" searchable>Violation</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="asset" searchable>Asset</IrisTableHeaderColumn>
                <IrisTableHeaderColumn dataField="date" searchable>Date/Time</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>
          </Col>
        </Row>
        <Row>
          <Col xl={6} lg={6}>
            <IrisBox>
              <div className="title">
                <h4><small className="text-muted pl-2">Hours to Maintenance: Fleet View</small></h4>
              </div>
              <IrisBarChart data={this.props.data.hoursToMaintenance} showGrid height={380}>
              </IrisBarChart>
            </IrisBox>
          </Col>
          <Col xl={6} lg={6}>
            <IrisBox>
              <div className="title">
                <h4><small className="text-muted pl-2">Fleet Utilization: Last 30 Days</small></h4>
              </div>
              <IrisPieChart data={this.props.data.alertLast30Days} colors={['red', 'yellow', 'green']} nameKey="name"
              dataKey="value" legendStyle={{ right: '10px' }} showPercentage={true} height={380}/>
            </IrisBox>
          </Col>
        </Row>
        <Row>
          <IrisPinMap apiKey={window.PINMAP_API_KEY} defaultZoom={this.props.data.pinMap.zoom} markers={this.props.data.pinMap.markers} defaultCenter={this.props.data.pinMap.defaultCenter}/>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    detailsType: fleetmanager.posts.details.type,
    data: fleetmanager.posts.details.data,
  };
};

export default connect(mapStateToProps, { getData })(Dashboard);
