export const RESET = 'RESET';

export const AUTHENTICATE_START = 'AUTHENTICATE_START';
export const AUTHENTICATE_DONE = 'AUTHENTICATE_DONE';
export const AUTHENTICATE_ERROR = 'AUTHENTICATE_ERROR';

export const POSTS_LIST_START = 'POSTS_LIST_START';
export const LIST_ACCESS_RULES_DONE = 'LIST_ACCESS_RULES_DONE';
export const LIST_ADMIN_ACCOUNTS_DONE = 'LIST_ADMIN_ACCOUNTS_DONE';
export const LIST_ASSET_ASSIGNMENTS_DONE = 'LIST_ASSET_ASSIGNMENTS_DONE';
export const LIST_ASSET_BOOKINGS_DONE = 'LIST_ASSET_BOOKINGS_DONE';
export const LIST_ASSET_CHECKLISTS_DONE = 'LIST_ASSET_CHECKLISTS_DONE';
export const LIST_ASSET_TYPES_DONE = 'LIST_ASSET_TYPES_DONE';
export const LIST_ASSET_USAGES_DONE = 'LIST_ASSET_USAGES_DONE';
export const LIST_ASSETS_DONE = 'LIST_ASSETS_DONE';
export const LIST_ASSIGNMENT_CHECKLISTS_DONE = 'LIST_ASSIGNMENT_CHECKLISTS_DONE';
export const LIST_ASSIGNMENT_EVENTS_DONE = 'LIST_ASSIGNMENT_EVENTS_DONE';
export const LIST_ASSIGNMENTS_DONE = 'LIST_ASSIGNMENTS_DONE';
export const LIST_AVAILABLE_ASSETS_FOR_BOOKING_DONE = 'LIST_AVAILABLE_ASSETS_FOR_BOOKING_DONE';
export const LIST_BOOKING_CHECKLISTS_DONE = 'LIST_BOOKING_CHECKLISTS_DONE';
export const LIST_BOOKING_EVENTS_DONE = 'LIST_BOOKING_EVENTS_DONE';
export const LIST_BOOKING_ITEMS_DONE = 'LIST_BOOKING_ITEMS_DONE';
export const LIST_BOOKINGS_DONE = 'LIST_BOOKINGS_DONE';
export const LIST_IMAGE_REFERENCES_DONE = 'LIST_IMAGE_REFERENCES_DONE';
export const LIST_CURRENT_BOOKINGS_DONE = 'LIST_CURRENT_BOOKINGS_DONE';
export const LIST_GROUPS_DONE = 'LIST_GROUPS_DONE';
export const LIST_KEYSTONE_POLICIES_DONE = 'LIST_KEYSTONE_POLICIES_DONE';
export const LIST_POLICIES_DONE = 'LIST_POLICIES_DONE';
export const LIST_POLICY_RULES_DONE = 'LIST_POLICY_RULES_DONE';
export const LIST_ROLES_DONE = 'LIST_ROLES_DONE';
export const LIST_USER_ASSIGNMENTS_DONE = 'LIST_USER_ASSIGNMENTS_DONE';
export const LIST_USER_AUTH_RECORDS_DONE = 'LIST_USER_AUTH_RECORDS_DONE';
export const LIST_USERS_DONE = 'LIST_USERS_DONE';
export const POSTS_LIST_ERROR = 'POSTS_LIST_ERROR';

export const GET_IMAGE_DONE = 'GET_IMAGE_DONE';

export const GET_DETAILS_START = 'GET_DETAILS_START';
export const GET_DETAILS_DONE = 'GET_DETAILS_DONE';
export const GET_DETAILS_ERROR = 'GET_DETAILS_ERROR';

export const POST_START = 'POST_START';
export const POST_DONE = 'POST_DONE';
export const POST_ERROR = 'POST_ERROR';

export const UPDATE_DETAILS_START = 'UPDATE_DETAILS_START';
export const UPDATE_DETAILS_DONE = 'UPDATE_DETAILS_DONE';
export const UPDATE_DETAILS_ERROR = 'UPDATE_DETAILS_ERROR';

export const DELETE_START = 'DELETE_START';
export const DELETE_DONE = 'DELETE_DONE';
export const DELETE_ERROR = 'DELETE_ERROR';
