import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import {
  IrisSpinner, IrisBox, IrisButton, IrisDatatable, IrisTableHeaderColumn,
} from 'iris-core/libs/components';

import { UpdateBookingForm, ConfirmDialog } from '../../components';
import {
  getBooking, getUsers, getAssets, getAssetTypes, getGroups, getBookingItems, getBookingChecklists, getBookingEvents,
  updateBooking, cancelBooking,
} from '../../actions';
import {
  toDate, toPartitionedString, getArrayElementProperty, resolveAssetName, resolveAssignmentStatus, resolveBookingStatus,
  resolveEventName, resolveChecklistType,
} from '../../helper';
import { SELECT_ROW_PROPS } from '../../globalConstants';
import i18n from '../../i18n';

class BookingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalUpdateBooking: false,
      modalCancelBooking: false,
    };
  }

  componentDidMount() {
    Promise.all([this.props.getUsers(), this.props.getAssets(), this.props.getAssetTypes(), this.props.getGroups()]).then(() => {
      this.props.getBookingItems(this.props.match.params.rowId);
      this.props.getBookingChecklists(this.props.match.params.rowId);
      this.props.getBookingEvents(this.props.match.params.rowId);
      this.props.getBooking(this.props.match.params.rowId);
    });
  }

  isUpdateBookingDisabled = (booking) => {
    return (new Date(booking.end).getTime() <= Date.now() || (booking.status !== 'pending' && booking.status !== 'activated'));
  }

  isCancelBookingDisabled = (booking) => {
    return ((new Date(booking.end).getTime() <= Date.now() || booking.status !== 'pending') && booking.status !== 'activated');
  }

  cancelBooking = () => {
    this.props.cancelBooking(this.props.booking).then(() => {
      this.props.getBookingItems(this.props.match.params.rowId);
    });
  }

  goTo = (itemSubPath, itemId) => {
    this.props.history.push(`${window.BASE_PATH}/${itemSubPath}/${itemId}`);
  }

  toggleModalUpdateBooking = () => {
    this.setState({ modalUpdateBooking: !this.state.modalUpdateBooking });
  }

  toggleModalCancelBooking = () => {
    this.setState({ modalCancelBooking: !this.state.modalCancelBooking });
  }

  onUpdate = () => {
    this.toggleModalUpdateBooking();
    this.props.getBookingItems(this.props.match.params.rowId);
  }

  render() {
    if (this.props.detailsType !== 'booking' || this.props.booking.id !== this.props.match.params.rowId) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    const checklistOptions = {
      onRowClick: (row) => this.props.history.push(`${this.props.location.pathname}/checklists/${row.id}`),
    };

    return (
      <div className="BookingDetails">
        <Row>
          <Col>
            <IrisBox>
              <Row>
                <Col>
                  <div className="table-title d-flex flex-row align-items-center">
                    <h4 className="mr-auto"><i className="fa fa-key"/> <small className="text-muted">{i18n.t('bookings.name')}: {this.props.booking.name}</small> {resolveBookingStatus(this.props.booking)}</h4>
                    <IrisButton color="primary" size="sm" onClick={this.toggleModalUpdateBooking} disabled={this.isUpdateBookingDisabled(this.props.booking)} outline>{i18n.t('buttons.update')}...</IrisButton>
                    <IrisButton color="secondary" size="sm" onClick={this.toggleModalCancelBooking} disabled={this.isCancelBookingDisabled(this.props.booking)} outline>{i18n.t('buttons.cancel')}...</IrisButton>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm="5" style={{ whiteSpace: 'nowrap' }}>
                  <Row>
                    <Col sm="3">
                      <div>{i18n.t('groups.group')}</div>
                      <div>{i18n.t('assets.assetType')}</div>
                      <div>{i18n.t('assets.details.make')}</div>
                      <div>{i18n.t('assets.details.model')}</div>
                    </Col>
                    <Col sm="8">
                      <a href="#/" onClick={() => this.goTo('groups', this.props.booking.groupId)}>{getArrayElementProperty(this.props.groups, this.props.booking.groupId, 'name')}</a>
                      <div className="text-muted">{getArrayElementProperty(this.props.assetTypes, this.props.booking.assetTypeId, 'name')}</div>
                      <div className="text-muted">{getArrayElementProperty(this.props.assetTypes, this.props.booking.assetTypeId, 'make')}</div>
                      <div className="text-muted">{getArrayElementProperty(this.props.assetTypes, this.props.booking.assetTypeId, 'model')}</div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="3" style={{ whiteSpace: 'nowrap' }}>
                  <Row>
                    <Col sm="4">
                      <div>{i18n.t('generic.startTime')}</div>
                      <div>{i18n.t('generic.endTime')}</div>
                      <div>{i18n.t('generic.created')}</div>
                    </Col>
                    <Col sm="7">
                      <div className="text-muted">{toDate(this.props.booking.start)}</div>
                      <div className="text-muted">{toDate(this.props.booking.end)}</div>
                      <div className="text-muted">{toDate(this.props.booking.createdAt)}</div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="4" style={{ whiteSpace: 'nowrap' }}>
                  <Row>
                    <Col sm="5">
                      <div>{i18n.t('generic.activatedTime')}</div>
                      <div>{i18n.t('generic.terminatedTime')}</div>
                      <div>{i18n.t('generic.usage')}</div>
                    </Col>
                    <Col sm="7">
                      <div className="text-muted">{toDate(this.props.booking.activationTimestamp)}</div>
                      <div className="text-muted">{toDate(this.props.booking.terminationTimestamp)}</div>
                      <div className="text-muted">{toPartitionedString(this.props.booking.usageSeconds)}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </IrisBox>
            <IrisBox>
              <div className="title mb-4">
                <h4><small className="text-muted">{i18n.t('assignments.title')}</small></h4>
              </div>
              <IrisDatatable data={this.props.bookingItems} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn
                  dataField="status" width="120px"
                  dataFormat={(cell, row) => resolveAssignmentStatus(row)}
                  searchable>{i18n.t('generic.status')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="userId"
                  dataFormat={(cell) => <a href="#/" onClick={() => this.goTo('users', cell)}>{getArrayElementProperty(this.props.users, cell, 'name', cell)}</a>}
                  searchable>{i18n.t('users.user')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="assetId"
                  dataFormat={(cell) => <a href="#/" onClick={() => this.goTo('assets', cell)}>{resolveAssetName(this.props.assets, cell)}</a>}
                  searchable>{i18n.t('assets.asset')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="start" width="165px"
                  dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.startTime')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="end" width="165px"
                  dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.endTime')}</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>
          </Col>
        </Row>

        <Row>
          <Col sm="6">
            <IrisBox>
              <div className="title mb-4">
                <h4><small className="text-muted">{i18n.t('checklists.title')}</small></h4>
              </div>
              <IrisDatatable height='350px' data={this.props.bookingChecklists} options={checklistOptions} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn
                  dataField="type"
                  dataFormat={(cell) => resolveChecklistType(cell)} searchable>{i18n.t('generic.type')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="metadata"
                  dataFormat={(cell) => getArrayElementProperty(this.props.users, cell.userId, 'name', cell.userId)} searchable>{i18n.t('users.user')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="metadata"
                  dataFormat={(cell) => resolveAssetName(this.props.assets, cell.assetId)} searchable>{i18n.t('assets.asset')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="timestamp" width="165px"
                  dataFormat={(cell) => toDate(cell)}>{i18n.t('generic.timestamp')}</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>
          </Col>
          <Col sm="6">
            <IrisBox>
              <div className="title mb-4">
                <h4><small className="text-muted">{i18n.t('events.title')}</small></h4>
              </div>
              <IrisDatatable height='350px' data={this.props.bookingEvents} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                <IrisTableHeaderColumn dataField="id" isKey hidden/>
                <IrisTableHeaderColumn
                  dataField="telemetry"
                  dataFormat={(cell) => resolveEventName(cell)} searchable>{i18n.t('generic.type')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="telemetry"
                  dataFormat={(cell) => getArrayElementProperty(this.props.users, cell.userId, 'name', cell.userId)} searchable>{i18n.t('users.user')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="metadata"
                  dataFormat={(cell) => resolveAssetName(this.props.assets, cell.assetId)} searchable>{i18n.t('assets.asset')}</IrisTableHeaderColumn>
                <IrisTableHeaderColumn
                  dataField="telemetry" width="165px"
                  dataFormat={(cell) => toDate(cell.timestamp)}>{i18n.t('generic.timestamp')}</IrisTableHeaderColumn>
              </IrisDatatable>
            </IrisBox>
          </Col>
        </Row>

        {this.state.modalUpdateBooking
         && <UpdateBookingForm title={i18n.t('bookings.updateBookingForm.title')}
                               icon={<i className="fa fa-key fa-fw"/>}
                               booking={this.props.booking} users={this.props.users} assets={this.props.assets}
                               assetTypes={this.props.assetTypes} groups={this.props.groups}
                               onUpdate={this.onUpdate} toggle={this.toggleModalUpdateBooking}/>}

        {this.state.modalCancelBooking
         && <ConfirmDialog title={i18n.t('bookings.cancelBookingDialog.title')}
                           icon={<i className="fa fa-trash fa-fw"/>}
                           confirmText={i18n.t('bookings.cancelBookingDialog.confirmText')} itemName={this.props.booking.name}
                           confirmButtonName={i18n.t('buttons.confirm')} onConfirm={this.cancelBooking} toggle={this.toggleModalCancelBooking}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    detailsType: fleetmanager.posts.details.type,
    booking: fleetmanager.posts.details.data,
    bookingItems: fleetmanager.posts.bookingItems,
    users: fleetmanager.posts.users,
    assets: fleetmanager.posts.assets,
    groups: fleetmanager.posts.groups,
    assetTypes: fleetmanager.posts.assetTypes,
    bookingChecklists: fleetmanager.posts.bookingChecklists,
    bookingEvents: fleetmanager.posts.bookingEvents,
  };
};

export default connect(mapStateToProps, {
  getBooking,
  getUsers,
  getAssets,
  getAssetTypes,
  getGroups,
  getBookingItems,
  getBookingChecklists,
  getBookingEvents,
  updateBooking,
  cancelBooking,
})(BookingDetails);
