import {
  RESET,
  AUTHENTICATE_START,
  AUTHENTICATE_DONE,
  AUTHENTICATE_ERROR,
  POSTS_LIST_START,
  LIST_ACCESS_RULES_DONE,
  LIST_ADMIN_ACCOUNTS_DONE,
  LIST_ASSET_ASSIGNMENTS_DONE,
  LIST_ASSET_BOOKINGS_DONE,
  LIST_ASSET_CHECKLISTS_DONE,
  LIST_ASSET_TYPES_DONE,
  LIST_ASSET_USAGES_DONE,
  LIST_ASSETS_DONE,
  LIST_ASSIGNMENT_CHECKLISTS_DONE,
  LIST_ASSIGNMENT_EVENTS_DONE,
  LIST_ASSIGNMENTS_DONE,
  LIST_AVAILABLE_ASSETS_FOR_BOOKING_DONE,
  LIST_BOOKING_CHECKLISTS_DONE,
  LIST_BOOKING_EVENTS_DONE,
  LIST_BOOKING_ITEMS_DONE,
  LIST_BOOKINGS_DONE,
  LIST_IMAGE_REFERENCES_DONE,
  LIST_CURRENT_BOOKINGS_DONE,
  LIST_GROUPS_DONE,
  LIST_KEYSTONE_POLICIES_DONE,
  LIST_POLICIES_DONE,
  LIST_POLICY_RULES_DONE,
  LIST_ROLES_DONE,
  LIST_USER_ASSIGNMENTS_DONE,
  LIST_USER_AUTH_RECORDS_DONE,
  LIST_USERS_DONE,
  POSTS_LIST_ERROR,
  GET_IMAGE_DONE,
  GET_DETAILS_START,
  GET_DETAILS_DONE,
  GET_DETAILS_ERROR,
  UPDATE_DETAILS_START,
  UPDATE_DETAILS_DONE,
  UPDATE_DETAILS_ERROR,
  POST_START,
  POST_DONE,
  POST_ERROR,
  DELETE_START,
  DELETE_DONE,
  DELETE_ERROR,
} from '../constants';

const resetState = {
  accessRules: null,
  assets: null,
  assetTypes: null,
  assetAssignments: null,
  assetBookings: null,
  assetChecklists: null,
  assetUsages: null,
  assignments: null,
  assignmentChecklists: null,
  assignmentEvents: null,
  availableAssetsForBooking: null,
  bookings: null,
  bookingChecklists: null,
  bookingEvents: null,
  bookingItems: null,
  currentBookings: null,
  imageReferences: null,
  data: null,
  groups: null,
  keystonePolicies: null,
  policies: null,
  policyRules: null,
  users: null,
  userAssignments: null,
  userAuthRecords: null,
};

const initialState = {
  isAuthenticating: false,
  isFetching: false,
  isPosting: false,
  isUpdating: false,
  isDeleting: false,

  profile: null,
  result: null,
  error: null,

  adminAccounts: null,
  roles: null,
  ...resetState,

  details: {},
  image: {},
};

export default (state = initialState, payload) => {
  switch (payload.type) {
    case RESET:
      return { ...state, ...resetState };
    case AUTHENTICATE_START:
      return { ...state, isAuthenticating: true };
    case AUTHENTICATE_DONE:
      return { ...state, isAuthenticating: false, profile: payload.profile };
    case AUTHENTICATE_ERROR:
      return { ...state, isAuthenticating: false, error: payload.error };
    case POSTS_LIST_START:
      return { ...state, isFetching: true, result: null };
    case LIST_ADMIN_ACCOUNTS_DONE:
      return { ...state, isFetching: false, adminAccounts: payload.adminAccounts };
    case LIST_ASSETS_DONE:
      return { ...state, isFetching: false, assets: payload.assets };
    case LIST_ASSET_TYPES_DONE:
      return { ...state, isFetching: false, assetTypes: payload.assetTypes };
    case LIST_USERS_DONE:
      return { ...state, isFetching: false, users: payload.users };
    case LIST_USER_ASSIGNMENTS_DONE:
      return { ...state, isFetching: false, userAssignments: payload.userAssignments };
    case LIST_USER_AUTH_RECORDS_DONE:
      return { ...state, isFetching: false, userAuthRecords: payload.userAuthRecords };
    case LIST_ASSIGNMENTS_DONE:
      return { ...state, isFetching: false, assignments: payload.assignments };
    case LIST_ASSIGNMENT_CHECKLISTS_DONE:
      return { ...state, isFetching: false, assignmentChecklists: payload.assignmentChecklists };
    case LIST_ASSIGNMENT_EVENTS_DONE:
      return { ...state, isFetching: false, assignmentEvents: payload.assignmentEvents };
    case LIST_ROLES_DONE:
      return { ...state, isFetching: false, roles: payload.roles };
    case LIST_GROUPS_DONE:
      return { ...state, isFetching: false, groups: payload.groups };
    case LIST_ASSET_BOOKINGS_DONE:
      return { ...state, isFetching: false, assetBookings: payload.assetBookings };
    case LIST_ASSET_ASSIGNMENTS_DONE:
      return { ...state, isFetching: false, assetAssignments: payload.assetAssignments };
    case LIST_ASSET_CHECKLISTS_DONE:
      return { ...state, isFetching: false, assetChecklists: payload.assetChecklists };
    case LIST_IMAGE_REFERENCES_DONE:
      return { ...state, isFetching: false, imageReferences: payload.imageReferences };
    case LIST_BOOKINGS_DONE:
      return { ...state, isFetching: false, bookings: payload.bookings };
    case LIST_CURRENT_BOOKINGS_DONE:
      return { ...state, isFetching: false, currentBookings: payload.currentBookings };
    case LIST_BOOKING_ITEMS_DONE:
      return { ...state, isFetching: false, bookingItems: payload.bookingItems };
    case LIST_BOOKING_CHECKLISTS_DONE:
      return { ...state, isFetching: false, bookingChecklists: payload.bookingChecklists };
    case LIST_BOOKING_EVENTS_DONE:
      return { ...state, isFetching: false, bookingEvents: payload.bookingEvents };
    case LIST_AVAILABLE_ASSETS_FOR_BOOKING_DONE:
      return { ...state, isFetching: false, availableAssetsForBooking: payload.availableAssetsForBooking };
    case LIST_KEYSTONE_POLICIES_DONE:
      return { ...state, isFetching: false, keystonePolicies: payload.keystonePolicies };
    case LIST_POLICIES_DONE:
      return { ...state, isFetching: false, policies: payload.policies };
    case LIST_POLICY_RULES_DONE:
      return { ...state, isFetching: false, policyRules: payload.policyRules };
    case LIST_ACCESS_RULES_DONE:
      return { ...state, isFetching: false, accessRules: payload.accessRules };
    case LIST_ASSET_USAGES_DONE:
      return { ...state, isFetching: false, assetUsages: payload.assetUsages };
    case POSTS_LIST_ERROR:
      return { ...state, isFetching: false, error: payload.error };
    case GET_IMAGE_DONE:
      return { ...state, isFetching: false, image: payload.image };
    case GET_DETAILS_START:
      return { ...state, isFetching: true };
    case GET_DETAILS_DONE:
      return { ...state, isFetching: false, details: payload.details };
    case GET_DETAILS_ERROR:
      return { ...state, isFetching: false, error: payload.error };
    case UPDATE_DETAILS_START:
      return { ...state, isUpdating: true };
    case UPDATE_DETAILS_DONE:
      return { ...state, isUpdating: false, details: payload.details };
    case UPDATE_DETAILS_ERROR:
      return { ...state, isUpdating: false, error: payload.error };
    case POST_START:
      return { ...state, isPosting: true };
    case POST_DONE:
      return addPostResultToArray(state, payload.result);
    case POST_ERROR:
      return { ...state, isPosting: false, error: payload.error };
    case DELETE_START:
      return { ...state, isDeleting: true };
    case DELETE_DONE:
      return removeResultFromArray(state, payload.result);
    case DELETE_ERROR:
      return { ...state, isDeleting: false, error: payload.error };
    default:
      return state;
  }
};

function addPostResultToArray(state, result) {
  switch (result.type) {
    case 'asset':
      return { ...state, isPosting: false, error: null, assets: [result.data, ...state.assets] };
    case 'user':
      return { ...state, isPosting: false, error: null, users: [result.data, ...state.users] };
    case 'group':
      return { ...state, isPosting: false, error: null, groups: [result.data, ...state.groups] };
    case 'assignment':
      return { ...state, isPosting: false, error: null, assignments: [result.data, ...state.assignments] };
    case 'booking':
      return { ...state, isPosting: false, error: null, bookings: [result.data, ...state.bookings] };
    case 'policyrule':
      return { ...state, isPosting: false, error: null, policyRules: [result.data, ...state.policyRules] };
    case 'policy':
      return { ...state, isPosting: false, error: null, policies: [result.data, ...state.policies] };
    case 'accessrule':
      return { ...state, isPosting: false, error: null, accessRules: [result.data, ...state.accessRules] };
    default:
      return state;
  }
}

function removeResultFromArray(state, result) {
  switch (result.type) {
    case 'asset':
      return { ...state, isDeleting: false, assets: [...state.assets.filter(asset => asset.id !== result.data)] };
    case 'user':
      return { ...state, isDeleting: false, users: [...state.users.filter(user => user.id !== result.data)] };
    case 'group':
      return { ...state, isDeleting: false, groups: [...state.groups.filter(group => group.id !== result.data)] };
    case 'assignment':
      return { ...state, isDeleting: false, assignments: [...state.assignments.filter(assignment => assignment.id !== result.data)] };
    case 'booking':
      return { ...state, isDeleting: false, bookings: [...state.bookings.filter(booking => booking.id !== result.data)] };
    case 'policyrule':
      return { ...state, isDeleting: false, policyRules: [...state.policyRules.filter(policyRule => policyRule.id !== result.data)] };
    case 'policy':
      return { ...state, isDeleting: false, policies: [...state.policies.filter(policy => policy.id !== result.data)] };
    case 'accessrule':
      return { ...state, isDeleting: false, accessRules: [...state.accessRules.filter(accessRule => accessRule.id !== result.data)] };
    default:
      return state;
  }
}
