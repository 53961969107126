import React from 'react';

import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';

import {
  func,
  array,
  string,
  object,
} from 'prop-types';

import { IrisSelect, IrisButton } from 'iris-core/libs/components';

import { GENERIC_NAME_MAX_LENGTH } from '../../globalConstants';
import i18n from '../../i18n';

/*
* Form to create access-rule
*/
class AccessRuleForm extends React.Component {
  static propTypes = {
    title: string.isRequired,
    users: array.isRequired,
    assets: array.isRequired,
    groups: array.isRequired,
    toggle: func.isRequired,
    onSubmit: func.isRequired,
    icon: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.users = this.props.users.map(value => ({ value: value.id, label: value.name }));
    this.assets = this.props.assets.map(value => ({ value: value.id, label: value.id }));

    this.userGroups = this.getGroups('users');
    this.assetGroups = this.getGroups('assets');

    this.userOptions = ['User', 'Group'];
    this.assetOptions = ['Asset', 'Group'];

    this.virtualKeyCreationOptions = [
      {
        value: 'self_generate',
        label: 'Self',
      },
      {
        value: 'upon_approval',
        label: 'Upon Approval',
      },
    ];

    this.virtualKeyValidityOptions = [
      {
        value: 'limit',
        label: 'Limit',
      },
      // {
      //   value: 'date',
      //   label: 'Date Selector',
      // },
    ];

    this.state = {
      isOpen: true,
      name: '',
      description: '',
      userChoice: this.userOptions[0],
      userChoiceId: this.users?.[0]?.value ?? '',
      assetChoice: this.assetOptions[0],
      assetChoiceId: this.assets?.[0]?.value ?? '',
      virtualKeyCreationPolicyValue: this.virtualKeyCreationOptions[0].value,
      virtualKeyValidityType: this.virtualKeyValidityOptions[0].value,
      virtualKeyValidityValue: 8,
    };
  }

  toggleWindow = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.toggle();
  }

  getGroups = (type) => {
    return this.props.groups.filter(element => element.type === type).map(value => ({ value: value.id, label: value.name }));
  }

  getUsers = () => {
    return (this.state.userChoice === 'Group' ? this.userGroups : this.users);
  }

  getAssets = () => {
    return (this.state.assetChoice === 'Group' ? this.assetGroups : this.assets);
  }

  onUserOptionChange = (value) => {
    this.setState({
      userChoice: value,
      userChoiceId: (value === 'Group' ? this.userGroups : this.users)?.[0]?.value ?? '',
    });
  }

  onAssetOptionChange = (value) => {
    this.setState({
      assetChoice: value,
      assetChoiceId: (value === 'Group' ? this.assetGroups : this.assets)?.[0]?.value ?? '',
    });
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit({
      name: this.state.name,
      description: this.state.description || undefined,
      user: {
        id: this.state.userChoiceId,
        type: this.state.userChoice.toLowerCase(),
      },
      asset: {
        id: this.state.assetChoiceId,
        type: this.state.assetChoice.toLowerCase(),
      },
      virtualKey: {
        creation: this.state.virtualKeyCreationPolicyValue,
        validityType: this.state.virtualKeyValidityType,
        validityValue: this.state.virtualKeyValidityValue,
      },
    }).then(() => {
      this.toggleWindow();
    });
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen} fade={true}>
        <Form className="modal-content" onSubmit={this.onSubmit}>
          <ModalHeader toggle={this.toggleWindow}>
            {this.props.icon} {this.props.title}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>{i18n.t('generic.name')}:</Label>
              <Input required name="name" maxLength={GENERIC_NAME_MAX_LENGTH} placeholder={i18n.t('accessRules.createAccessRuleForm.namePlaceholder')}
                     onChange={e => this.setState({ name: e.target.value })} value={this.state.name}/>
            </FormGroup>
            <FormGroup>
              <Label>{i18n.t('generic.description')}:</Label>
              <Input name="description" placeholder={i18n.t('accessRules.createAccessRuleForm.descriptionPlaceholder')}
                     onChange={e => this.setState({ description: e.target.value })} value={this.state.description}/>
            </FormGroup>
            <hr/>
            <FormGroup>
              <Label>{i18n.t('generic.selectUser')}:&nbsp;</Label>
              <IrisSelect options={this.userOptions} onChange={value => this.onUserOptionChange(value)} outline/>
              <span>&nbsp;</span>
              <IrisSelect options={this.getUsers()} onChange={value => this.setState({ userChoiceId: value })} outline/>
            </FormGroup>
            <FormGroup>
              <Label>{i18n.t('generic.selectAsset')}:&nbsp;</Label>
              <IrisSelect options={this.assetOptions} onChange={value => this.onAssetOptionChange(value)} outline/>
              <span>&nbsp;</span>
              <IrisSelect options={this.getAssets()} onChange={value => this.setState({ assetChoiceId: value })} outline/>
            </FormGroup>
            <hr/>
            <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label>{i18n.t('accessRules.createAccessRuleForm.keyCreation')}:&nbsp;</Label>
                  <IrisSelect options={this.virtualKeyCreationOptions} onChange={value => this.setState({ virtualKeyCreationPolicyValue: value })} readOnly outline/>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>{i18n.t('generic.keyValidity')}:&nbsp;</Label>
                  <IrisSelect options={this.virtualKeyValidityOptions} onChange={value => this.setState({ virtualKeyValidityType: value })} outline/>
                </FormGroup>
              </Col>
              {this.state.virtualKeyValidityType === 'limit' && <Col md={2}>
                <FormGroup>
                  <Label>{i18n.t('generic.hours')}:</Label>
                  <Input name="limit" onChange={e => this.setState({ virtualKeyValidityValue: e.target.value })} value={this.state.virtualKeyValidityValue}/>
                </FormGroup>
              </Col>}
            </Row>
          </ModalBody>
          <ModalFooter>
            <IrisButton disabled={!(this.state.userChoiceId && this.state.assetChoiceId)} color="info" size="sm" type="submit">
              {i18n.t('buttons.create')}
            </IrisButton>
            <IrisButton color="secondary" size="sm" onClick={this.toggleWindow} outline>
              {i18n.t('buttons.close')}
            </IrisButton>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default AccessRuleForm;
