import React from 'react';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import {
  IrisSpinner, IrisBox, IrisButton, IrisDatatable, IrisTableHeaderColumn,
} from 'iris-core/libs/components';

import {
  UpdateGroupForm, ConfirmDialog, AddMemberForm, RemoveMemberForm,
} from '../../components';
import {
  getGroup, updateGroup, deleteGroup, getUsers, getAssets, getAssetTypes, modifyGroupMember,
} from '../../actions';
import { toDate, getArrayElementProperty, resolveGroupType, resolveAssetName } from '../../helper';
import { SELECT_ROW_PROPS } from '../../globalConstants';
import i18n from '../../i18n';

class GroupDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalUpdateGroup: false,
      modalDeleteGroup: false,
      modalAddMemberForm: false,
      modalRemoveMemberForm: false,
    };
  }

  componentDidMount() {
    this.props.getGroup(this.props.match.params.rowId).then(() => {
      if (this.props.group.type === 'users') {
        this.props.getUsers().then(() => {
          this.entities = this.props.users;
          this.setState({ loading: false });
        });
      } else {
        Promise.all([this.props.getAssetTypes(), this.props.getAssets()]).then(() => {
          this.entities = this.props.assets;
          this.setState({ loading: false });
        });
      }
    });
  }

  setMembers = () => {
    if (!this.entities || !this.props.group.members) {
      this.mappedMembers = [];
      this.nonMembers = this.entities || [];
    } else {
      this.mappedMembers = this.entities.filter(entity => this.props.group.members.includes(entity.id));
      this.nonMembers = this.entities.filter(entity => !this.props.group.members.includes(entity.id));
    }
  }

  deleteGroup = () => {
    this.props.deleteGroup(this.props.group.id).then(() => {
      if (this.props.error !== 'group_deletion_error') {
        this.props.history.goBack();
      }
    });
  }

  toggleModalUpdateGroup = () => {
    this.setState({ modalUpdateGroup: !this.state.modalUpdateGroup });
  }

  toggleModalDeleteGroup = () => {
    this.setState({ modalDeleteGroup: !this.state.modalDeleteGroup });
  }

  toggleModalAddMemberForm = () => {
    this.setState({ modalAddMemberForm: !this.state.modalAddMemberForm });
  }

  toggleModalRemoveMemberForm = () => {
    this.setState({ modalRemoveMemberForm: !this.state.modalRemoveMemberForm });
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    this.setMembers();

    const memberOptions = {
      onRowClick: (row) => this.props.history.push(`${window.BASE_PATH}/${this.props.group.type}/${row.id}`),
    };

    const toolbarButtons = [
      <IrisButton key={_.uniqueId()} color="danger" size="sm"
                  disabled={!this.mappedMembers.length}
                  onClick={this.toggleModalRemoveMemberForm}
                  outline>{i18n.t('buttons.remove')}...</IrisButton>,
      <IrisButton key={_.uniqueId()} color="info" size="sm"
                  disabled={!this.nonMembers.length}
                  onClick={this.toggleModalAddMemberForm}
                  outline>{i18n.t('buttons.add')}...</IrisButton>,
    ];

    return (
      <div className="GroupDetails">
        <Row>
          <Col>
            <IrisBox>
              <Row>
                <Col>
                  <div className="table-title d-flex flex-row align-items-center">
                    <h4 className="mr-auto"><i className="fa fa-users"/> <small className="text-muted">{this.props.group.name}</small></h4>
                    <IrisButton color="primary" size="sm" onClick={this.toggleModalUpdateGroup} outline>{i18n.t('buttons.update')}...</IrisButton>
                    <IrisButton color="secondary" size="sm" onClick={this.toggleModalDeleteGroup} outline>{i18n.t('buttons.delete')}...</IrisButton>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm="4">
                  <Row>
                    <Col sm="3">
                      <div>{i18n.t('generic.type')}</div>
                      <div>{i18n.t('generic.created')}</div>
                    </Col>
                    <Col sm="9">
                      <div className="text-muted">{resolveGroupType(this.props.group.type)}</div>
                      <div className="text-muted">{toDate(this.props.group.createdAt)}</div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="4">
                  <Row>
                    <Col sm="3">
                      <div>{i18n.t('generic.description')}</div>
                      <div>{i18n.t('groups.members')}</div>
                    </Col>
                    <Col sm="9">
                      <div className="text-muted">{this.props.group.description || '\u00A0'}</div>
                      <div className="text-muted">{this.mappedMembers.length}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </IrisBox>
            <IrisBox>
              <div className="title mb-4">
                <h4><small className="text-muted">{i18n.t('groups.members')}</small></h4>
              </div>
              {this.props.group.type === 'users'
                ? <IrisDatatable data={this.mappedMembers} options={memberOptions} toolbar={toolbarButtons} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                    <IrisTableHeaderColumn dataField="id" isKey hidden/>
                    <IrisTableHeaderColumn dataField="name" searchable>{i18n.t('users.user')}</IrisTableHeaderColumn>
                    <IrisTableHeaderColumn dataField="email" searchable>{i18n.t('users.details.email')}</IrisTableHeaderColumn>
                    <IrisTableHeaderColumn
                      dataField="externalId"
                      hidden={!_.get(this.props.profile.account.iris, 'users.fields.externalId', false)}
                      searchable>{i18n.t('users.details.externalId')}
                    </IrisTableHeaderColumn>
                    <IrisTableHeaderColumn dataField="role" searchable>{i18n.t('users.role')}</IrisTableHeaderColumn>
                  </IrisDatatable>
                : <IrisDatatable data={this.mappedMembers} options={memberOptions} toolbar={toolbarButtons} selectRow={SELECT_ROW_PROPS} striped hover pagination search>
                    <IrisTableHeaderColumn dataField="id" isKey hidden/>
                    <IrisTableHeaderColumn
                      dataField="id"
                      dataFormat={(cell) => resolveAssetName(this.props.assets, cell)}
                      searchable>{i18n.t('assets.asset')}</IrisTableHeaderColumn>
                    <IrisTableHeaderColumn
                      dataField="assetTypeId"
                      dataFormat={(cell) => getArrayElementProperty(this.props.assetTypes, cell, 'name')}
                      searchable>{i18n.t('generic.type')}</IrisTableHeaderColumn>
                    <IrisTableHeaderColumn
                      dataField="assetTypeId"
                      dataFormat={(cell) => getArrayElementProperty(this.props.assetTypes, cell, 'make')}
                      searchable>{i18n.t('assets.details.make')}</IrisTableHeaderColumn>
                    <IrisTableHeaderColumn
                      dataField="assetTypeId"
                      dataFormat={(cell) => getArrayElementProperty(this.props.assetTypes, cell, 'model')}
                      searchable>{i18n.t('assets.details.model')}</IrisTableHeaderColumn>
                    <IrisTableHeaderColumn
                      dataField="year" width="140px"
                      searchable>{i18n.t('assets.details.year')}</IrisTableHeaderColumn>
                  </IrisDatatable>}
            </IrisBox>
          </Col>
        </Row>

        {this.state.modalUpdateGroup
         && <UpdateGroupForm title={i18n.t('groups.updateGroupForm.title')} icon={<i className="fa fa-users fa-fw"/>}
                             group={this.props.group}
                             onSubmit={this.props.updateGroup} toggle={this.toggleModalUpdateGroup}/>}

        {this.state.modalDeleteGroup
         && <ConfirmDialog title={i18n.t('groups.deleteGroupDialog.title')} icon={<i className="fa fa-trash fa-fw"/>}
                           confirmText={i18n.t('groups.deleteGroupDialog.confirmText')} itemName={this.props.group.name}
                           confirmButtonName={i18n.t('buttons.delete')} onConfirm={this.deleteGroup} toggle={this.toggleModalDeleteGroup}/>}

        {this.state.modalAddMemberForm
         && <AddMemberForm title={i18n.t('groups.addMemberForm.title')} icon={<i className="fa fa-plus fa-fw"/>}
                           group={this.props.group} nonMembers={this.nonMembers}
                           onSubmit={this.props.modifyGroupMember} toggle={this.toggleModalAddMemberForm}/>}

        {this.state.modalRemoveMemberForm
         && <RemoveMemberForm title={i18n.t('groups.removeMemberForm.title')} icon={<i className="fa fa-minus fa-fw"/>}
                              group={this.props.group} members={this.mappedMembers}
                              onSubmit={this.props.modifyGroupMember} toggle={this.toggleModalRemoveMemberForm}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    profile: fleetmanager.posts.profile,
    detailsType: fleetmanager.posts.details.type,
    group: fleetmanager.posts.details.data,
    users: fleetmanager.posts.users,
    assets: fleetmanager.posts.assets,
    assetTypes: fleetmanager.posts.assetTypes,
    error: fleetmanager.posts.error,
  };
};

export default connect(mapStateToProps, {
  getGroup, updateGroup, deleteGroup, getUsers, getAssets, getAssetTypes, modifyGroupMember,
})(GroupDetails);
