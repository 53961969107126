import React from 'react';
import _ from 'lodash';
import {
  Collapse, Card, CardBody, Row, Col, Label,
} from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  IrisSpinner, IrisBox, IrisButton, IrisToggleSwitcher,
} from 'iris-core/libs/components';

import { BookingForm } from '../../components';
import {
  resetState, getBookings, getUsers, getAssets, getAssetTypes, getGroups,
} from '../../actions';
import {
  toMinutes, toDate, getArrayElementProperty, resolveBookingStatus, resolveAssetName, generateSelectMap,
  generateSelectMapWithFilter, downloadCsvFile,
} from '../../helper';
import bookingStatusOptions from '../../data/bookingStatusOptions.json';
import i18n from '../../i18n';

const { SearchBar } = Search;
const CSV_FIELDS_BOOKING = [
  { i18n: 'bookings.name', property: 'name' },
  { i18n: 'generic.status', property: 'status' },
  { i18n: 'assets.assetType', property: 'assetTypeId' },
  { i18n: 'assets.details.make', property: 'make' },
  { i18n: 'assets.details.model', property: 'model' },
  { i18n: 'assets.asset', property: 'assetId' },
  { i18n: 'groups.group', property: 'groupId' },
  { i18n: 'generic.startTime', property: 'start' },
  { i18n: 'generic.endTime', property: 'end' },
  { i18n: 'generic.activatedTime', property: 'activationTimestamp' },
  { i18n: 'generic.terminatedTime', property: 'terminationTimestamp' },
  { i18n: 'generic.usageMinutes', property: 'usageSeconds' },
];

class Bookings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalBookingForm: false,
      filters: {},
    };
    this.props.resetState();
  }

  componentDidMount() {
    Promise.all([this.props.getUsers(), this.props.getAssets(), this.props.getAssetTypes(), this.props.getGroups()]).then(() => {
      this.props.getBookings();
      this.userGroupOptions = generateSelectMapWithFilter(this.props.groups, {}, group => group.type === 'users');
      this.assetTypeOptions = generateSelectMap(this.props.assetTypes, {});
      this.setState({ loading: false });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.isEmpty(this.state.filters) && !_.isEmpty(prevState.filters)) {
      this.props.getBookings();
    }

    if (!_.isEmpty(this.state.filters) && !_.isEqual(this.state.filters, prevState.filters)) {
      this.props.getBookings(this.getUrlEncodedFilters());
    }
  }

  toggleModalBookingForm = () => {
    this.setState({ modalBookingForm: !this.state.modalBookingForm });
  }

  toggleFilter = () => {
    this.setState({
      openFilters: true,
      filters: {},
      selectedUserGroups: [],
      selectedAssetTypes: [],
      selectedStatuses: [],
    });
  }

  clearFilter = () => {
    this.setState({
      openFilters: false,
      filters: {},
    });
  }

  getUrlEncodedFilters = () => {
    const queryArray = [];

    if (this.state.filters.startDate) {
      queryArray.push(`start=${this.state.filters.startDate.toISOString()}`);
    }

    if (this.state.filters.endDate) {
      queryArray.push(`end=${this.state.filters.endDate.toISOString()}`);
    }

    if (this.state.filters.attentionFlag) {
      queryArray.push(`attentionflag=${this.state.filters.attentionFlag}`);
    }

    if (this.state.filters.assetTypeIds?.length) {
      queryArray.push(this.state.filters.assetTypeIds.join('&'));
    }

    if (this.state.filters.userGroupIds?.length) {
      queryArray.push(this.state.filters.userGroupIds.join('&'));
    }

    if (this.state.filters.statuses?.length) {
      queryArray.push(this.state.filters.statuses.join('&'));
    }

    return queryArray.join('&');
  }

  setSelectedAssetType = (selectedAssetTypes) => {
    const array = selectedAssetTypes && selectedAssetTypes.map(assetType => `assettype=${assetType.value}`);
    this.setState({
      selectedAssetTypes,
      filters: { ...this.state.filters, assetTypeIds: array },
    });
  }

  setSelectedUserGroup = (selectedUserGroups) => {
    const array = selectedUserGroups && selectedUserGroups.map(userGroup => `usergroup=${userGroup.value}`);
    this.setState({
      selectedUserGroups,
      filters: { ...this.state.filters, userGroupIds: array },
    });
  }

  setSelectedStatus = (selectedStatuses) => {
    const array = selectedStatuses && selectedStatuses.map(status => `status=${status.value}`);
    this.setState({
      selectedStatuses,
      filters: { ...this.state.filters, statuses: array },
    });
  }

  setAttentionFlag = (attentionFlag) => {
    this.setState({ filters: { ...this.state.filters, attentionFlag } });
  }

  setStartDate = (date) => {
    this.setState({ filters: { ...this.state.filters, startDate: date } });
  }

  setEndDate = (date) => {
    this.setState({ filters: { ...this.state.filters, endDate: date } });
  }

  csvBookingStatus = (booking) => {
    const currentTime = Date.now();
    const startTime = new Date(booking.start).getTime();
    const endTime = new Date(booking.end).getTime();

    if (booking.status === 'cancelled') {
      return 'Cancelled';
    }

    if (booking.status === 'pending') {
      if (startTime > currentTime) {
        return 'Future';
      }

      if (endTime > currentTime) {
        return 'Pending';
      }

      if (endTime < currentTime) {
        return 'Expired';
      }
    }

    if (booking.status === 'activated') {
      if (endTime > currentTime) {
        return 'Activated';
      }

      if (endTime < currentTime) {
        return 'Outstanding';
      }
    }

    if (booking.status === 'completed') {
      return 'Completed';
    }

    return 'Unknown';
  }

  exportBookings = () => {
    const csvList = [];
    csvList.push(CSV_FIELDS_BOOKING.map(field => `"${i18n.t(field.i18n)}"`).join());

    let fields;
    for (const booking of this.props.bookings) {
      fields = [];

      for (const field of CSV_FIELDS_BOOKING) {
        switch (field.property) {
          case 'assetId':
            fields.push(`"${resolveAssetName(this.props.assets, booking.assets[0])}"`);
            break;
          case 'assetTypeId':
            fields.push(`"${getArrayElementProperty(this.props.assetTypes, booking.assetTypeId, 'name')}"`);
            break;
          case 'make':
          case 'model':
            fields.push(`"${getArrayElementProperty(this.props.assetTypes, booking.assetTypeId, field.property)}"`);
            break;
          case 'groupId':
            fields.push(`"${getArrayElementProperty(this.props.groups, booking.groupId, 'name')}"`);
            break;
          case 'status':
            fields.push(`"${this.csvBookingStatus(booking)}"`);
            break;
          case 'start':
          case 'end':
          case 'activationTimestamp':
          case 'terminationTimestamp':
            fields.push(`"${toDate(booking[field.property])}"`);
            break;
          case 'usageSeconds':
            fields.push(`"${toMinutes(booking.usageSeconds)}"`);
            break;
          default:
            fields.push(`"${booking[field.property]}"`);
            break;
        }
      }

      csvList.push(fields.join());
    }

    downloadCsvFile(csvList.join('\n').concat('\n'), 'bookings');
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    const columns = [{
      dataField: 'id',
      hidden: true,
    }, {
      sort: true,
      dataField: 'status',
      text: i18n.t('generic.status'),
      headerStyle: { width: '125px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell, row) => resolveBookingStatus(row),
    }, {
      sort: true,
      dataField: 'name',
      text: i18n.t('bookings.name'),
      headerStyle: { width: '105px', color: '#0093ca', fontWeight: 'normal' },
    }, {
      sort: true,
      dataField: 'groupId',
      text: i18n.t('groups.group'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => getArrayElementProperty(this.props.groups, cell, 'name'),
    }, {
      sort: true,
      dataField: 'assetTypeId',
      text: i18n.t('assets.assetType'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => getArrayElementProperty(this.props.assetTypes, cell, 'name'),
    }, {
      sort: true,
      dataField: 'assetTypeId',
      text: i18n.t('assets.details.make'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => getArrayElementProperty(this.props.assetTypes, cell, 'make'),
    }, {
      sort: true,
      dataField: 'assetTypeId',
      text: i18n.t('assets.details.model'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => getArrayElementProperty(this.props.assetTypes, cell, 'model'),
    }, {
      sort: true,
      dataField: 'start',
      text: i18n.t('generic.startTime'),
      headerStyle: { width: '165px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => toDate(cell),
    }, {
      sort: true,
      dataField: 'end',
      text: i18n.t('generic.endTime'),
      headerStyle: { width: '165px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => toDate(cell),
    }, {
      sort: true,
      dataField: 'usageSeconds',
      text: i18n.t('generic.usageMinutes'),
      headerStyle: { width: '132px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => toMinutes(cell),
    }, {
      sort: true,
      dataField: 'activationTimestamp',
      text: i18n.t('generic.activatedTime'),
      headerStyle: { width: '165px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => toDate(cell),
    }, {
      sort: true,
      dataField: 'terminationTimestamp',
      text: i18n.t('generic.terminatedTime'),
      headerStyle: { width: '165px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => toDate(cell),
    }];

    const rowEvents = {
      onClick: (e, row) => this.props.history.push(`${this.props.location.pathname}/${row.id}`),
    };

    const DateInput = ({ value, onClick }) => (
      <IrisButton onClick={onClick} style={{ width: '200px', height: '35px' }} outline>
        {value}
      </IrisButton>
    );

    const animatedComponents = makeAnimated();

    return (
      <div className="BookingFilters">
        <Row>
          <Col>
            <IrisBox>
              <div className="title mb-4">
                <h4 className="text-muted"><i className="fa fa-key"/> {i18n.t('bookings.title')}</h4>
              </div>
              <div>
                <Collapse isOpen={this.state.openFilters}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={2}>
                          <Label>{i18n.t('generic.status')}:</Label>
                          <br/>
                          <Select
                            value={this.state.selectedStatuses}
                            onChange={this.setSelectedStatus}
                            options={bookingStatusOptions}
                            components={animatedComponents}
                            isMulti/>
                        </Col>
                        <Col md={2}>
                          <Label>{i18n.t('groups.group')}:</Label>
                          <br/>
                          <Select
                            value={this.state.selectedUserGroups}
                            onChange={this.setSelectedUserGroup}
                            options={this.userGroupOptions}
                            components={animatedComponents}
                            isMulti/>
                        </Col>
                        <Col md={2}>
                          <Label>{i18n.t('assets.assetType')}:</Label>
                          <br/>
                          <Select
                            value={this.state.selectedAssetTypes}
                            onChange={this.setSelectedAssetType}
                            options={this.assetTypeOptions}
                            components={animatedComponents}
                            isMulti/>
                        </Col>
                        <Col>
                          <Label>{i18n.t('generic.startTime')}:</Label>
                          <br/>
                          <DatePicker
                            selected={this.state.filters.startDate}
                            onChange={date => this.setStartDate(date)}
                            timeInputLabel=""
                            dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeInput
                            customInput={<DateInput/>}/>
                        </Col>
                        <Col>
                          <Label>{i18n.t('generic.endTime')}:</Label>
                          <br/>
                          <DatePicker
                            selected={this.state.filters.endDate}
                            onChange={date => this.setEndDate(date)}
                            timeInputLabel=""
                            dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeInput
                            customInput={<DateInput/>}/>
                        </Col>
                        <Col style={{ whiteSpace: 'nowrap' }}>
                          <Label><i className="fa fa-exclamation-triangle" style={{ color: 'orange' }}/> {i18n.t('generic.attention')}:</Label>
                          <br/>
                          <IrisToggleSwitcher
                            checked={this.state.filters.attentionFlag}
                            onChange={this.setAttentionFlag}/>
                        </Col>
                        <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                          <IrisButton key={_.uniqueId()} color="secondary" size="sm" onClick={this.clearFilter} outline>
                            <i className="fa fa-times-circle fa-lg"/>
                          </IrisButton>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Collapse>
                <br/>
              </div>

              <ToolkitProvider
                keyField="id"
                data={this.props.bookings || []}
                columns={ columns }
                search={{ searchFormatted: true }}
              >
                {props => (
                  <div>
                    <div style={{ display: 'inline' }}>
                      <div style={{ float: 'left' }}>
                        <SearchBar { ...props.searchProps }/>
                      </div>
                      <div style={{ float: 'right' }}>
                        <IrisButton key={_.uniqueId()} color="primary" size="sm" onClick={this.toggleFilter} disabled={this.state.openFilters} outline>
                          <i className="fa fa-filter" aria-hidden="true"/> {i18n.t('buttons.filter')}
                        </IrisButton>
                        <span>&nbsp;</span>
                        <IrisButton key={_.uniqueId()} color="primary" size="sm" onClick={this.exportBookings} outline>
                          <i className="fa fa-download" aria-hidden="true"/> {i18n.t('buttons.export')}
                        </IrisButton>
                        <span>&nbsp;</span>
                        <IrisButton key={_.uniqueId()} color="info" size="sm" onClick={this.toggleModalBookingForm} disabled={!this.props.assetTypes?.length} outline>
                          {i18n.t('buttons.create')}...
                        </IrisButton>
                      </div>
                    </div>
                    <BootstrapTable
                      { ...props.baseProps }
                      noDataIndication={i18n.t('notices.noDataIndication')}
                      rowEvents={rowEvents}
                      pagination={paginationFactory({ hideSizePerPage: true })}
                      striped hover/>
                  </div>
                )}
              </ToolkitProvider>
            </IrisBox>
          </Col>
        </Row>

        {this.state.modalBookingForm
         && <BookingForm title={i18n.t('bookings.createBookingForm.title')} icon={<i className="fa fa-key fa-fw"/>}
                         users={this.props.users} assets={this.props.assets} assetTypes={this.props.assetTypes} groups={this.props.groups}
                         toggle={this.toggleModalBookingForm}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    bookings: fleetmanager.posts.bookings,
    users: fleetmanager.posts.users,
    assets: fleetmanager.posts.assets,
    assetTypes: fleetmanager.posts.assetTypes,
    groups: fleetmanager.posts.groups,
  };
};

export default connect(mapStateToProps, {
  resetState, getBookings, getUsers, getAssets, getAssetTypes, getGroups,
})(Bookings);
