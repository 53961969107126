import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { IrisSpinner, IrisBox, IrisButton } from 'iris-core/libs/components';

import { AssetForm } from '../../components';
import { getAssets, getAssetTypes, createAsset } from '../../actions';
import {
  getArrayElementProperty, resolveAssetStatus, resolveCheckSquare,
} from '../../helper';
import assetIcon from '../../assets/excavator_gray.png';
import i18n from '../../i18n';

const { SearchBar } = Search;

class Assets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalCreateAsset: false,
    };
  }

  componentDidMount() {
    Promise.all([this.props.getAssetTypes()]).then(() => {
      this.props.getAssets();
      this.setState({ loading: false });
    });
  }

  resolveLockedFlag = (lockedFlag) => {
    return (lockedFlag === undefined
      ? <i className="fa fa-question fa-lg"/>
      : <i className={lockedFlag ? 'fa fa-lock fa-lg' : 'fa fa-unlock fa-lg'}/>);
  }

  toggleModalCreateAsset = () => {
    this.setState({ modalCreateAsset: !this.state.modalCreateAsset });
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    const columns = [{
      sort: true,
      dataField: 'status',
      text: i18n.t('generic.status'),
      headerStyle: { width: '120px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => resolveAssetStatus(cell),
    }, {
      sort: true,
      dataField: 'bypassFlag',
      text: i18n.t('assets.details.bypassFlag'),
      headerStyle: { width: '85px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => resolveCheckSquare(cell),
    }, {
      sort: true,
      dataField: 'lockedFlag',
      text: i18n.t('assets.details.lockedFlag'),
      headerStyle: { width: '85px', color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => this.resolveLockedFlag(cell),
    }, {
      sort: true,
      dataField: 'assetTypeId',
      text: i18n.t('assets.assetType'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => getArrayElementProperty(this.props.assetTypes, cell, 'name'),
    }, {
      sort: true,
      dataField: 'id',
      text: i18n.t('assets.details.vin'),
      headerStyle: { width: '200px', color: '#0093ca', fontWeight: 'normal' },
    }, {
      sort: true,
      dataField: 'externalId',
      text: i18n.t('assets.details.externalId'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
      hidden: !_.get(this.props.profile.account.iris, 'assets.fields.externalId', true),
    }, {
      sort: true,
      dataField: 'licensePlate',
      text: i18n.t('assets.details.licensePlate'),
      headerStyle: { width: '130px', color: '#0093ca', fontWeight: 'normal' },
      hidden: !_.get(this.props.profile.account.iris, 'assets.fields.licensePlate', true),
    }, {
      sort: true,
      dataField: 'assetTypeId',
      text: i18n.t('assets.details.make'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => getArrayElementProperty(this.props.assetTypes, cell, 'make'),
    }, {
      sort: true,
      dataField: 'assetTypeId',
      text: i18n.t('assets.details.model'),
      headerStyle: { color: '#0093ca', fontWeight: 'normal' },
      formatter: (cell) => getArrayElementProperty(this.props.assetTypes, cell, 'model'),
    }];

    const rowEvents = {
      onClick: (e, row) => this.props.history.push(`${this.props.location.pathname}/${row.id}`),
    };

    return (
      <div className="AssetList">
        <Row>
          <Col>
            <IrisBox>
              <div className="title mb-4">
                <h4 className="text-muted"><img src={assetIcon} alt="" width="32" height="32"/> {i18n.t('assets.title')}</h4>
              </div>
              <ToolkitProvider
                keyField="id"
                data={this.props.assets || []}
                columns={ columns }
                search={{ searchFormatted: true }}
              >
                {props => (
                  <div>
                    <div style={{ display: 'inline' }}>
                      <div style={{ float: 'left' }}>
                        <SearchBar { ...props.searchProps }/>
                      </div>
                      <div>
                        <IrisButton style={{ float: 'right' }} key={_.uniqueId()} color="info" size="sm" onClick={this.toggleModalCreateAsset} disabled={!this.props.assetTypes?.length} outline>
                          {i18n.t('buttons.create')}...
                        </IrisButton>
                      </div>
                    </div>
                    <BootstrapTable
                      { ...props.baseProps }
                      noDataIndication={i18n.t('notices.noDataIndication')}
                      rowEvents={rowEvents}
                      pagination={paginationFactory({ hideSizePerPage: true })}
                      striped hover/>
                  </div>
                )}
              </ToolkitProvider>
            </IrisBox>
          </Col>
        </Row>

        {this.state.modalCreateAsset
         && <AssetForm title={i18n.t('assets.createAssetForm.title')} icon={<img src={assetIcon} alt="" width="32" height="32"/>}
                       account={this.props.profile.account} assetTypes={this.props.assetTypes}
                       onSubmit={this.props.createAsset} toggle={this.toggleModalCreateAsset}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    profile: fleetmanager.posts.profile,
    assets: fleetmanager.posts.assets,
    assetTypes: fleetmanager.posts.assetTypes,
  };
};

export default connect(mapStateToProps, {
  getAssets, getAssetTypes, createAsset,
})(Assets);
