import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-gb';

import { IrisCore, createIrisStore } from 'iris-core';
import { Home } from 'iris-core/libs/views';

import 'iris-core/libs/iris-core.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import './scss/app.css';

import service from './service';

const services = [service];
const irisStore = createIrisStore([...services]);
window.irisState = irisStore.getState;
moment.locale('en-gb');
ReactDOM.render(
  <Provider store={irisStore}>
    <IrisCore services={services} HomeComponent={Home}/>
  </Provider>,
  document.getElementById('root')
);
