import React from 'react';
import { connect } from 'react-redux';
import './style.css';

import {
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import DatePicker from 'react-datepicker';

import {
  func,
  array,
  string,
  object,
} from 'prop-types';

import {
  IrisDatatable, IrisTableHeaderColumn, IrisSelect, IrisButton,
} from 'iris-core/libs/components';

import {
  getAssetsAvailableForBooking, getCurrentBookings, updateBooking,
} from '../../actions';
import {
  generateSelectMap, getArrayElementProperty, showFormValidationErrors, toDate,
} from '../../helper';
import { SERVER_ERROR } from '../../globalConstants';
import i18n from '../../i18n';

/*
* Form to update booking
*/
class UpdateBookingForm extends React.Component {
  static propTypes = {
    title: string.isRequired,
    booking: object.isRequired,
    users: array.isRequired,
    assets: array.isRequired,
    assetTypes: array.isRequired,
    groups: array.isRequired,
    availableAssetsForBooking: array,
    currentBookings: array,
    error: object,
    getAssetsAvailableForBooking: func,
    getCurrentBookings: func,
    updateBooking: func,
    toggle: func.isRequired,
    onUpdate: func.isRequired,
    icon: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.usersMap = {};
    this.users = generateSelectMap(this.props.users, this.usersMap);

    this.assetTypes = generateSelectMap(this.props.assetTypes, {});

    this.generateAssetsByAssetTypeMap(this.props.assets);

    this.groupsMap = {};
    this.userGroups = [];
    this.props.groups.forEach(group => {
      if (group.type === 'users') {
        this.groupsMap[group.id] = group;

        this.userGroups.push({
          value: group.id,
          label: group.name,
        });
      }
    });

    this.state = {
      isOpen: true,
      fetchingAvailableAssets: false,
      groupId: this.props.booking.groupId,
      users: this.groupsMap[this.props.booking.groupId].members,
      assetId: this.props.booking.assets[0],
      startDate: new Date(this.props.booking.start),
      endDate: new Date(this.props.booking.end),
      validationErrors: [],
    };

    this.origStartTime = this.state.startDate.getTime();
  }

  componentDidMount() {
    this.getAvailableAssets(this.state.startDate, this.state.endDate);
    this.props.getCurrentBookings(`start=${this.state.startDate.toISOString()}&end=${this.state.endDate.toISOString()}&assettype=${this.props.booking.assetTypeId}`);
    this.checkAssetsNotFound();
  }

  componentDidUpdate(prevProps, prevState) {
    const start = this.state.startDate;
    const end = this.state.endDate;

    if (start.getTime() !== prevState.startDate.getTime() || end.getTime() !== prevState.endDate.getTime()) {
      const validationErrors = [];

      this.validateTime(start, end, validationErrors);
      this.setState({ validationErrors });
      this.checkAssetsNotFound();

      if (validationErrors.length) {
        return;
      }

      this.getAvailableAssets(start, end);
      this.props.getCurrentBookings(`start=${start.toISOString()}&end=${end.toISOString()}&assettype=${this.props.booking.assetTypeId}`);
    }
  }

  toggleWindow = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.toggle();
  }

  generateAssetsByAssetTypeMap = (assets) => {
    this.assetsByAssetTypeMap = {};

    for (const asset of assets) {
      const element = {
        value: asset.id,
        label: asset.licensePlate || asset.externalId || asset.id,
      };

      if (this.assetsByAssetTypeMap[asset.assetTypeId]) {
        this.assetsByAssetTypeMap[asset.assetTypeId].push(element);
      } else {
        this.assetsByAssetTypeMap[asset.assetTypeId] = [element];
      }
    }
  }

  getAssets = () => {
    return this.assetsByAssetTypeMap[this.props.booking.assetTypeId] || [];
  }

  // Resolve state.users to actual user objects for rendering
  resolveUsers = () => {
    const list = [];
    this.state.users.forEach(id => list.push(this.usersMap[id]));
    return list;
  }

  getAvailableAssets = (startDate, endDate) => {
    this.setState({ fetchingAvailableAssets: true });
    this.props.getAssetsAvailableForBooking(startDate.toISOString(), endDate.toISOString(), this.props.booking.name).then(() => {
      const availableAssets = this.props.assets.filter(asset => this.props.availableAssetsForBooking.includes(asset.id));
      this.generateAssetsByAssetTypeMap(availableAssets);

      let assetId;
      const assets = this.getAssets();
      if (assets.length) {
        // Get selected asset, if available; otherwise, select first one
        const asset = assets.find(a => a.value === this.state.assetId);
        assetId = asset ? asset.value : assets[0].value;
      } else {
        assetId = null;
      }

      this.setState({
        fetchingAvailableAssets: false,
        assetId,
        assetsNotFound: !assetId,
      });
    });
  }

  checkAssetsNotFound = () => {
    if (this.state.assetsNotFound) {
      this.setState(prevState => ({
        validationErrors: [...prevState.validationErrors, 'No available assets - Change time period or add a new asset'],
      }));
    }
  }

  validateTime = (startDate, endDate, validationErrors) => {
    if (startDate.getTime() !== this.origStartTime && startDate.getTime() <= (Date.now() - 600000)) {
      validationErrors.push('Change start-time - Must be after current time');
    }

    if (endDate.getTime() <= startDate.getTime()) {
      validationErrors.push('Change end-time - Must be after start-time');
    }
  }

  onSubmit = (event) => {
    event.preventDefault();

    const validationErrors = [];

    this.validateTime(this.state.startDate, this.state.endDate, validationErrors);

    if (!this.state.assetId) {
      validationErrors.push('Select asset - Ensure an asset of the selected type is added');
    }

    this.setState({ validationErrors });
    this.checkAssetsNotFound();

    if (validationErrors.length) {
      return;
    }

    this.props.updateBooking({
      id: this.props.booking.id,
      assets: [this.state.assetId],
      validity: {
        start: this.state.startDate.toISOString(),
        end: this.state.endDate.toISOString(),
      },
    }).then(() => {
      if (!this.props.error) {
        this.props.onUpdate();
        return;
      }

      validationErrors.push(this.props.error.errorCode === SERVER_ERROR.BOOKING_CONFLICT
        ? `Change asset - ${this.props.error.message}`
        : 'Unexpected failure; please retry');
      this.setState({ validationErrors });
    });
  }

  render() {
    const options = {
      sizePerPage: 10,
    };

    const DateInput = React.forwardRef((props, ref) => {
      return (<IrisButton onClick={props.onClick} outline>{props.value}</IrisButton>);
    });

    return (
      <Modal isOpen={this.state.isOpen} size="md" fade={true}>
        <ModalHeader toggle={this.toggleWindow}>
          {this.props.icon} {this.props.title} : {this.props.booking.name}
        </ModalHeader>

        <ModalBody>
          {showFormValidationErrors(this.state.validationErrors)}
          <Row>
            <Col>
              <FormGroup>
                <Label>{i18n.t('generic.startTime')}:</Label>
                <br/>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={date => this.setState({ startDate: date })}
                  timeInputLabel=""
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  customInput={<DateInput/>}
                  disabled={this.props.booking.status === 'activated'}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{i18n.t('generic.endTime')}:</Label>
                <br/>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={date => this.setState({ endDate: date })}
                  timeInputLabel=""
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  customInput={<DateInput/>}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{i18n.t('assets.asset')}:</Label>
                <br/>
                <IrisSelect className="asset-type-select" options={this.assetTypes} value={this.props.booking.assetTypeId} disabled outline/>
                <span>&nbsp;</span>
                <IrisSelect className="asset-select" options={this.getAssets()}
                            onChange={value => this.setState({ assetId: value })} value={this.state.assetId || ''} disabled={this.state.fetchingAvailableAssets} outline/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{i18n.t('groups.group')}:&nbsp;</Label>
                <IrisSelect options={this.userGroups} value={this.state.groupId} disabled outline/>
                <br/><br/>
                <IrisDatatable headerStyle={{ display: 'none' }} height='150px' data={this.resolveUsers()} options={options} noDataText="No users added" hover pagination>
                  <IrisTableHeaderColumn dataField="id" isKey hidden/>
                  <IrisTableHeaderColumn dataField="name" searchable/>
                  <IrisTableHeaderColumn dataField="externalId" searchable/>
                  <IrisTableHeaderColumn dataField="role"/>
                </IrisDatatable>
              </FormGroup>
            </Col>
          </Row>
          <hr/>
          <Row>
            <Col>
              <FormGroup>
                <Label>{getArrayElementProperty(this.props.assetTypes, this.props.booking.assetTypeId, 'name')} {i18n.t('bookings.title')}:</Label>
                <br/><br/>
                <IrisDatatable headerStyle={{ display: 'none' }} height='150px' data={this.props.currentBookings} options={options} noDataText="No bookings found" hover pagination>
                  <IrisTableHeaderColumn dataField="id" isKey hidden/>
                  <IrisTableHeaderColumn dataField="groupId" dataFormat={(cell) => getArrayElementProperty(this.props.groups, cell, 'name')} searchable/>
                  <IrisTableHeaderColumn dataField="start" dataFormat={(cell) => toDate(cell)}/>
                  <IrisTableHeaderColumn dataField="end" dataFormat={(cell) => toDate(cell)}/>
                </IrisDatatable>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <IrisButton color="primary" size="sm" type="submit" onClick={this.onSubmit} disabled={this.state.fetchingAvailableAssets}>
            {i18n.t('buttons.update')}
          </IrisButton>
          <IrisButton color="secondary" size="sm" onClick={this.toggleWindow} outline>
            {i18n.t('buttons.close')}
          </IrisButton>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    availableAssetsForBooking: fleetmanager.posts.availableAssetsForBooking,
    currentBookings: fleetmanager.posts.currentBookings,
    error: fleetmanager.posts.error,
  };
};

export default connect(mapStateToProps, {
  getAssetsAvailableForBooking, getCurrentBookings, updateBooking,
})(UpdateBookingForm);
