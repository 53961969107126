/* eslint-disable no-param-reassign */

import React from 'react';
import {
  Badge, ListGroup, ListGroupItem, ListGroupItemText,
} from 'reactstrap';
import axios from 'axios';

import { ROLE } from '../globalConstants';
import i18n from '../i18n';

export const toDate = (data) => {
  return (data ? new Date(data).toLocaleString([], {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }) : '');
};

export const toHours = (millisecs) => {
  return (millisecs ? Math.round(millisecs / 3600000) : '');
};

export const toMinutes = (seconds) => {
  return (seconds ? Math.round(seconds / 60) : '');
};

export const toPartitionedString = (seconds) => {
  if (seconds >= 1) {
    const d = Math.floor(seconds / 86400);
    const h = Math.floor(seconds % 86400 / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 60);
    const parts = [];

    if (d > 0) {
      parts.push(`${d}d`);
    }

    if (h > 0) {
      parts.push(`${h}h`);
    }

    if (m > 0) {
      parts.push(`${m}m`);
    }

    if (s > 0) {
      parts.push(`${s}s`);
    }

    return parts.join(' ');
  }

  return 'N/A';
};

export const isAssetWorkerRole = (role) => {
  return role !== ROLE.ADMIN && role !== ROLE.DISPATCHER && role !== ROLE.TECHNICIAN;
};

export const getArrayElementProperty = (array, id, propName, defaultVal) => {
  const elem = array.find(entry => entry.id === id);
  return elem?.[propName] ?? defaultVal ?? '';
};

export const getArtifacts = (tokenizedUrlArray) => {
  return Promise.all(tokenizedUrlArray.map(tokenizedUrl => {
    return axios.get(resolveTokenizedUrl(tokenizedUrl))
      .then(response => {
        return response.data;
      })
      .catch(async error => {
        if (window.tokenStorage.lang !== 'en') {
          window.tokenStorage.lang = 'en';
          try {
            const response = await axios.get(resolveTokenizedUrl(tokenizedUrl));
            return response.data;
          } catch (error) {
            // Fallthrough
          }
        }
        return {};
      });
  }));
};

export const generateSelectMap = (inArray, outMap) => {
  return inArray.map(value => {
    outMap[value.id] = value;

    return {
      value: value.id,
      label: value.name,
    };
  });
};

export const generateSelectMapWithFilter = (inArray, outMap, condition) => {
  return generateSelectMap(inArray.filter(value => condition(value)), outMap);
};

export const showFormValidationErrors = (validationErrors) => {
  if (validationErrors.length) {
    return (<>
      <div>
        <ListGroup>
          <ListGroupItem color="danger">
            {validationErrors.map((element, index) => (
              <ListGroupItemText key={index}>{<i className="fa fa-exclamation-circle fa-fw" style={{ color: 'red' }}/>} {element}.</ListGroupItemText>
            ))}
          </ListGroupItem>
        </ListGroup>
      </div>
      <hr/>
    </>);
  }

  return (<></>);
};

export const resolveTokenizedUrl = (tokenizedUrl) => {
  return tokenizedUrl.replace(/{(\w+)}/g, (m, token) => window.tokenStorage[token]);
};

export const resolveI18nArtifact = (i18nKey, mapping) => {
  return mapping[i18nKey] ?? `?${i18nKey}?`;
};

export const resolveAssetName = (assets, assetId) => {
  const asset = assets.find(entry => entry.id === assetId);
  return asset?.licensePlate ?? asset?.externalId ?? assetId;
};

export const resolveAssetTypeName = (assetTypes, assets, assetId) => {
  const asset = assets.find(entry => entry.id === assetId);
  return getArrayElementProperty(assetTypes, asset.assetTypeId, 'name');
};

export const resolveAssetStatus = (status) => {
  switch (status) {
    case 'active':
      return (<Badge color="success">Active</Badge>);
    case 'maintenance':
      return (<Badge color="warning">Maintenance</Badge>);
    default:
      return 'Unknown';
  }
};

export const resolveGroupType = (type) => {
  switch (type) {
    case 'users':
      return i18n.t('users.title');
    case 'assets':
      return i18n.t('assets.title');
    default:
      return '?';
  }
};

export const resolveCheckSquare = (booleanValue) => {
  return (<i className={booleanValue ? 'fa fa-check-square-o fa-lg' : 'fa fa-square-o fa-lg'}/>);
};

export const resolveAssignmentStatus = (assignment) => {
  if (!assignment) {
    return 'Failed';
  }

  if (Date.now() > new Date(assignment.end).getTime() && (assignment.status === 'pending' || assignment.status === 'ready')) {
    return (<Badge color="secondary">Expired</Badge>);
  }

  switch (assignment.status) {
    case 'pending':
      return (<Badge color="warning">Pending</Badge>);
    case 'ready':
      return (<Badge color="success">Ready</Badge>);
    case 'cancelled':
      return (<Badge color="secondary">Cancelled</Badge>);
    default:
      return 'Unknown';
  }
};

export const resolveBookingStatus = (booking) => {
  const currentTime = Date.now();
  const startTime = new Date(booking.start).getTime();
  const endTime = new Date(booking.end).getTime();

  if (booking.status === 'cancelled') {
    // Booking cancelled by dispatcher
    return (<Badge color="secondary">Cancelled</Badge>);
  }

  if (booking.status === 'pending') {
    if (startTime > currentTime) {
      // Start-time has not come yet
      return (booking.attentionFlag
        ? <span><Badge color="info">Future</Badge> <i className="fa fa-exclamation-triangle fa-lg" style={{ color: 'orange' }}/></span> : <Badge color="info">Future</Badge>);
    }

    if (endTime > currentTime) {
      // Start-time has come and no operator activated the booking
      return (booking.attentionFlag
        ? <span><Badge color="warning">Pending</Badge> <i className="fa fa-exclamation-triangle fa-lg" style={{ color: 'orange' }}/></span> : <Badge color="warning">Pending</Badge>);
    }

    if (endTime < currentTime) {
      // Booking expired and no operator activated the booking
      return (<Badge color="secondary">Expired</Badge>);
    }
  }

  if (booking.status === 'activated') {
    if (endTime > currentTime) {
      // Booking activated by operator by submitting pre-op checklist
      return (booking.attentionFlag
        ? <span><Badge color="primary">Activated</Badge> <i className="fa fa-exclamation-triangle fa-lg" style={{ color: 'orange' }}/></span> : <Badge color="primary">Activated</Badge>);
    }

    if (endTime < currentTime) {
      // Booking expired and no operator terminated the booking
      return (<Badge color="danger">Outstanding</Badge>);
    }
  }

  if (booking.status === 'completed') {
    // Booking terminated by operator by submitting post-op checklist
    return (<Badge color="success">Completed</Badge>);
  }

  return 'Unknown';
};

export const resolveEventName = (eventTelemetry) => {
  switch (eventTelemetry.type) {
    case 14:
      switch (eventTelemetry.payload.lockStatus) {
        case 1:
          return 'Locked';
        case 2:
          return 'Unlocked';
        default:
          return 'Unknown Lock Status';
      }
    case 15:
      switch (eventTelemetry.payload.bookingStatus) {
        case 1:
          return 'Booking Activated';
        case 2:
          return 'Booking Terminated';
        default:
          return 'Unknown Booking Status';
      }
    default:
      return 'Unknown';
  }
};

export const resolveChecklistType = (checklistType) => {
  switch (checklistType) {
    case 'PRE_OP':
      return (<span><i className="fa fa-sign-in fa-lg"/> PRE-OP</span>);
    case 'POST_OP':
      return (<span><i className="fa fa-sign-out fa-lg"/> POST-OP</span>);
    default:
      return (<span><i className="fa fa-exclamation-circle fa-lg"/> PROBLEM</span>);
  }
};

export const downloadCsvFile = (content, filePrefix) => {
  const a = document.createElement('a');
  const fileName = `${filePrefix}_${new Date().toJSON().slice(0, 10)}.csv`;

  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(new Blob([content], { type: 'text/csv;charset=utf-8' }), fileName);
  } else if (URL && 'download' in a) { // HTML5 A[download]
    a.href = URL.createObjectURL(new Blob([content], { type: 'text/csv;charset=utf-8' }));
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    // eslint-disable-next-line no-restricted-globals
    location.href = `data:application/octet-stream,${encodeURIComponent(content)}`;
  }
};
