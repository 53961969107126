import React from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import {
  func,
  string,
  object,
} from 'prop-types';

import { IrisButton } from 'iris-core/libs/components';

import i18n from '../../i18n';

/*
* Generic confirm dialog
*/
class ConfirmDialog extends React.Component {
  static propTypes = {
    title: string.isRequired,
    confirmText: string.isRequired,
    itemName: string.isRequired,
    confirmButtonName: string.isRequired,
    toggle: func.isRequired,
    onConfirm: func.isRequired,
    icon: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { isOpen: true };
  }

  toggleWindow = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.toggle();
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen} fade={true}>
        <ModalHeader toggle={this.toggleWindow}>
          {this.props.icon} {this.props.title}
        </ModalHeader>
        <ModalBody>
          {this.props.confirmText}{this.props.itemName ? `: ${this.props.itemName}` : ''}
        </ModalBody>
        <ModalFooter>
          <IrisButton color="primary" size="sm" onClick={() => { this.toggleWindow(); this.props.onConfirm(); }}>
            {this.props.confirmButtonName}
          </IrisButton>
          <IrisButton color="secondary" size="sm" onClick={this.toggleWindow} outline>
            {i18n.t('buttons.close')}
          </IrisButton>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ConfirmDialog;
