import React from 'react';
import { connect } from 'react-redux';
import { Label, Row, Col } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { IrisSpinner, IrisBox, IrisButton } from 'iris-core/libs/components';

import { resetState, authenticateKeystoneUser, getAdminAccounts } from '../../actions';
import i18n from '../../i18n';

class SwitchAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAccount: null,
    };
  }

  componentDidMount() {
    Promise.all([this.props.getAdminAccounts()]).then(() => {
      this.setState({
        accounts: this.props.adminAccounts.map(account => ({ value: account.id, label: account.name })),
      });
    });
  }

  switchAccountSelected = () => {
    if (!this.state.selectedAccount) {
      return;
    }

    this.switchAccount(this.state.selectedAccount.value);
  }

  switchAccountDefault = () => {
    this.switchAccount(null);
  }

  switchAccount = (accountId) => {
    this.props.resetState();
    this.props.authenticateKeystoneUser(this.props.profile.user.email, accountId);
  }

  render() {
    if (!this.props.adminAccounts) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <IrisSpinner size="md" color="primary"/>
        </div>
      );
    }

    return (
      <div>
        <Row>
          <Col>
            <IrisBox>
              <div className="title mb-4">
                <h4 className="text-muted"><i className="fa fa-refresh"/> {i18n.t('switchAccount.title')}</h4>
              </div>
              <Row>
                <Col>
                  <Label>{i18n.t('switchAccount.current')}: <b>{this.props.profile.account.name}</b></Label>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Label>{i18n.t('switchAccount.change')}:</Label>
                  <Select
                    options={this.state.accounts}
                    value={this.state.selectedAccount}
                    onChange={selected => this.setState({ selectedAccount: selected })}
                    components={makeAnimated()}/>
                </Col>
                <Col md={2}>
                  <IrisButton style={{ marginTop: '30px' }} color="primary" size="sm" onClick={this.switchAccountSelected}>{i18n.t('buttons.apply')}</IrisButton>
                  <span>&nbsp;</span>
                  <IrisButton style={{ marginTop: '30px' }} color="secondary" size="sm" onClick={this.switchAccountDefault}>{i18n.t('buttons.switchDefault')}</IrisButton>
                </Col>
              </Row>
            </IrisBox>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ fleetmanager = {} }) => {
  return {
    profile: fleetmanager.posts.profile,
    adminAccounts: fleetmanager.posts.adminAccounts,
  };
};

export default connect(mapStateToProps, {
  resetState, authenticateKeystoneUser, getAdminAccounts,
})(SwitchAccount);
