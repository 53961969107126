import React from 'react';

import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import {
  func,
  string,
  object,
} from 'prop-types';

import { IrisSelect, IrisButton } from 'iris-core/libs/components';

import { GROUP_NAME_MAX_LENGTH } from '../../globalConstants';
import i18n from '../../i18n';

/*
* Form to create group
*/
class GroupForm extends React.Component {
  static propTypes = {
    title: string.isRequired,
    toggle: func.isRequired,
    onSubmit: func.isRequired,
    icon: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.typeOptions = [
      {
        value: 'users',
        label: 'Users',
      },
      {
        value: 'assets',
        label: 'Assets',
      },
    ];

    this.state = {
      isOpen: true,
      name: '',
      description: '',
      typeValue: this.typeOptions[0].value,
    };
  }

  toggleWindow = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.toggle();
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit({
      name: this.state.name,
      description: this.state.description || undefined,
      type: this.state.typeValue,
    }).then(() => {
      this.toggleWindow();
    });
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen} fade={true}>
        <Form className="modal-content" onSubmit={this.onSubmit}>
          <ModalHeader toggle={this.toggleWindow}>
            {this.props.icon} {this.props.title}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>{i18n.t('generic.type')}:&nbsp;</Label>
              <IrisSelect options={this.typeOptions} onChange={value => this.setState({ typeValue: value })} outline/>
            </FormGroup>
            <hr/>
            <FormGroup>
              <Label>{i18n.t('generic.name')}:</Label>
              <Input required name="name" maxLength={GROUP_NAME_MAX_LENGTH} placeholder={i18n.t('groups.createGroupForm.namePlaceholder')}
                     onChange={e => this.setState({ name: e.target.value })} value={this.state.name}/>
            </FormGroup>
            <FormGroup>
              <Label>{i18n.t('generic.description')}:</Label>
              <Input name="description" placeholder={i18n.t('groups.createGroupForm.descriptionPlaceholder')}
                     onChange={e => this.setState({ description: e.target.value })} value={this.state.description}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <IrisButton color="info" size="sm" type="submit">
              {i18n.t('buttons.create')}
            </IrisButton>
            <IrisButton color="secondary" size="sm" onClick={this.toggleWindow} outline>
              {i18n.t('buttons.close')}
            </IrisButton>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default GroupForm;
